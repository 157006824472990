import React, { useEffect, useState } from 'react';
import { TabTitle } from '../../utils/General';
import axios from "axios";
import './Shop.css';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ArrowIcon from './ArrowIcon';
import { useTheme, useMediaQuery, Box, FormControl, Select, MenuItem, Button, Slider, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ItemCard from '../Card/ItemCard/ItemCard';

const Shop = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'Каталог', path: `/all` },
    ];
    
    TabTitle('Каталог');
    
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [maxPrice, setMaxPrice] = useState(10000);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadProducts = async () => {
            try {
                setLoading(true);
                
                const res = await axios.get("https://carabine.shop/api/items");
                const transformedItems = res.data
                    .map(item => ({
                        ...item,
                        categories: Array.isArray(item.categories)
                            ? item.categories[0].split(',').map(cat => cat.trim())
                            : item.category.split(',').map(cat => cat.trim())
                    }))
                    .filter(item => {
                        return !item.categories.includes('packaging') &&
                               !item.categories.includes('certificates') &&
                               !item.categories.includes('sertifikaty') &&
                               !item.categories.includes('giftcard');
                    });

                const maxPriceInItems = Math.max(...transformedItems.map(item => item.price));
                const sortedItems = transformedItems.sort((a, b) => {
                    const primaryCategoryA = Array.isArray(a.categories) ? a.categories[0] : a.category;
                    const primaryCategoryB = Array.isArray(b.categories) ? b.categories[0] : b.category;
                    const priorityA = getCategoryPriority(primaryCategoryA);
                    const priorityB = getCategoryPriority(primaryCategoryB);
                    return priorityA - priorityB;
                });

                setItems(sortedItems);
                setFilteredItems(sortedItems);
                setMaxPrice(maxPriceInItems);
                
            } catch (err) {
                console.error(err);
                setError('Ошибка загрузки товаров');
            } finally {
                setLoading(false);
                window.scrollTo(0, 0);
            }
        };

        loadProducts();
    }, []);

    // Сортировка категорий
    const categoryOrder = [
        'chokers', 'necklace', 'sergi', 'pendants', 'scarves', 'anklets', 'new', 'bracelets', 'bestsellers', 'dreamdrop', 'babushkindrop', 'pearl', 'crystal', 'naturalstones', 'basic'
    ];
    
    const getCategoryPriority = (category) => {
        const index = categoryOrder.indexOf(category);
        return index !== -1 ? index : categoryOrder.length;
    };

    const groupItemsByCategory = (items) => {
        const grouped = {};
        items.forEach(item => {
            const primaryCategory = Array.isArray(item.categories) ? item.categories[0] : item.category;
            if (!grouped[primaryCategory]) {
                grouped[primaryCategory] = [];
            }
            grouped[primaryCategory].push(item);
        });

        const sortedGroups = Object.keys(grouped).sort((a, b) => {
            const priorityA = getCategoryPriority(a);
            const priorityB = getCategoryPriority(b);
            return priorityA - priorityB;
        });

        const sortedGrouped = {};
        sortedGroups.forEach(category => {
            sortedGrouped[category] = grouped[category];
        });

        return sortedGrouped;
    };

    const handleClearFilters = () => {
        setSelectedColors([]);
        setSelectedMaterials([]);
        setSelectedCategories([]);
        setPriceRange([0, maxPrice]);
        applyFilters();
    };

    const [showFilters, setShowFilters] = useState(false);
    const [show, setShow] = useState('All');
    const [filter, setFilter] = useState([]);
    const [priceRange, setPriceRange] = useState([0, maxPrice]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(50);
    const [activeFilter, setActiveFilter] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    const handleShowChange = (event) => {
        setShow(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handlePriceChange = (event, newValue) => {
        if (Array.isArray(newValue)) {
            setPriceRange(newValue);
            applyFilters(newValue);
        }
    };

    const handleMinPriceChange = (event) => {
        const newMinPrice = Number(event.target.value);
        setPriceRange((prev) => {
            const updatedRange = [newMinPrice, prev[1]];
            applyFilters(updatedRange);
            return updatedRange;
        });
    };

    const handleMaxPriceChange = (event) => {
        const newMaxPrice = Number(event.target.value);
        setPriceRange((prev) => {
            const updatedRange = [prev[0], newMaxPrice];
            applyFilters(updatedRange);
            return updatedRange;
        });
    };

    const handleColorChange = (event) => {
        const value = event.target.value;
        setSelectedColors((prev) =>
            prev.includes(value) ? prev.filter(color => color !== value) : [...prev, value]
        );
    };

    const handleMaterialChange = (event) => {
        const value = event.target.value;
        setSelectedMaterials((prev) =>
            prev.includes(value) ? prev.filter(material => material !== value) : [...prev, value]
        );
    };

    const handleCategoryChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSelectedCategories((prev) =>
            prev.includes(value) ? prev.filter(category => category !== value) : [...prev, value]
        );
    };

    useEffect(() => {
        applyFilters();
    }, [priceRange, selectedColors, selectedMaterials, selectedCategories, filter]);

    const applyFilters = (priceRangeOverride) => {
        const range = priceRangeOverride || priceRange;
        let filtered = items;

        filtered = filtered.filter(item => item.price >= range[0] && item.price <= range[1]);

        if (selectedColors.length > 0) {
            filtered = filtered.filter(item => selectedColors.includes(item.color));
        }

        if (selectedMaterials.length > 0) {
            filtered = filtered.filter(item => selectedMaterials.includes(item.material));
        }

        if (selectedCategories.length > 0) {
            filtered = filtered.filter(item =>
                Array.isArray(item.categories) &&
                item.categories.some(category =>
                    selectedCategories.map(cat => cat.toLowerCase()).includes(category.toLowerCase())
                )
            );
        }

        filtered = sortItems(filtered, filter);
        setFilteredItems(filtered);
    };

    const sortItems = (items, sortFilter) => {
        switch (sortFilter) {
            case 'Сначала дешевле':
                return [...items].sort((a, b) => a.price - b.price);
            case 'Сначала дороже':
                return [...items].sort((a, b) => b.price - a.price);
            case 'Популярные':
                return [...items].sort((a, b) => b.popularity - a.popularity);
            case 'Новинки':
                return [...items].sort((a, b) => new Date(b.date) - new Date(a.date));
            default:
                return items;
        }
    };

    const toggleFilter = (filterName) => {
        setActiveFilter(activeFilter === filterName ? null : filterName);
    };

    const handleShowMore = () => {
        setItemsToShow((prev) => prev + 10);
    };

    const isActive = (filterName) => activeFilter === filterName;

    const ArrowsIcon = ({ isOpen }) => (
        <svg 
            width="18" 
            height="9" 
            viewBox="0 0 18 9" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
        >
            <path d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z" fill="#1B1B1B"/>
        </svg>
    );

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const categoryMapping = {
        chokers: 'чокеры',
        necklace: 'колье',
        pendants: 'подвески',
        scarves: 'шарфики',
        anklets: 'анклеты',
        new: 'новинки',
        sergi: 'серьги',
        braslette: 'браслеты',
        bestsellers: 'бестселлеры',
        dreamdrop: 'Dream Drop',
        babushkindrop: 'Babushkin Drop',
        pearl: 'Pearl',
        crystal: 'Crystal',
        naturalstones: 'Natural Stones',
        basic: 'Basic'
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.scrollHeight - 100 &&
                itemsToShow < filteredItems.length
            ) {
                handleShowMore();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [itemsToShow, filteredItems]);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return ( 
        <div className="shop__container">
            <Breadcrumbs items={breadcrumbItems} />
            <div className="category__sort desc">
                <div className="show__filter">
                    <Button onClick={() => setShowFilters(!showFilters)} variant="outlined">
                        Фильтры
                        <ArrowsIcon isOpen={showFilters} />
                    </Button>
                </div>
                <div className="show__filter">
                    <Box sx={{ width: 120 }}>
                        <FormControl fullWidth size="small">
                            <Select
                                value={filter}
                                onChange={handleFilterChange}
                                IconComponent={() => <ArrowsIcon isOpen={!!filter} />}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return 'Сортировка';
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>Сортировка</MenuItem>
                                <MenuItem value="Сначала дороже">Сначала дороже</MenuItem>
                                <MenuItem value="Сначала дешевле">Сначала дешевле</MenuItem>
                                <MenuItem value="Популярные">Популярные</MenuItem>
                                <MenuItem value="Новинки">Новинки</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
            {isMobileOrTablet && (
                <>
                <div className="category__sort mob">
                    <div className="show__filter">
                        <Button onClick={handleOpenDialog} variant="outlined">
                            Фильтры
                            <ArrowIcon isOpen={showFilters} />
                        </Button>
                    </div>
                    <div className="show__filter">
                        <Box sx={{ width: 120 }}>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={filter}
                                    onChange={handleFilterChange}
                                    IconComponent={() => <ArrowsIcon isOpen={!!filter} />}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return 'Сортировка';
                                        }
                                        return selected;
                                    }}
                                >
                                    <MenuItem value="" disabled>Сортировка</MenuItem>
                                <MenuItem value="Сначала дороже">Сначала дороже</MenuItem>
                                <MenuItem value="Сначала дешевле">Сначала дешевле</MenuItem>
                                <MenuItem value="Популярные">Популярные</MenuItem>
                                <MenuItem value="Новинки">Новинки</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>

                <Dialog
                    fullScreen={isMobileOrTablet}
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="filter-dialog-title"
                >
                    <DialogTitle id="filter-dialog-title">
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{
                                position: 'absolute',
                                right: 19,
                                top: 19,
                                color: theme.palette.grey[500],
                            }}
                        >
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.5907 9L18 16.4093L16.4093 18L9 10.5907L1.59075 18L0 16.4093L7.40925 9L0 1.59075L1.59075 0L9 7.40925L16.4093 0L18 1.59075L10.5907 9Z" fill="#1B1B1B"/>
                           </svg>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {/* Цена */}
                        <div className="filter-section">
                            <div onClick={() => toggleFilter('price')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '18px' }}>Цена</h3>
                                <ArrowsIcon isOpen={isActive('price')} />
                            </div>
                            {isActive('price') && (
                                <div className="slider">
                                    <div className="price-inputs">
                                        <div className="from-price">
                                            <label className="minPrice" htmlFor="minPrice">От</label>
                                            <input
                                                type="number"
                                                id="minPrice"
                                                value={priceRange[0] > 0 ? priceRange[0] : ''} // Показать значение, если оно больше нуля
                                                placeholder="0"
                                                onChange={handleMinPriceChange} 
                                                min={0}
                                                max={priceRange[1]} 
                                            />
                                        </div>
                                        <div className="to-price">
                                            <label className="maxPrice"  htmlFor="maxPrice">До</label>
                                            <input
                                                type="number"
                                                id="maxPrice"
                                                value={priceRange[1] > 0 ? priceRange[1] : ''} // Показать значение, если оно больше нуля
                                                placeholder={maxPrice}
                                                onChange={handleMaxPriceChange}
                                                min={priceRange[0]} 
                                                max={maxPrice}
                                            />
                                        </div>
                                    </div>
                                    <Slider
                                        value={priceRange}
                                        onChange={handlePriceChange}
                                        valueLabelDisplay="on"
                                        min={0}
                                        max={maxPrice}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Цвет */}
                        <div className="filter-section">
                            <div onClick={() => toggleFilter('color')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '18px' }}>Цвет</h3>
                                <ArrowsIcon isOpen={isActive('color')} />
                            </div>
                            {isActive('color') && (
                                <FormGroup>
                                    {['белый', 'черный', 'красный', 'розовый', 'золото', 'серебро', 'серый', 'синий'].map(color => (
                                        <FormControlLabel
                                            control={<Checkbox value={color} checked={selectedColors.includes(color)} onChange={handleColorChange} />}
                                            label={color}
                                            key={color}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                        </div>

                        {/* Материал */}
                        <div className="filter-section">
                            <div onClick={() => toggleFilter('material')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '18px' }}>Материал</h3>
                                <ArrowsIcon isOpen={isActive('material')} />
                            </div>
                            {isActive('material') && (
                                <FormGroup>
                                    {['агат', 'жемчуг', 'гематит', 'серебро', 'фианит', 'хрусталь'].map(material => (
                                        <FormControlLabel
                                            control={<Checkbox value={material} checked={selectedMaterials.includes(material)} onChange={handleMaterialChange} />}
                                            label={material}
                                            key={material}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                        </div>

                        {/* Категория */}
                        <div className="filter-section">
                            <div onClick={() => toggleFilter('category')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                <h3 style={{ marginRight: '18px' }}>Категория</h3>
                                <ArrowsIcon isOpen={isActive('category')} />
                            </div>
                            {isActive('category') && (
                                <FormGroup>
                                    {Object.keys(categoryMapping).map(englishCategory => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={englishCategory} // Используем английское название для значения
                                                    checked={selectedCategories.includes(englishCategory)}
                                                    onChange={handleCategoryChange}
                                                />
                                            }
                                            label={categoryMapping[englishCategory]} // Отображаем русское название
                                            key={englishCategory}
                                        />
                                    ))}
                                </FormGroup>
                            )}
                        </div>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Применить
                        </Button>
                    </DialogActions>
                    </DialogContent>
                </Dialog>
                </>
            )}

            {!isMobileOrTablet && showFilters && (
                <div className={`filters ${showFilters ? 'filters--open' : 'filters--closed'}`}>
                    {/* Цена */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('price')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Цена</h3>
                            <ArrowsIcon isOpen={isActive('price')} />
                        </div>
                        {isActive('price') && (
                            <div className="slider">
                                <div className="price-inputs">
                                    <div className="from-price">
                                        <label htmlFor="minPrice">От</label>
                                        <input
                                            type="number"
                                            id="minPrice"
                                            value={priceRange[0] > 0 ? priceRange[0] : ''} // Показать значение, если оно больше нуля
                                            placeholder="0"
                                            onChange={handleMinPriceChange}
                                            min={0}
                                            max={priceRange[1]} 
                                        />
                                    </div>
                                    <div className="to-price">
                                        <label htmlFor="maxPrice">До</label>
                                        <input
                                            type="number"
                                            id="maxPrice"
                                            value={priceRange[1] > 0 ? priceRange[1] : ''} // Показать значение, если оно больше нуля
                                            placeholder={maxPrice}
                                            onChange={handleMaxPriceChange}
                                            min={priceRange[0]} 
                                            max={maxPrice}
                                        />
                                    </div>
                                </div>
                                <Slider
                                    value={priceRange}
                                    onChange={handlePriceChange}
                                    valueLabelDisplay="on"
                                    min={0}
                                    max={maxPrice}
                                />
                            </div>
                        )}
                    </div>

                    {/* Цвет */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('color')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Цвет</h3>
                            <ArrowsIcon isOpen={isActive('color')} />
                        </div>
                        {isActive('color') && (
                            <FormGroup>
                                {['белый', 'черный', 'красный', 'розовый', 'золото', 'серебро', 'серый', 'синий'].map(color => (
                                    <FormControlLabel
                                        control={<Checkbox value={color} checked={selectedColors.includes(color)} onChange={handleColorChange} />}
                                        label={color}
                                        key={color}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>

                    {/* Материал */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('material')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Материал</h3>
                            <ArrowsIcon isOpen={isActive('material')} />
                        </div>
                        {isActive('material') && (
                            <FormGroup>
                                {['агат', 'жемчуг', 'гематит', 'серебро', 'фианит', 'хрусталь'].map(material => (
                                    <FormControlLabel
                                        control={<Checkbox value={material} checked={selectedMaterials.includes(material)} onChange={handleMaterialChange} />}
                                        label={material}
                                        key={material}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>

                    {/* Категория */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('category')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Категория</h3>
                            <ArrowsIcon isOpen={isActive('category')} />
                        </div>
                        {isActive('category') && (
                            <FormGroup>
                                {Object.keys(categoryMapping).map(englishCategory => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={englishCategory} // Используем английское название для значения
                                                checked={selectedCategories.includes(englishCategory)}
                                                onChange={handleCategoryChange}
                                            />
                                        }
                                        label={categoryMapping[englishCategory]} // Отображаем русское название
                                        key={englishCategory}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>
                </div>
            )}

<div className="category__card__container">
            <div className="category__product__card">
                {filteredItems.slice(0, itemsToShow).map((data) => (
                    <ItemCard key={data.id} item={data} category="all" />
                ))}
            </div>
            {filteredItems.length > itemsToShow && (
                <div className="show__more__action">
                    <Button 
                        variant='outlined' 
                        sx={{ 
                            width: '200px', 
                            height: '0px', 
                            borderRadius: '20px', 
                            fontWeight: '700', 
                            backgroundColor: '#FFE26E', 
                            borderColor: '#FFE26E', 
                            color: 'black', 
                            '&:hover': { 
                                borderColor: '#FFE26E', 
                                backgroundColor: "none" 
                            } 
                        }} 
                        onClick={handleShowMore}
                    >
                    </Button>
                </div>
            )}
        </div>
    </div>
);
}

export default Shop;