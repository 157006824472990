import React, { useState, createContext, useEffect } from "react";
export const CartItemsContext = createContext({
    items: [],
    totalAmount: 0,
    addItem: (item) => {},
    removeItem: (item) => {},
    quantity: (id, action) => {},
    clearCart: () => {}, // Добавляем новую функцию
});

export const CartItemsProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    // Инициализация корзины из localStorage
    useEffect(() => {
        try {
            const savedCart = localStorage.getItem('cart');
            if (savedCart) {
                const parsedCart = JSON.parse(savedCart);
                if (Array.isArray(parsedCart)) {
                    setCartItems(parsedCart);
                }
            }
        } catch (error) {
            console.error("Failed to load cart from localStorage:", error);
        } finally {
            setInitialized(true);
        }
    }, []);

    // Сохранение корзины в localStorage
    useEffect(() => {
        if (initialized) {
            try {
                localStorage.setItem('cart', JSON.stringify(cartItems));
            } catch (error) {
                console.error("Failed to save cart to localStorage:", error);
            }
        }
    }, [cartItems, initialized]);

    const addItem = (item) => {
        setCartItems((prevItems) => {
            const existingItemIndex = prevItems.findIndex((i) => i._id === item._id);
            if (existingItemIndex >= 0) {
                const updatedItem = { 
                    ...prevItems[existingItemIndex], 
                    quantity: prevItems[existingItemIndex].quantity + (item.quantity || 1) 
                };
                return prevItems.map((i, index) => 
                    index === existingItemIndex ? updatedItem : i
                );
            }
            return [...prevItems, { 
                ...item, 
                quantity: item.quantity || 1,
                type: item.type || "-",
                description: item.description || "-"
            }];
        });
    };

    const removeItem = (item) => {
        setCartItems((prevItems) => 
            prevItems.filter((i) => i._id !== item._id)
        );
    };

    const quantity = (id, action) => {
        setCartItems((prevItems) =>
            prevItems.map((item) => {
                if (item._id === id) {
                    const newQuantity = action === 'INC' 
                        ? item.quantity + 1 
                        : Math.max(item.quantity - 1, 1);
                    return { ...item, quantity: newQuantity };
                }
                return item;
            })
        );
    };

    const totalAmount = cartItems.reduce(
        (sum, item) => sum + (item.price * item.quantity), 
        0
    );
	const clearCart = () => {
        setCartItems([]);
    };

    return (
        <CartItemsContext.Provider value={{ 
            items: cartItems, 
            totalAmount, 
            addItem, 
            removeItem, 
            quantity, 
            clearCart // Добавляем в провайдер
        }}>
            {initialized ? children : null}
        </CartItemsContext.Provider>
    );
};