import './Category.css';
import ItemCard from '../Card/ItemCard/ItemCard';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TabTitle } from '../../utils/General';
import axios from "axios";
import { Button, Slider, Checkbox, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Category = (props) => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: props.name, path: `/category/${props.category}` },
    ];

    TabTitle(props.name);

    const [show, setShow] = useState('All');
    const [filter, setFilter] = useState('');
    const [priceRange, setPriceRange] = useState([0, 10000]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(50);
    const [activeFilter, setActiveFilter] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [maxPrice, setMaxPrice] = useState(10000);  // Добавьте состояние для maxPrice
	const [groupedItems, setGroupedItems] = useState({});
	
    const [filteredItems, setFilteredItems] = useState([]);

    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));  

    const [items, setItems] = useState([]);
    useEffect(() => {
        if (props.category) {
            // Добавляем категорию в selectedCategories
            setSelectedCategories([props.category.toLowerCase()]);
            // Применяем фильтры
            applyFilters();
        }
    }, [props.category]);
	const desiredCategories = ['dreamdrop', 'babushkindrop', 'new', 'pearl', 'crystal', 'naturalstones', 'basic', 'anklets', 'sertifikaty'];
    const categoryOrder = [
        'chokers', 'necklace', 'sergi', 'pendants', 'scarves', 'anklets', 'new', 'bracelets', 'bestsellers', 'dreamdrop', 'babushkindrop', 'pearl', 'crystal', 'naturalstones', 'basic', 'sertifikaty'
    ];
	const getCategoryPriority = (category) => {
        const index = categoryOrder.indexOf(category);
        return index !== -1 ? index : categoryOrder.length; // Если категория не найдена, она будет в конце
    };
    const groupItemsByCategory = (items) => {
        const grouped = {};
        items.forEach(item => {
            // Находим первую категорию из desiredCategories
            const primaryCategory = item.categories.find(cat => desiredCategories.includes(cat));
            if (primaryCategory) {
                if (!grouped[primaryCategory]) {
                    grouped[primaryCategory] = [];
                }
                grouped[primaryCategory].push(item);
            }
        });

        // Сортируем группы по порядку categoryOrder
        const sortedGroups = Object.keys(grouped).sort((a, b) => {
            const priorityA = getCategoryPriority(a);
            const priorityB = getCategoryPriority(b);
            return priorityA - priorityB;
        });

        // Создаем новый объект с отсортированными ключами
        const sortedGrouped = {};
        sortedGroups.forEach(category => {
            sortedGrouped[category] = grouped[category];
        });
    
        return sortedGrouped;
    };
	const sortItemsByCategoryPriority = (items) => {
        return items.sort((a, b) => {
            const priorityA = Math.min(...a.categories.map(cat => getCategoryPriority(cat)));
            const priorityB = Math.min(...b.categories.map(cat => getCategoryPriority(cat)));
            return priorityA - priorityB;
        });
    };
    useEffect(() => {
        axios.get("https://carabine.shop/api/items")
            .then(res => {
                const transformedItems = res.data.map(item => ({
                    ...item,
                    quantity: item.quantity ?? 0,
                    categories: item.category.split(',').map(cat => cat.trim().toLowerCase())
                }));

                // Если указана категория, фильтруем товары по этой категории
                let filteredItems = transformedItems;
                if (props.category) {
                    filteredItems = transformedItems.filter(item =>
                        item.categories.includes(props.category.toLowerCase())
                    );
                }

                // Фильтруем товары по желаемым категориям
                const filteredByDesiredCategories = filteredItems.filter(item =>
                    item.categories.some(category => desiredCategories.includes(category))
                );

                // Сортируем товары по приоритету категорий
                const sortedItems = sortItemsByCategoryPriority(filteredByDesiredCategories);
    
                // Применяем выбранный фильтр сортировки
                const sortedAndFilteredItems = sortItems(sortedItems, filter);

                setItems(sortedAndFilteredItems);
                setFilteredItems(sortedAndFilteredItems);

                const maxPriceInItems = Math.max(...transformedItems.map(item => item.price));
                setMaxPrice(maxPriceInItems);
            })
            .catch(err => console.log(err));
        window.scrollTo(0, 0);
    }, [props.category, filter]); // Зависимость от props.category и filter
	
    
    useEffect(() => {
        console.log("Selected Categories:", selectedCategories); // Логируем выбранные категории
        applyFilters();
    }, [priceRange, selectedColors, selectedMaterials, selectedCategories, filter]);

    const applyFilters = (priceRangeOverride) => {
        const range = priceRangeOverride || priceRange;
        let filtered = items;

        // Применяем фильтры
        filtered = filtered.filter(item => item.price >= range[0] && item.price <= range[1]);

        if (selectedColors.length > 0) {
            filtered = filtered.filter(item => selectedColors.includes(item.color?.toLowerCase()));
        }

        if (selectedMaterials.length > 0) {
            filtered = filtered.filter(item => selectedMaterials.includes(item.highlights?.toLowerCase()));
        }

        if (selectedCategories.length > 0) {
            console.log("Filtering by categories:", selectedCategories); // Логируем выбранные категории
            filtered = filtered.filter(item =>
                item.categories.some(category => selectedCategories.includes(category))
            );
        }

        // Если никакие фильтры не выбраны, показываем только товары из desiredCategories
        if (
            selectedCategories.length === 0 &&
            selectedColors.length === 0 &&
            selectedMaterials.length === 0 &&
            range[0] === 0 &&
            range[1] === maxPrice
        ) {
            filtered = filtered.filter(item =>
                item.categories.some(category => desiredCategories.includes(category))
            );
        }

        // Сортируем товары
        filtered = sortItems(filtered, filter);

        // Группируем отфильтрованные товары
        const groupedFilteredItems = groupItemsByCategory(filtered);
        setGroupedItems(groupedFilteredItems);
        setFilteredItems(filtered);
    };
    
    const itemsWithArrayCategories = props.items.map(item => ({
        ...item,
        categories: Array.isArray(item.category) ? item.category : [item.category]
    }));
    // Функция для сортировки товаров
    const sortItems = (items, sortFilter) => {
        switch (sortFilter) {
            case 'Сначала дешевле':
                return [...items].sort((a, b) => a.price - b.price); // По возрастанию
            case 'Сначала дороже':
                return [...items].sort((a, b) => b.price - a.price); // По убыванию
            case 'Популярные':
                return [...items].sort((a, b) => b.popularity - a.popularity);
            case 'Новинки':
                return [...items].sort((a, b) => new Date(b.date) - new Date(a.date));
            default:
                return items;
        }
    };

    const handleShowChange = (event) => {
        setShow(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handlePriceChange = (event, newValue) => {
        if (Array.isArray(newValue) && newValue.length === 2) {
            setPriceRange(newValue);
            applyFilters(newValue);
        }
    };
    const handleMinPriceChange = (event) => {
        const newMinPrice = Number(event.target.value);
        setPriceRange((prev) => {
            const updatedRange = [newMinPrice, prev[1]];
            applyFilters(updatedRange);  // Применение фильтров с обновленным диапазоном цен
            return updatedRange;
        });
    };

    const handleMaxPriceChange = (event) => {
        const newMaxPrice = Number(event.target.value);
        setPriceRange((prev) => {
            const updatedRange = [prev[0], newMaxPrice];
            applyFilters(updatedRange);  // Применение фильтров с обновленным диапазоном цен
            return updatedRange;
        });
    };
    const handleColorChange = (event) => {
        const value = event.target.value;
        setSelectedColors((prev) => 
            prev.includes(value) ? prev.filter(color => color !== value) : [...prev, value]
        );
    };

    const handleMaterialChange = (event) => {
        const value = event.target.value;
        setSelectedMaterials((prev) => 
            prev.includes(value) ? prev.filter(highlights => highlights !== value) : [...prev, value]
        );
    };

    const handleCategoryChange = (event) => {
        const value = event.target.value.toLowerCase(); // Преобразуем в нижний регистр
        setSelectedCategories((prev) =>
            prev.includes(value) ? prev.filter(category => category !== value) : [...prev, value]
        );
    };


    const toggleFilter = (filterName) => {
        setActiveFilter(activeFilter === filterName ? null : filterName);
    };

    const handleShowMore = () => {
        setItemsToShow((prev) => prev + 20);
    };

    const isActive = (filterName) => activeFilter === filterName;
    const CustomArrow = () => {
        return <ArrowsIcon />;
    };
    const ArrowIcon = ({ isOpen }) => (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.88889 1H16.1111C16.3469 1 16.573 1.09514 16.7397 1.26448C16.9064 1.43382 17 1.6635 17 1.90299V3.33512C16.9999 3.57459 16.9063 3.80423 16.7396 3.97354L11.0382 9.76529C10.8715 9.9346 10.7778 10.1642 10.7778 10.4037V16.097C10.7778 16.2343 10.747 16.3697 10.6877 16.4931C10.6284 16.6164 10.5423 16.7244 10.4357 16.8089C10.3292 16.8933 10.2052 16.952 10.073 16.9804C9.94078 17.0089 9.80396 17.0063 9.67289 16.9729L7.89511 16.5214C7.70289 16.4725 7.53227 16.3598 7.41036 16.2011C7.28845 16.0424 7.22223 15.8468 7.22222 15.6455V10.4037C7.22217 10.1642 7.12849 9.9346 6.96178 9.76529L1.26044 3.97354C1.09373 3.80423 1.00005 3.57459 1 3.33512V1.90299C1 1.6635 1.09365 1.43382 1.26035 1.26448C1.42705 1.09514 1.65314 1 1.88889 1Z" stroke="#1B1B1B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
    useEffect(() => {
        if (props.category) {
            setSelectedCategories(prev => [...prev, props.category.toLowerCase()]);
        }
    }, [props.category]);
    const ArrowsIcon = ({ isOpen }) => (
        <svg 
          width="18" 
          height="9" 
          viewBox="0 0 18 9" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
        >
          <path d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z" fill="#1B1B1B"/>
        </svg>
    );

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };
    const categoryMapping = {
        chokers: 'чокеры',
        necklace: 'колье',
        pendants: 'подвески',
        scarves: 'шарфики',
        anklets: 'анклеты',
		new: 'новинки',
        sergi: 'серьги',
        braslette: 'браслеты',
        bestsellers: 'бестселлеры',
		dreamdrop: 'Dream Drop',
		babushkindrop: 'Babushkin Drop',
		new: 'NEW',
		pearl: 'Pearl',
		crystal: 'Crystal',
		naturalstones: 'Natural Stones',
		basic: 'Basic'
    };
    return (
        <div className="category__container">
            <Breadcrumbs items={breadcrumbItems} />
            <div className="category">
                <div className="category__header__container">
                    <div className="category__header__big">
                        <div className="category__header">
                            <h2>{props.name}</h2>
                        </div>
                    </div>
                    <div className="category__sort desc">
                        <div className="show__filter">
                            <Button onClick={() => setShowFilters(!showFilters)} variant="outlined">
                                Фильтры
                                <ArrowsIcon isOpen={showFilters} />
                            </Button>
                        </div>
                        <div className="show__filter">
                            <Box sx={{ width: 120 }}>
                                <FormControl fullWidth size="small">
                            <Select
                                value={filter}
                                onChange={handleFilterChange}
                                IconComponent={() => <ArrowsIcon isOpen={!!filter} />}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return 'Сортировка';
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>Сортировка</MenuItem>
                                <MenuItem value="Сначала дороже">Сначала дороже</MenuItem>
                                <MenuItem value="Сначала дешевле">Сначала дешевле</MenuItem>
                                <MenuItem value="Популярные">Популярные</MenuItem>
                                <MenuItem value="Новинки">Новинки</MenuItem>
                            </Select>
                        </FormControl>
                            </Box>
                        </div>
                    </div>
                    {isMobileOrTablet && (
                        <>
                        <div className="category__sort mob">
                            <div className="show__filter">
                                <Button onClick={handleOpenDialog} variant="outlined">
                                    Фильтры
                                    <ArrowIcon isOpen={showFilters} />
                                </Button>
                            </div>
                            <div className="show__filter">
                                <Box sx={{ width: 120 }}>
                                    <FormControl fullWidth size="small">
                            <Select
                                value={filter}
                                onChange={handleFilterChange}
                                IconComponent={() => <ArrowsIcon isOpen={!!filter} />}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return 'Сортировка';
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>Сортировка</MenuItem>
                                <MenuItem value="Сначала дороже">Сначала дороже</MenuItem>
                                <MenuItem value="Сначала дешевле">Сначала дешевле</MenuItem>
                                <MenuItem value="Популярные">Популярные</MenuItem>
                                <MenuItem value="Новинки">Новинки</MenuItem>
                            </Select>
                        </FormControl>
                                </Box>
                            </div>
                        </div>

                        <Dialog
                            fullScreen={isMobileOrTablet}
                            open={isDialogOpen}
                            onClose={handleCloseDialog}
                            aria-labelledby="filter-dialog-title"
                        >
                            <DialogTitle id="filter-dialog-title">
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseDialog}
                                    sx={{
                                        position: 'absolute',
                                        right: 19,
                                        top: 19,
                                        color: theme.palette.grey[500],
                                    }}
                                >
                                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.5907 9L18 16.4093L16.4093 18L9 10.5907L1.59075 18L0 16.4093L7.40925 9L0 1.59075L1.59075 0L9 7.40925L16.4093 0L18 1.59075L10.5907 9Z" fill="#1B1B1B"/>
                                   </svg>
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                {/* Цена */}
                                <div className="filter-section">
                                    <div onClick={() => toggleFilter('price')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                        <h3 style={{ marginRight: '18px' }}>Цена</h3>
                                        <ArrowsIcon isOpen={isActive('price')} />
                                    </div>
                                    {isActive('price') && (
                                        <div className="slider">
                                            <div className="price-inputs">
                                                <div className="from-price">
                                                    <label className="minPrice" htmlFor="minPrice">От</label>
                                                    <input
                                                        type="number"
                                                        id="minPrice"
                                                        value={priceRange[0]}
                                                        onChange={handleMinPriceChange}
                                                        min={0}
                                                        max={priceRange[1]} 
                                                    />
                                                </div>
                                                <div className="to-price">
                                                    <label className="maxPrice" htmlFor="maxPrice">До</label>
                                                    <input
                                                        type="number"
                                                        id="maxPrice"
                                                        value={priceRange[1]}
                                                         onChange={handleMaxPriceChange}
                                                        min={priceRange[0]} 
                                                        max={9999} 
                                                    />
                                                </div>
                                            </div>
                                            <Slider
                                                value={priceRange}
                                                onChange={handlePriceChange}
                                                valueLabelDisplay="on"
                                                min={0}
                                                max={9999}
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Цвет */}
                                <div className="filter-section">
                                    <div onClick={() => toggleFilter('color')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                        <h3 style={{ marginRight: '18px' }}>Цвет</h3>
                                        <ArrowsIcon isOpen={isActive('color')} />
                                    </div>
                                    {isActive('color') && (
                                        <FormGroup>
                                            {['белый', 'черный', 'красный', 'розовый', 'золото', 'серебро', 'серый', 'синий'].map(color => (
                                                <FormControlLabel
                                                    control={<Checkbox value={color} checked={selectedColors.includes(color)} onChange={handleColorChange} />}
                                                    label={color}
                                                    key={color}
                                                />
                                            ))}
                                        </FormGroup>
                                    )}
                                </div>

                                {/* Материал */}
                                <div className="filter-section">
                                    <div onClick={() => toggleFilter('highlights')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                        <h3 style={{ marginRight: '18px' }}>Материал</h3>
                                        <ArrowsIcon isOpen={isActive('highlights')} />
                                    </div>
                                    {isActive('highlights') && (
                                        <FormGroup>
                                            {['агат', 'жемчуг', 'гематит', 'серебро', 'фианит', 'хрусталь'].map(highlights => (
                                                <FormControlLabel
                                                    control={<Checkbox value={highlights} checked={selectedMaterials.includes(highlights)} onChange={handleMaterialChange} />}
                                                    label={highlights}
                                                    key={highlights}
                                                />
                                            ))}
                                        </FormGroup>
                                    )}
                                </div>

                                {/* Категория */}
                                <div className="filter-section">
                                    <div onClick={() => toggleFilter('category')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                                        <h3 style={{ marginRight: '18px' }}>Категория</h3>
                                        <ArrowsIcon isOpen={isActive('category')} />
                                    </div>
                                    {isActive('category') && (
                                        <FormGroup>
                                            {Object.keys(categoryMapping).map(englishCategory => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={englishCategory} // Используем английское название для значения
                                                            checked={selectedCategories.includes(englishCategory)}
                                                            onChange={handleCategoryChange}
                                                        />
                                                    }
                                                    label={categoryMapping[englishCategory]} // Отображаем русское название
                                                    key={englishCategory}
                                                />
                                            ))}
                                        </FormGroup>
                                    )}
                                </div>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Применить
                                </Button>
                            </DialogActions>
                            </DialogContent>
                        </Dialog>
                        </>
                    )}

            {!isMobileOrTablet && showFilters && (
                <div className={`filters ${showFilters ? 'filters--open' : 'filters--closed'}`}>
                    {/* Цена */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('price')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Цена</h3>
                            <ArrowsIcon isOpen={isActive('price')} />
                        </div>
                        {isActive('price') && (
                                <div className="slider">
                                    <div className="price-inputs">
                                        <div className="from-price">
                                            <label className="minPrice" htmlFor="minPrice">От</label>
                                            <input
                                                type="number"
                                                id="minPrice"
                                                value={priceRange[0] > 0 ? priceRange[0] : ''} // Показать значение, если оно больше нуля
                                                placeholder="0"
                                                onChange={handleMinPriceChange} 
                                                min={0}
                                                max={priceRange[1]} 
                                            />
                                        </div>
                                        <div className="to-price">
                                            <label className="maxPrice"  htmlFor="maxPrice">До</label>
                                            <input
                                                type="number"
                                                id="maxPrice"
                                                value={priceRange[1] > 0 ? priceRange[1] : ''} // Показать значение, если оно больше нуля
                                                placeholder={maxPrice}
                                                onChange={handleMaxPriceChange}
                                                min={priceRange[0]} 
                                                max={maxPrice}
                                            />
                                        </div>
                                    </div>
                                    <Slider
                                        value={priceRange}
                                        onChange={handlePriceChange}
                                        valueLabelDisplay="on"
                                        min={0}
                                        max={maxPrice}
                                    />
                                </div>
                            )}
                    </div>

                    {/* Цвет */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('color')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Цвет</h3>
                            <ArrowsIcon isOpen={isActive('color')} />
                        </div>
                        {isActive('color') && (
                            <FormGroup>
                                {['белый', 'черный', 'красный', 'розовый', 'золото', 'серебро', 'серый', 'синий'].map(color => (
                                    <FormControlLabel
                                        control={<Checkbox value={color} checked={selectedColors.includes(color)} onChange={handleColorChange} />}
                                        label={color}
                                        key={color}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>

                    {/* Материал */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('highlights')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Материал</h3>
                            <ArrowsIcon isOpen={isActive('highlights')} />
                        </div>
                        {isActive('highlights') && (
                            <FormGroup>
                                {['агат', 'жемчуг', 'гематит', 'серебро', 'фианит', 'хрусталь'].map(highlights => (
                                    <FormControlLabel
                                        control={<Checkbox value={highlights} checked={selectedMaterials.includes(highlights)} onChange={handleMaterialChange} />}
                                        label={highlights}
                                        key={highlights}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>

                    {/* Категория */}
                    <div className="filter-section">
                        <div onClick={() => toggleFilter('category')} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                            <h3 style={{ marginRight: '18px' }}>Категория</h3>
                            <ArrowsIcon isOpen={isActive('category')} />
                        </div>
                        {isActive('category') && (
                            <FormGroup>
                                {Object.keys(categoryMapping).map(englishCategory => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={englishCategory} // Используем английское название для значения
                                                checked={selectedCategories.includes(englishCategory)}
                                                onChange={handleCategoryChange}
                                            />
                                        }
                                        label={categoryMapping[englishCategory]} // Отображаем русское название
                                        key={englishCategory}
                                    />
                                ))}
                            </FormGroup>
                        )}
                    </div>
                </div>
            )}

                <div className="category__card__container">
                    <div className="category__product__card">
                        {filteredItems.slice(0, itemsToShow).map((data) => (
                            <ItemCard key={data.id} item={data} />
                        ))}
						{filteredItems.length === 0 && (
                            <p>Нет товаров в данной категории</p>
                        )}
                        {filteredItems.length > itemsToShow && (
                            <div className="show__more__action">
                                <Button 
                                    variant='outlined' 
                                    sx={{ 
                                        width: '200px', 
                                        height: '0px', 
                                        borderRadius: '20px', 
                                        fontWeight: '700', 
                                        backgroundColor: '#FFE26E', 
                                        borderColor: '#FFE26E', 
                                        color: 'black', 
                                        '&:hover': { 
                                            borderColor: '#FFE26E', 
                                            backgroundColor: "none" 
                                        } 
                                    }} 
                                    onClick={handleShowMore}
                                >
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
                </div>
            </div>
    );
}

export default Category;