import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Modal } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const CategoryUpload = () => {
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [url, setUrl] = useState('');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [editCategory, setEditCategory] = useState(null); // Данные для редактирования
    const [openModal, setOpenModal] = useState(false); // Состояние модального окна

    // Получаем список категорий при загрузке компонента
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://carabine.shop/api/categories');
            setCategories(response.data);
        } catch (error) {
        }
    };

    const handleUpload = async () => {
        if (!file || !name || !text || !url) {
            setMessage('Пожалуйста, заполните все поля и выберите файл');
            return;
        }

        const formData = new FormData();
        formData.append('category', file);
        formData.append('name', name);
        formData.append('text', text);
        formData.append('url', url);

        try {
            const response = await axios.post('https://carabine.shop/api/categories', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessage(response.data.message);
            fetchCategories(); // Обновляем список категорий
            setName('');
            setText('');
            setUrl('');
            setFile(null);
        } catch (error) {
            setMessage('Ошибка при загрузке коллекции');
        }
    };

    const handleEdit = (category) => {
        setEditCategory(category); // Устанавливаем данные для редактирования
        setOpenModal(true); // Открываем модальное окно
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://carabine.shop/api/categories/${id}`);
            fetchCategories(); // Обновляем список категорий
            setMessage('Категория успешно удалена');
        } catch (error) {
            setMessage('Ошибка при удалении коллекции');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (file) formData.append('category', file);
            formData.append('name', editCategory.name);
            formData.append('text', editCategory.text);
            formData.append('url', editCategory.url);

            await axios.put(`https://carabine.shop/api/categories/${editCategory.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setOpenModal(false); // Закрываем модальное окно
            fetchCategories(); // Обновляем список категорий
            setMessage('Категория успешно обновлена');
        } catch (error) {
            setMessage('Ошибка при обновлении коллекции');
        }
    };

    return (
        <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>Управление коллекциями</Typography>

            {/* Список категорий */}
            <Typography variant="h6" component="h3" gutterBottom>Список коллекций</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Текст</TableCell>
                            <TableCell>Изображение</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow key={category.id}>
                                <TableCell>{category.name}</TableCell>
                                <TableCell>{category.text}</TableCell>
                                <TableCell>
                                    <img src={`https://carabine.shop/api/public${category.image_url}`} alt={category.name} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                </TableCell>
                                <TableCell>{category.url}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(category)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(category.id)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Форма загрузки новой коллекции */}
            <Typography variant="h6" component="h3" gutterBottom>Добавить новую коллекцию</Typography>
            <TextField
                label="Название коллекции"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Текст коллекции"
                value={text}
                onChange={(e) => setText(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="URL коллекции"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
                margin="normal"
            />
            <input
                type="file"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: 'block', marginBottom: '16px' }}
            />
            <Button variant="contained" color="primary" onClick={handleUpload}>
                Загрузить коллекции
            </Button>
            {message && <Typography>{message}</Typography>}
            {/* Модальное окно для редактирования */}
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography variant="h6" component="h2" gutterBottom>Редактировать коллекции</Typography>
                    <form onSubmit={handleUpdate}>
                        <TextField
                            label="Название коллекции"
                            value={editCategory?.name || ''}
                            onChange={(e) => setEditCategory({ ...editCategory, name: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Название коллекции"
                            value={editCategory?.text || ''}
                            onChange={(e) => setEditCategory({ ...editCategory, text: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="URL коллекции"
                            value={editCategory?.url || ''}
                            onChange={(e) => setEditCategory({ ...editCategory, url: e.target.value })}
                            fullWidth
                            margin="normal"
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                            style={{ display: 'block', marginBottom: '16px' }}
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Сохранить изменения
                        </Button>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
};

export default CategoryUpload;