import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Thanks.css'; // Подключите стили, если необходимо

const Thanks = () => {
    const navigate = useNavigate();

    const handleReturn = () => {
        navigate('/');
    };

    return (
        <div className="thanks-container">
            <h2>благодарим за ваш заказ!</h2>
            <p>в ближайшее время мы свяжемся с вами,<br />для уточнения деталей</p>
            <button className="closepopups" onClick={() => {
                window.location.href = '/'; // Перенаправление на главную страницу с перезагрузкой
            }}>Продолжить покупки</button>
        </div>
    );
};

export default Thanks;
