import React, { useContext, useState } from "react";
import { CartItemsContext } from "./CartItemsContext";
import { WishItemsContext } from "./WishItemsContext";

const WishItemsProvider = (props) => {
    const [wishItems, setWishItems] = useState([]);
    const cartItems = useContext(CartItemsContext);

    const addToCartHandler = (item) => {
        cartItems.addItem(item, 1);
    };

    const addToWishHandler = (item) => {
        const { _id, name, price, image, category, size } = item;
        // Удаляем элемент, если он уже в списке, чтобы избежать дублирования
        setWishItems((prevItems) => prevItems.filter((prevItem) => prevItem._id !== item._id));
        setWishItems((prevItems) => [...prevItems, { _id, name, price, image, category, size, itemQuantity: 1 }]);
    };

    const removeFromWishHandler = (itemId) => {
        setWishItems((prevItems) => prevItems.filter((prevItem) => prevItem._id !== itemId));
    };

    const wishItemsCtx = {
        items: wishItems,
        addItem: addToWishHandler,
        removeItem: removeFromWishHandler,
        addToCart: addToCartHandler
    };

    return (
        <WishItemsContext.Provider value={wishItemsCtx}>
            {props.children}
        </WishItemsContext.Provider>
    );
};

export default WishItemsProvider;