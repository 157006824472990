import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import CategoryCard from "../../Card/FeaturedCard/CategoryCard";
import Slider from 'react-slick';
import './FeaturedCategories.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Categories = () => {
    const [featuredCategories, setFeaturedCategories] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const sliderRef = useRef(null);

    useEffect(() => {
        // Получаем категории из API
        axios.get('https://carabine.shop/api/categories')
            .then(response => {
                setFeaturedCategories(response.data);
            })
            .catch(error => {
            });

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth < 440; // Mobile view
    const isTablet = windowWidth >= 440 && windowWidth < 1366; // Tablet view
    const isDesktop = windowWidth >= 1366; // Desktop view

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 1 : isTablet ? 2 : 6, // Adjusted for better view
        slidesToScroll: 1, // Scroll one slide at a time
        arrows: false,
        centerMode: false, // Ensure center mode is disabled
        variableWidth: false, // Ensure variable width is disabled
        afterChange: (current) => {
            sliderRef.current.slickGoTo(current);
        }
    };

    return ( 
        <div className="featured__categories__container">
            <div className="featured__categories">
                <Slider {...sliderSettings} ref={sliderRef}>
                    {featuredCategories.map((category) => (
                        <div key={category.id} className="custom-carousel-item">
                            <div className="carousel__image__container">
                                <CategoryCard data={category} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>  
    );
}

export default Categories;