import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const ArrowIcon = ({ isOpen }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  return isMobileOrTablet ? (
    // SVG для мобильных устройств и планшетов
    <svg 
      width="18" 
      height="18" 
      viewBox="0 0 18 18" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
    >
      <path d="M1.88889 1H16.1111C16.3469 1 16.573 1.09514 16.7397 1.26448C16.9064 1.43382 17 1.6635 17 1.90299V3.33512C16.9999 3.57459 16.9063 3.80423 16.7396 3.97354L11.0382 9.76529C10.8715 9.9346 10.7778 10.1642 10.7778 10.4037V16.097C10.7778 16.2343 10.747 16.3697 10.6877 16.4931C10.6284 16.6164 10.5423 16.7244 10.4357 16.8089C10.3292 16.8933 10.2052 16.952 10.073 16.9804C9.94078 17.0089 9.80396 17.0063 9.67289 16.9729L7.89511 16.5214C7.70289 16.4725 7.53227 16.3598 7.41036 16.2011C7.28845 16.0424 7.22223 15.8468 7.22222 15.6455V10.4037C7.22217 10.1642 7.12849 9.9346 6.96178 9.76529L1.26044 3.97354C1.09373 3.80423 1.00005 3.57459 1 3.33512V1.90299C1 1.6635 1.09365 1.43382 1.26035 1.26448C1.42705 1.09514 1.65314 1 1.88889 1Z" stroke="#1B1B1B" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ) : (
    // SVG для десктопов
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.88889 1H16.1111C16.3469 1 16.573 1.09514 16.7397 1.26448C16.9064 1.43382 17 1.6635 17 1.90299V3.33512C16.9999 3.57459 16.9063 3.80423 16.7396 3.97354L11.0382 9.76529C10.8715 9.9346 10.7778 10.1642 10.7778 10.4037V16.097C10.7778 16.2343 10.747 16.3697 10.6877 16.4931C10.6284 16.6164 10.5423 16.7244 10.4357 16.8089C10.3292 16.8933 10.2052 16.952 10.073 16.9804C9.94078 17.0089 9.80396 17.0063 9.67289 16.9729L7.89511 16.5214C7.70289 16.4725 7.53227 16.3598 7.41036 16.2011C7.28845 16.0424 7.22223 15.8468 7.22222 15.6455V10.4037C7.22217 10.1642 7.12849 9.9346 6.96178 9.76529L1.26044 3.97354C1.09373 3.80423 1.00005 3.57459 1 3.33512V1.90299C1 1.6635 1.09365 1.43382 1.26035 1.26448C1.42705 1.09514 1.65314 1 1.88889 1Z" stroke="#1B1B1B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default ArrowIcon;