import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './FAQs.css';
import { TabTitle } from '../../utils/General';

const FAQs = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'Часто задаваемые вопросы', path: '/chasto-zadavaemye-voprosy' },
    ];

    TabTitle('Часто задаваемые вопросы');
    // Состояния для каждого аккордеона
    const [openSections, setOpenSections] = useState({
        courierFAQs: false,
        sdekFAQs: false,
        selfPickup: false,
        payment: false,
		samovivozFAQs: false,
		dostFAQs: false,
		otslFAQs: false,
		vozFAQs: false,
		brackFAQs: false,
		offlineFAQs: false,
    });

    const toggleAccordion = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const ArrowIcon = ({ isOpen }) => (
        <svg
            width="18"
            height="9"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
            <path
                d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z"
                fill="#1B1B1B"
            />
        </svg>
    );

    return (
        <div className="FAQs">
            <div className="FAQs__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="Accordion">
                    <span>Часто задаваемые вопросы</span>
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('courierFAQs')} style={{ cursor: 'pointer' }}>
                        <h1 style={{ margin: '10px 0', height: '75px' }}>ЕСЛИ МНЕ НЕ ПОДХОДИТ СТАНДАРТНЫЙ РАЗМЕР ИЗДЕЛИЯ, МОЖНО ЛИ ЕГО ИЗМЕНИТЬ?</h1>
                        <ArrowIcon isOpen={openSections.courierFAQs} />
                    </div>
                    {openSections.courierFAQs && (
                        <div className={`Accordion__content ${openSections.courierFAQs ? 'open' : ''}`}>
                           <p>
						      Да, мы можем сделать все изделия по вашим индивидуальным меркам.
						      Оставьте ваши пожелания и размеры в окошке для комментариев при оформлении заказа. Если вы уже сделали заказ и не внесли комментарии, то напишите нам в Instagram или <a href="https://wa.me/79774757069">What’s app</a>.						
						   </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('sdekFAQs')} style={{ cursor: 'pointer' }}>
                       <h1>КАКИЕ У ВАС ЕСТЬ СПОСОБЫ ОПЛАТЫ?</h1>
                       <ArrowIcon isOpen={openSections.sdekFAQs} />
                    </div>
                    {openSections.sdekFAQs && (
                        <div className={`Accordion__content ${openSections.sdekFAQs ? 'open' : ''}`}>
                            <p>
                               Оплата заказа доступна на сайте банковской картой или через СБП. Также, вы можете оплатить заказ переводом или наличными по адресу самовывоза в Москве.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('samovivozFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>КАК ОФОРМИТЬ САМОВЫВОЗ?</h1>
                        <ArrowIcon isOpen={openSections.samovivozFAQs} />
                    </div>
                    {openSections.samovivozFAQs && (
                        <div className={`Accordion__content ${openSections.samovivozFAQs ? 'open' : ''}`}>
                            <p>
                               После осуществления заказа, мы свяжемся с вами по номеру телефона в <a href="https://wa.me/79774757069">What’s app</a> для уточнений деталей и доступного времени для самовывоза.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('dostFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>ЕСТЬ ЛИ У ВАС МЕЖДУНАРОДНАЯ ДОСТАВКА?</h1> 
                        <ArrowIcon isOpen={openSections.samovivozFAQs} />
                    </div>
                    {openSections.dostFAQs && (
                        <div className={`Accordion__content ${openSections.dostFAQs ? 'open' : ''}`}>
                            <p>
                               На данный момент мы отправляем заказы по России и в страны СНГ.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('otslFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>ГДЕ МОЖНО ОТСЛЕЖИВАТЬ ЗАКАЗ?</h1> 
                        <ArrowIcon isOpen={openSections.otslFAQs} />
                    </div>
                    {openSections.otslFAQs && (
                        <div className={`Accordion__content ${openSections.otslFAQs ? 'open' : ''}`}>
                            <p>
                               После оформления заказа, номер отслеживания придет вам на указанный номер телефона. Если сообщение не пришло в течение 5 рабочих дней, свяжитесь с нами в Instagram или <a href="https://wa.me/79774757069">What’s app</a>.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('vozFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>КАК ОСУЩЕСТВЛЯТЬ ВОЗВРАТ ТОВАРА?</h1> 
                        <ArrowIcon isOpen={openSections.vozFAQs} />
                    </div>
                    {openSections.vozFAQs && (
                        <div className={`Accordion__content ${openSections.vozFAQs ? 'open' : ''}`}>
                            <p>
                               Для оформления возврата свяжитесь с нами в <a href="https://wa.me/79774757069">What’s app</a> или Instagram.
                            </p>
                            <p>
							   Возврат обрабатывается в течение 10 рабочих дней с момента получения заявки.
                            </p>
                            <p>
							   Подробнее читайте в разделе «Возврат и обмен».
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('brackFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>НА ВЕЩИ ОБНАРУЖЕН БРАК, ЧТО ДЕЛАТЬ?</h1>
                        <ArrowIcon isOpen={openSections.brackFAQs} />
                    </div>
                    {openSections.brackFAQs && (
                        <div className={`Accordion__content ${openSections.brackFAQs ? 'open' : ''}`}>
                            <p>
							  Просим вас сделать фотографию дефекта и связаться с нами в <a href="https://wa.me/79774757069">What’s app</a> или Instagram. При подтверждении брака, у нас доступен обмен на новое изделие или возврат денежных средств. В случае обнаружения дефектов доставка оплачивается за наш счет.
                            </p>
                        </div>
                    )}
                </div>
				<div className="Accordion">
                    <div className="Accordion_bottom" onClick={() => toggleAccordion('offlineFAQs')} style={{ cursor: 'pointer' }}>
                        <h1>КАК УЗНАТЬ НАЛИЧИЕ В ОФФЛАЙН МАГАЗИНАХ?</h1>
                        <ArrowIcon isOpen={openSections.offlineFAQs} />
                    </div>
                    {openSections.offlineFAQs && (
                        <div className={`Accordion__content ${openSections.offlineFAQs ? 'open' : ''}`}>
                            <p>
							   Информацию по наличию наших изделий в оффлайн точках уточняйте напрямую в магазинах.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FAQs;
