import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Landing from "../components/Landing/Landing";
import FeaturedItems from "../components/Featured/Items/FetauredItems";
import FeaturedCategories from "../components/Featured/Categories/FeaturedCategories";
import ItemList from "../components/ItemList/ItemList";
import NewItems from "../components/Featured/NewItems/NewItems";
import { TabTitle } from "../utils/General";

const Home = () => {
    const [featuredItems, setFeaturedItems] = useState([]);
    const [featuredCategories, setFeaturedCategories] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const [banners, setBanners] = useState([]);

    TabTitle("CARABINE");

    useEffect(() => {
        // Fetch featured items
        axios.get("https://carabine.shop/api/items")
            .then(res => setFeaturedItems(res.data))
            .catch(err => console.log(err));

        // Fetch featured categories
        axios.get("https://carabine.shop/api/categories")
            .then(res => setFeaturedCategories(res.data))
            .catch(err => console.log(err));

        // Fetch new items
        axios.get("https://carabine.shop/api/items")
            .then(res => setNewItems(res.data))
            .catch(err => console.log(err));

        // Fetch banners
        axios.get("https://carabine.shop/api/banner")
            .then(res => setBanners(res.data))
            .catch(err => console.log(err));

        window.scrollTo(0, 0);
    }, []);

    return ( 
        <Fragment>
            <Landing banners={banners} />
            <FeaturedItems items={featuredItems}/>
            <ItemList /> 
            <FeaturedCategories items={featuredCategories}/>
            <NewItems items={newItems} />
        </Fragment>
    );
}
 
export default Home;