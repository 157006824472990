import React from 'react';
import { Outlet, Link, Navigate } from 'react-router-dom';
import './Admin.css';

const AdminLayout = ({ isAdmin }) => {
    if (!isAdmin) {
        return <Navigate to="/admin/login" />;
    }

    return (
        <div className="admin-container">
            <div className="admin-sidebar">
                <ul>
                    <li><Link to="all-items">Все товары</Link></li>
                    <li><Link to="add-item">Добавить товар</Link></li>
                    <li><Link to="orders">Заказы</Link></li>
                    <li><Link to="banner-upload">Загрузить баннер</Link></li>
                    <li><Link to="category-upload">Коллекции</Link></li>
                    <li><Link to="promocode">Промокоды</Link></li>
                </ul>
            </div>
            <div className="admin-content">
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;