import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Container, TextField, Typography, CircularProgress, MenuItem, Select, InputLabel, FormControl, LinearProgress } from '@mui/material';
import Compressor from 'compressorjs';

const AddItem = () => {
  const [item, setItem] = useState({
    name: '',
    category: [],
    color: '',
    type: '',
    quantity: '',
    description: '',
    price: '',
    size: '',
    highlights: '',
    detail: '',
    images: []
  });

  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_BASE_URL = 'https://carabine.shop/api';

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'category') {
      setItem({ ...item, [name]: Array.isArray(value) ? value : [value] });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const compressImage = (file, callback) => {
    new Compressor(file, {
      quality: 0.90,
      maxWidth: 1920,
      maxHeight: 1080,
      success(result) {
        callback(result);
      },
      error(err) {
        callback(file);
      },
    });
  };

  const handleFileChange = async (e) => {
      const files = e.target.files;
      const MAX_IMAGES = 10;

      if (files.length > MAX_IMAGES) {
          alert(`Вы можете выбрать не более ${MAX_IMAGES} изображений.`);
          return;
      }

      setPreviewImages([]); // Очищаем предыдущие превью
      const compressedFiles = [];
      const previews = [];

      const compressionPromises = Array.from(files).map((file, index) => {
          return new Promise((resolve, reject) => {
              if (file.size > 15 * 1024 * 1024) {
                  alert('Файл слишком большой. Максимальный размер: 25 MB.');
                  reject(new Error('Файл слишком большой.'));
                  return;
              }

              if (!Compressor) {
                  reject(new Error('Библиотека Compressor не доступна.'));
                  return;
              }

              compressImage(file, (compressedFile) => {
                  if (!compressedFile) {
                      reject(new Error('Ошибка при сжатии изображения.'));
                      return;
                  }

                  compressedFiles.push(compressedFile);

                  const reader = new FileReader();
                  reader.onload = (event) => {
                      previews.push(event.target.result);
                      setPreviewImages([...previews]); // Обновляем состояние превью
                  };
                  reader.readAsDataURL(compressedFile);

                  resolve(compressedFile);
              });
          });
      });

      try {
          await Promise.all(compressionPromises);

          if (compressedFiles.length === 0) {
              throw new Error('Нет успешно сжатых изображений.');
          }

          setItem({ ...item, images: compressedFiles }); // Обновляем состояние item.images
      } catch (error) {
          setError('Произошла ошибка при загрузке изображений.');
      }
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      if (!item.images || item.images.length === 0) {
          setError('Необходимо выбрать хотя бы одно изображение.');
          setLoading(false);
          return;
      }

      const formData = new FormData();
      formData.append('name', item.name);
      formData.append('category', item.category.join(','));
      formData.append('color', item.color);
      formData.append('quantity', item.quantity);
      formData.append('type', item.type);
      formData.append('description', item.description);
      formData.append('price', item.price);
      formData.append('size', item.size.split(',').map(s => s.trim()).join(','));
      formData.append('highlights', item.highlights.split(',').map(h => h.trim()).join(','));
      formData.append('detail', item.detail);

      for (let i = 0; i < item.images.length; i++) {
          formData.append('images', item.images[i]);
      }

      for (let pair of formData.entries()) {
      }

      try {
          await axios.post(`${API_BASE_URL}/items`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
          setLoading(false);
          window.location.href = '/admin/all-items';
      } catch (err) {
          setError('Ошибка при добавлении товара');
          setLoading(false);
      }
  };


  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>Добавить товар</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Название товара"
            name="name"
            value={item.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Артикул"
            name="description"
            value={item.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Категория</InputLabel>
            <Select
              name="category"
              multiple // Добавлено для множественного выбора
              value={item.category}
              onChange={handleChange}
              label="Категория"
            >
              <MenuItem value="chokers">Чокеры</MenuItem>
              <MenuItem value="pendants">Подвески</MenuItem>
              <MenuItem value="necklace">Колье</MenuItem>
              <MenuItem value="scarves">Шарфики</MenuItem>
              <MenuItem value="anklets">Анклеты</MenuItem>
              <MenuItem value="sergi">Серьги</MenuItem>
              <MenuItem value="braslette">Браслеты</MenuItem>
              <MenuItem value="stikery">Стикеры</MenuItem>
              <MenuItem value="sertifikaty">Сертификаты</MenuItem>
              <MenuItem value="packaging">Упаковка</MenuItem>
              <MenuItem value="bestsellers">Бестселлеры</MenuItem>
              <MenuItem value="new">Новинки</MenuItem>
              <MenuItem value="basic">BASIC</MenuItem>
              <MenuItem value="pearl">PEARL</MenuItem>
              <MenuItem value="crystal">CRYSTAL</MenuItem>
              <MenuItem value="giftcard">GIFT CARD</MenuItem>
              <MenuItem value="naturalstones">Natural stones</MenuItem>
              <MenuItem value="babushkindrop">Babushkin Drop</MenuItem>
              <MenuItem value="dreamdrop">Dream Drop</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Цвет"
            name="color"
            value={item.color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Состав"
            name="type"
            value={item.type}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Количество"
            name="quantity"
            value={item.quantity}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Цена"
            name="price"
            value={item.price}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            required
          />
          <TextField
            label="Размер"
            name="size"
            value={item.size}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Материал"
            name="highlights"
            value={item.highlights}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Детали"
            name="detail"
            value={item.detail}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
         {/* Изображения */}
          <Box mt={2}>
            <Typography variant="h6">Изображения</Typography>
            <input type="file" name="images" multiple accept="image/jpeg,image/png" onChange={handleFileChange} required />
          </Box>

          {/* Отображение превью изображений */}
          <Box mt={2}>
            {previewImages.length > 0 && (
              <div style={{ display: 'flex', gap: '10px' }}>
                {previewImages.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    style={{ width: '10%', height: 'auto', marginRight: '10px', objectFit: 'cover', borderRadius: 4 }}
                  />
                ))}
              </div>
            )}
          </Box>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Добавить'}
            </Button>
          </Box>
          {loading && <LinearProgress />}
          {error && <Typography color="error" mt={2}>{error}</Typography>}
        </form>
      </Box>
    </Container>
  );
};

export default AddItem;