import React, { useContext, useState, useEffect } from 'react';
import './Control.css';
import { Link } from 'react-router-dom';
import Cart from '../../Card/Cart/Cart';
import { WishItemsContext } from '../../../Context/WishItemsContext';
import ItemCard from '../../Card/ItemCard/ItemCard';
import SearchPopup from '../Controls/SearchPopupDesc'; // You need to create this component
import './SearchPopup.css';

const Control = (props) => {
    const wishItems = useContext(WishItemsContext);
    const [isSearchPopupOpen, setSearchPopupOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    const toggleSearchPopup = () => {
        setSearchPopupOpen(!isSearchPopupOpen);
        if (!isSearchPopupOpen) {
            setSearchTerm(''); // Сбрасываем поисковый запрос при открытии попапа
        }
    };

    // Фильтрация товаров на основе поискового запроса
    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredItems(props.items || []); // Показываем все товары, если поисковый запрос пуст
        } else {
            const filtered = (props.items || []).filter(item =>
                item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        }
    }, [searchTerm, props.items]);

    const itemsToShow = props.items ? props.items.slice(0, 10) : []; // Показываем только первые 10 товаров

    return (
        <div className="control__bar__container">
            <div className="controls__container">
                <div className="control">
                    <button onClick={toggleSearchPopup} className="search-button">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_522_21351)">
                                <path d="M23.7814 23.2191L16.5181 15.8847C17.8719 14.2575 18.6878 12.1639 18.6878 9.87796C18.6878 4.6987 14.5043 0.5 9.34389 0.5C4.18345 0.5 0 4.6987 0 9.87796C0 15.0572 4.18345 19.2559 9.34389 19.2559C11.6885 19.2559 13.8301 18.3871 15.4709 16.9549L22.724 24.2803C23.0159 24.5732 23.4895 24.5732 23.7814 24.2803C24.0729 23.9873 24.0729 23.5124 23.7814 23.2191H23.7814ZM9.34389 17.7678C5.00235 17.7678 1.48269 14.2353 1.48269 9.87796C1.48269 5.52058 5.00235 1.98809 9.34389 1.98809C13.6854 1.98809 17.2051 5.52058 17.2051 9.87796C17.2051 14.2353 13.6854 17.7678 9.34389 17.7678Z" fill="#1B1B1B"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_522_21351">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
                <div className="control">
                    <Link to="/wishlist" className={`wishlist-button ${wishItems.items && wishItems.items.length > 0 ? 'active' : ''}`}>
                            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4545 0.5C15.1636 0.5 13.1716 1.55768 12 3.33016C10.8284 1.55768 8.83636 0.5 6.54545 0.5C4.81011 0.502069 3.14642 1.20876 1.91935 2.46504C0.692279 3.72131 0.00202103 5.4246 0 7.20125C0 10.4625 1.98545 13.8567 5.90182 17.2877C7.69643 18.8533 9.63493 20.2373 11.6902 21.4202C11.7854 21.4726 11.8919 21.5 12 21.5C12.1081 21.5 12.2146 21.4726 12.3098 21.4202C14.3651 20.2373 16.3036 18.8533 18.0982 17.2877C22.0145 13.8567 24 10.4625 24 7.20125C23.998 5.4246 23.3077 3.72131 22.0806 2.46504C20.8536 1.20876 19.1899 0.502069 17.4545 0.5ZM12 20.0576C10.2098 18.9999 1.30909 13.4245 1.30909 7.20125C1.31053 5.77988 1.86269 4.41714 2.84438 3.41208C3.82608 2.40702 5.15713 1.84173 6.54545 1.84025C8.75782 1.84025 10.6156 3.04982 11.3945 4.99765C11.4439 5.12056 11.5277 5.22569 11.6356 5.29967C11.7434 5.37365 11.8702 5.41315 12 5.41315C12.1298 5.41315 12.2566 5.37365 12.3644 5.29967C12.4723 5.22569 12.5561 5.12056 12.6055 4.99765C13.3844 3.04982 15.2422 1.84025 17.4545 1.84025C18.8429 1.84173 20.1739 2.40702 21.1556 3.41208C22.1373 4.41714 22.6895 5.77988 22.6909 7.20125C22.6909 13.4245 13.7902 18.9999 12 20.0576Z" fill="#1B1B1B"/>
                            </svg>
                    </Link>
                </div>
                <div className="control">

                    <Cart />
                </div>
            </div>
            {isSearchPopupOpen && (
                <SearchPopup toggleSearchPopup={toggleSearchPopup} isActive={isSearchPopupOpen} />
            )}
        </div>
    );
}

export default Control;