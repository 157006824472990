// ItemList.js
import React from "react";
import { Link } from "react-router-dom";
import './ItemList.css'; // Import CSS for styling

const items = [
    { name: "Новинки", link: "/category/new" },
    { name: "Бестселлеры", link: "/category/bestsellers" },
    { name: "Серьги", link: "/category/sergi" },
    { name: "Браслеты", link: "/category/braslette" },
];

const ItemList = () => {
    return (
        <div className="item-list">
            {items.map((item, index) => (
                <div className="item" key={index}>
                    <Link to={item.link} className="item-link">
                        <span className="item-name">{item.name}</span>
                        <span className="arrow">
                            <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 14C3.26293e-07 6.53529 6.03529 0.5 13.5 0.5C20.9647 0.5 27 6.53529 27 14C27 21.4647 20.9647 27.5 13.5 27.5C6.03529 27.5 -3.26293e-07 21.4647 0 14ZM25.4118 14C25.4118 7.40882 20.0912 2.08824 13.5 2.08824C6.90882 2.08824 1.58824 7.40882 1.58824 14C1.58824 20.5912 6.90882 25.9118 13.5 25.9118C20.0912 25.9118 25.4118 20.5912 25.4118 14Z" fill="#BBBBBB"/>
                                <path d="M12.15 20.5912L18.7412 14L12.15 7.40882L13.2618 6.29706L20.9647 14L13.2618 21.7029L12.15 20.5912Z" fill="#BBBBBB"/>
                                <path d="M19.8529 13.2059V14.7941L6.35294 14.7941V13.2059L19.8529 13.2059Z" fill="#BBBBBB"/>
                            </svg>
                        </span>
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default ItemList;
