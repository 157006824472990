import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Box, Typography, CircularProgress, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper, 
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Alert
} from '@mui/material';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [itemsDetails, setItemsDetails] = useState({});
  const [retryCount, setRetryCount] = useState(0);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  
  const API_BASE_URL = 'https://carabine.shop/api';

  // Функции для перевода вариантов
  const translateOption = (option, type) => {
    const deliveryOptions = {
      pickup: 'Самовывоз',
      deliveryPoint: 'СДЭК - до пункта выдачи',
      delivery: 'СДЭК - до квартиры',
      pickupKurl: 'Курьером',
    };

    const paymentOptions = {
      onlinePayment: 'Онлайн-оплата',
      deliveryPayment: 'Оплата при получении',
    };

    return type === 'delivery' 
      ? deliveryOptions[option] || option
      : type === 'payment'
      ? paymentOptions[option] || option
      : option;
  };

  const translatePaymentStatus = (status) => {
    const statusOptions = {
      pending: 'Ожидает оплаты',
      deliveryPayment: 'Оплата при получении',
      success: 'Оплачено',
      failed: 'Ошибка оплаты',
      refunded: 'Возврат',
      canceled: 'Отменен'
    };

    return statusOptions[status] || status;
  };
  
  const deleteOrder = async (orderId) => {
    try {
      setLoading(true);
      const response = await axios.delete(`${API_BASE_URL}/orders/${orderId}`);
      if (response.data.success) {
        setOrders(orders.filter(order => order.id !== orderId));
        setNotification({
          type: 'success',
          message: 'Заказ успешно удален'
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Ошибка при удалении заказа:', error);
      setNotification({
        type: 'error',
        message: error.response?.data?.error || 'Ошибка при удалении заказа'
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Функция для парсинга изображений из строки JSON
  const parseItemImages = (imageString) => {
    try {
      if (!imageString) return [];
      const parsed = JSON.parse(imageString);
      return Array.isArray(parsed) ? parsed : [];
    } catch (e) {
      console.error('Ошибка парсинга изображений:', e);
      return [];
    }
  };

  // Загрузка данных
  useEffect(() => {
    document.title = 'Заказы - Админ-панель';
    
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await axios.get(`${API_BASE_URL}/orders`);
        console.log('Полный ответ API:', response.data);

        // Проверяем структуру ответа
        if (!response.data?.success || !response.data.data?.orders) {
          throw new Error('Неверный формат ответа сервера');
        }

        const ordersData = Array.isArray(response.data.data.orders) 
          ? response.data.data.orders 
          : [];

        console.log('Полученные заказы:', ordersData);
        console.log('Статусы заказов:', ordersData.map(o => o.payment_status));

        const allProductIds = ordersData.reduce((ids, order) => {
          if (Array.isArray(order.items)) {
            order.items.forEach(item => {
              if (item.product_id && !ids.includes(item.product_id)) {
                ids.push(item.product_id);
              }
            });
          }
          return ids;
        }, []);

        setItemsLoading(true);
        let itemsData = {};
        
        if (allProductIds.length > 0) {
          try {
            const itemsResponse = await axios.post(
              `${API_BASE_URL}/items/details`, 
              { ids: allProductIds }
            );

            if (Array.isArray(itemsResponse.data)) {
              itemsData = itemsResponse.data.reduce((acc, item) => {
                if (item?._id) {
                  acc[item._id] = {
                    name: item.name || `Товар ID: ${item._id}`,
                    description: item.description || 'Артикул не указан',
                    image: parseItemImages(item.image),
                    category: item.category || ''
                  };
                }
                return acc;
              }, {});
            }
          } catch (itemsError) {
            console.error('Ошибка загрузки деталей товаров:', itemsError);
          } finally {
            setItemsLoading(false);
          }
        }

        setItemsDetails(itemsData);
        setOrders(ordersData);
        setLoading(false);
      } catch (err) {
        console.error('Ошибка загрузки заказов:', err);
        setError(err.message || 'Ошибка при загрузке данных');
        setLoading(false);
      }
    };

    fetchData();
  }, [retryCount]);

  // Проверка статуса заказа
  const isPaidOrder = (order) => {
    if (!order || !order.payment_status) return false;
    const status = order.payment_status.toLowerCase();
    return status === 'success' || status === 'оплачено' || status === 'deliverypayment' || status === 'Оплата при получении' ;
  };

  const handleOpen = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  const getItemDetails = (productId) => {
    const defaultDetails = {
      name: `Товар ID: ${productId}`,
      description: 'Артикул не указан',
      image: [],
      category: ''
    };

    const details = itemsDetails[productId] || defaultDetails;
  
    // Обрабатываем изображения
    const processedImages = details.image.map(img => ({
      ...img,
      url: img.filename 
        ? `${API_BASE_URL}/public/uploads/${img.filename}`
        : null
    }));

    return {
      ...details,
      image: processedImages
    };
  };

  const renderItemPreview = (item) => {
    const details = getItemDetails(item.product_id);
    const firstImage = details.image[0];

    return (
      <Box key={`${item.product_id}-${item.quantity}`} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        {firstImage?.url ? (
          <img
            src={firstImage.url}
            alt={details.name}
            style={{ 
              width: '40px', 
              height: '40px', 
              objectFit: 'cover',
              borderRadius: '4px'
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/40';
            }}
          />
        ) : (
          <Box 
            sx={{
              width: '40px',
              height: '40px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px'
            }}
          >
            <Typography variant="caption">Нет фото</Typography>
          </Box>
        )}
        <Box>
          <Typography variant="body2">{details.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            Арт: {details.description} × {item.quantity} шт.
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderItemRow = (item) => {
    const details = getItemDetails(item.product_id);
    const firstImage = details.image[0];

    return (
      <TableRow key={`${item.product_id}-${item.quantity}`}>
        <TableCell>
          {firstImage?.url ? (
            <img
              src={firstImage.url}
              alt={details.name}
              style={{ 
                width: '60px', 
                height: '60px', 
                objectFit: 'cover',
                borderRadius: '4px'
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/60';
              }}
            />
          ) : (
            <Box 
              sx={{
                width: '60px',
                height: '60px',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px'
              }}
            >
              <Typography variant="caption">Нет фото</Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Box>
            <Typography fontWeight="bold">{details.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              Артикул: {details.description}
            </Typography>
            {details.category && (
              <Typography variant="caption" color="textSecondary">
                Категория: {details.category}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell align="center">{item.quantity}</TableCell>
        <TableCell align="right">{item.price} ₽</TableCell>
        <TableCell align="right">{item.price * item.quantity} ₽</TableCell>
      </TableRow>
    );
  };

  // Получаем оплаченные заказы
  const paidOrders = orders.filter(isPaidOrder);

  return (
    <Box my={4}>
      <Typography variant="h4" component="h2" gutterBottom>
        Все заказы
      </Typography>

      {notification && (
        <Alert 
          severity={notification.type} 
          onClose={() => setNotification(null)}
          sx={{ mb: 2 }}
        >
          {notification.message}
        </Alert>
      )}

      {error && (
        <Alert 
          severity="error" 
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={() => setRetryCount(c => c + 1)}
            >
              Повторить
            </Button>
          }
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : paidOrders.length === 0 ? (
        <Typography variant="body1">Нет оплаченных заказов</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>Фамилия</TableCell>
                <TableCell>Телефон</TableCell>
                <TableCell>Товары</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Доставка</TableCell>
                <TableCell>Статус оплаты</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paidOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.first_name || '-'}</TableCell>
                  <TableCell>{order.last_name || '-'}</TableCell>
                  <TableCell>{order.phone || '-'}</TableCell>
                  <TableCell sx={{ maxWidth: 300 }}>
                    {order.items?.map(renderItemPreview) || 'Нет товаров'}
                  </TableCell>
                  <TableCell>{order.total_amount} ₽</TableCell>
                  <TableCell>
                    {translateOption(order.delivery_option, 'delivery')}
                  </TableCell>
                  <TableCell>
                    <Box 
                      sx={{
                        color: isPaidOrder(order) ? 'green' : 'red',
                        fontWeight: 'bold'
                      }}
                    >
                      {translatePaymentStatus(order.payment_status)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleOpen(order)}
                      sx={{ mr: 1, mb: 1 }}
                    >
                      Подробнее
                    </Button>
                    <Button 
                      variant="contained" 
                      color="error"
                      onClick={async () => {
                        if (window.confirm('Вы уверены, что хотите удалить этот заказ?')) {
                          await deleteOrder(order.id);
                        }
                      }}
                    >
                      Удалить
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Детали заказа #{selectedOrder?.id}
        </DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Информация о покупателе:
              </Typography>
              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableBody>
                    {[
                      { label: 'Имя', value: selectedOrder.first_name },
                      { label: 'Фамилия', value: selectedOrder.last_name },
                      { label: 'Телефон', value: selectedOrder.phone },
                      { label: 'Email', value: selectedOrder.email },
                      { label: 'Адрес', value: selectedOrder.address },
                      { 
                        label: 'Способ доставки', 
                        value: translateOption(selectedOrder.delivery_option, 'delivery') 
                      },
                      { 
                        label: 'Способ оплаты', 
                        value: translateOption(selectedOrder.payment_method, 'payment') 
                      },
                      { 
                        label: 'Статус оплаты', 
                        value: (
                          <Box 
                            component="span" 
                            sx={{
                              color: isPaidOrder(selectedOrder) ? 'green' : 'red',
                              fontWeight: 'bold'
                            }}
                          >
                            {translatePaymentStatus(selectedOrder.payment_status)}
                          </Box>
                        ) 
                      },
                      { label: 'Сумма заказа', value: `${selectedOrder.total_amount} ₽` },
                      { 
                        label: 'Дата заказа', 
                        value: new Date(selectedOrder.created_at).toLocaleString() 
                      }
                    ].map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {row.label}
                        </TableCell>
                        <TableCell>{row.value || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" gutterBottom>
                Состав заказа:
              </Typography>
              {itemsLoading ? (
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '80px' }}>Изображение</TableCell>
                        <TableCell>Товар</TableCell>
                        <TableCell align="center" sx={{ width: '100px' }}>Кол-во</TableCell>
                        <TableCell align="right" sx={{ width: '120px' }}>Цена</TableCell>
                        <TableCell align="right" sx={{ width: '120px' }}>Сумма</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedOrder.items?.map(renderItemRow) || (
                        <TableRow>
                          <TableCell colSpan={5} align="center">Нет товаров</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
          <Button 
            onClick={async () => {
              if (window.confirm('Вы уверены, что хотите удалить этот заказ?')) {
                const success = await deleteOrder(selectedOrder.id);
                if (success) {
                  handleClose();
                }
              }
            }} 
            color="error"
          >
            Удалить заказ
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Orders;