import React, { useContext, useState, useEffect } from 'react';
import './Detail.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { CartItemsContext } from '../../../Context/CartItemsContext';
import { WishItemsContext } from '../../../Context/WishItemsContext';
import Description from '../Description/Description';

const Detail = (props) => {
    const [quantity, setQuantity] = useState(1);
    const [size, setSize] = useState(props.item.size[0]);
    const [isWished, setIsWished] = useState(false);
    const [isAddedToCart, setIsAddedToCart] = useState(false);

    const cartItems = useContext(CartItemsContext);
    const wishItems = useContext(WishItemsContext);

    useEffect(() => {
        const isItemWished = wishItems.items.some(item => item._id === props.item._id);
        setIsWished(isItemWished);
    }, [wishItems.items, props.item._id]);

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleQuantityIncrement = (event) => {
        setQuantity((prev) => prev + 1);
    };

    const handleQuantityDecrement = (event) => {
        if (quantity > 1) {
            setQuantity((prev) => prev - 1);
        }
    };

    const handleAddToCart = () => {
        cartItems.addItem(props.item, quantity, size);
        setIsAddedToCart(true);
        
        // Возвращаем кнопку в исходное состояние через 2 секунды
        setTimeout(() => {
            setIsAddedToCart(false);
        }, 2000);
    };

    const handleAddToWish = () => {
        if (isWished) {
            wishItems.removeItem(props.item._id);
        } else {
            wishItems.addItem(props.item);
        }
        setIsWished(!isWished);
    };

    const shouldDisplay = (value) => {
        return value && value !== '-' && value !== 'Нет данных';
    };

    return (
        <div className="product__detail__container">
            <div className="product__detail">
                <div className="product__main__detail">
                    {shouldDisplay(props.item.name) && <div className="product__name__main">{props.item.name}</div>}
                    {shouldDisplay(props.item.description) && <div className="product__detail__description">артикул: {props.item.description}</div>}
                    {shouldDisplay(props.item.price) && <div className="product__price__detail">{props.item.price} ₽</div>}
                </div>
                <form onSubmit={handleAddToCart} className="product__form">
                    <div className="collect__item__actions">
                        <div className="add__cart__add__wish">
                            <div className="add__cart">
                                <Button 
                                    variant="outlined" 
                                    size="large" 
                                    onClick={handleAddToCart}
                                    sx={{
                                        backgroundColor: isAddedToCart ? '#10059F' : 'transparent',
                                        color: isAddedToCart ? 'white' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: isAddedToCart ? '#10059F' : 'transparent',
                                        }
                                    }}
                                >
                                    {isAddedToCart ? 'В корзине' : 'Добавить в корзину'}
                                </Button>
                            </div>
                            <div className="add__wish">
                                <IconButton variant="outlined" size="large" onClick={handleAddToWish}>
                                    {isWished ? (
                                        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6364 0C17.0591 0 14.8181 1.20878 13.5 3.23447C12.1819 1.20878 9.94091 0 7.36364 0C5.41138 0.00236473 3.53973 0.810007 2.15927 2.24575C0.778813 3.6815 0.00227366 5.62812 0 7.65857C0 11.3857 2.23364 15.2648 6.63955 19.186C8.65848 20.9752 10.8393 22.5569 13.1515 23.9088C13.2586 23.9687 13.3784 24 13.5 24C13.6216 24 13.7414 23.9687 13.8485 23.9088C16.1607 22.5569 18.3415 20.9752 20.3605 19.186C24.7664 15.2648 27 11.3857 27 7.65857C26.9977 5.62812 26.2212 3.6815 24.8407 2.24575C23.4603 0.810007 21.5886 0.00236473 19.6364 0Z" fill="#10059F"/>
                                        </svg>
                                    ) : (
                                        <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6364 0C17.0591 0 14.8181 1.20878 13.5 3.23447C12.1819 1.20878 9.94091 0 7.36364 0C5.41138 0.00236473 3.53973 0.810007 2.15927 2.24575C0.778813 3.6815 0.00227366 5.62812 0 7.65857C0 11.3857 2.23364 15.2648 6.63955 19.186C8.65848 20.9752 10.8393 22.5569 13.1515 23.9088C13.2586 23.9687 13.3784 24 13.5 24C13.6216 24 13.7414 23.9687 13.8485 23.9088C16.1607 22.5569 18.3415 20.9752 20.3605 19.186C24.7664 15.2648 27 11.3857 27 7.65857C26.9977 5.62812 26.2212 3.6815 24.8407 2.24575C23.4603 0.810007 21.5886 0.00236473 19.6364 0ZM13.5 22.3515C11.486 21.1428 1.47273 14.7708 1.47273 7.65857C1.47435 6.03414 2.09552 4.47674 3.19993 3.32809C4.30434 2.17945 5.80177 1.5334 7.36364 1.53171C9.85255 1.53171 11.9426 2.91408 12.8189 5.14017C12.8743 5.28064 12.9687 5.40078 13.09 5.48534C13.2113 5.56989 13.354 5.61503 13.5 5.61503C13.646 5.61503 13.7887 5.56989 13.91 5.48534C14.0313 5.40078 14.1257 5.28064 14.1811 5.14017C15.0574 2.91408 17.1475 1.53171 19.6364 1.53171C21.1982 1.5334 22.6957 2.17945 23.8001 3.32809C24.9045 4.47674 25.5256 6.03414 25.5273 7.65857C25.5273 14.7708 15.514 21.1428 13.5 22.3515Z" fill="#1B1B1B"/>
                                        </svg>
                                    )}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </form>
                <Description item={props.item} />
            </div>
        </div>
    );
};

export default Detail;