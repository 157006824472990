import React, { useContext } from 'react';
import { CartItemsContext } from '../../../../Context/CartItemsContext';
import Button from '@mui/material/Button';
import './CartCard.css';

const CartCard = (props) => {
    const cartItems = useContext(CartItemsContext);
    const item = props.item; // Конкретный товар из props
    const description = props.description; // Конкретный товар из props
    const type = props.type; // Конкретный товар из props

    const handleQuantityIncrement = () => {
        cartItems.quantity(item._id, 'INC'); // Увеличиваем количество только для текущего товара
    };

    const handleQuantityDecrement = () => {
        if (item.quantity > 1) {
            cartItems.quantity(item._id, 'DEC'); // Уменьшаем количество только для текущего товара
        }
    };

    const handleRemoveItem = () => {
        cartItems.removeItem(item._id); // Передаем только ID товара вместо всего объекта
    };

    return (
        <div className='cart__item__card'>
            <div className="remove__item__icon">
                <Button variant="outlined" color="error" onClick={handleRemoveItem} className="remove__item__button">
                    <span className="remove__item__text">Удалить</span>
                    <svg className="remove__item__svg" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M16.0919 15.3642C12.573 18.8831 6.88286 18.8831 3.36396 15.3642C-0.154935 11.8453 -0.154935 6.15518 3.36396 2.63628C6.88286 -0.882612 12.573 -0.882613 16.0919 2.63628C19.6108 6.15518 19.6108 11.8453 16.0919 15.3642ZM4.11266 3.38498C1.00555 6.4921 1.00555 11.5084 4.11266 14.6155C7.21977 17.7226 12.2361 17.7226 15.3432 14.6155C18.4503 11.5084 18.4503 6.49209 15.3432 3.38498C12.2361 0.277873 7.21977 0.277874 4.11266 3.38498Z" fill="#BBBBBB"/>
                       <path d="M5.98442 11.995L12.7227 5.25674L13.4714 6.00544L6.73312 12.7438L5.98442 11.995Z" fill="#BBBBBB"/>
                       <path d="M5.98442 6.00544L6.73312 5.25674L13.4714 11.995L12.7227 12.7437L5.98442 6.00544Z" fill="#BBBBBB"/>
                    </svg>
                </Button>
				
            </div>
            <div className="cart__item__detail">
                <div className="cart__item__image">
                    {/* Отображаем первое изображение из массива */}
                    <img 
                        src={`https://carabine.shop/api/public/uploads/${item.image[0].filename}`} 
                        alt="item" 
                        className="item__image" 
                    />
                </div>
            </div>
            <div className="card_to">
                <div className="cart__item__name">{item.name}</div>
                <div className="cart__item__info">
                    <div className="cart__item__size">
                        {/* Отображаем артикул, если он есть */}
                        <div className="name-size">артикул: {item.description || "Не указан"}</div>
                    </div>
                    <div className="cart__item__composition">
                        {/* Отображаем состав, если он есть */}
                        <div className="name-composition">состав: {item.type || "Не указан"}</div>
                    </div>
                    <div className="cart__item__size">
                        {/* Отображаем размер, если он есть */}
                        <div className="name-size">размер: {item.size || "Не указан"}</div>
                    </div>
                    <div className="cart__item__quantity">
                        <div className="name-quantity">количество</div>
                        <svg 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg" 
                            onClick={handleQuantityIncrement} 
                            style={{ cursor: 'pointer' }}
                        >
                            <path d="M8 16C3.57647 16 0 12.4235 0 8C0 3.57647 3.57647 0 8 0C12.4235 0 16 3.57647 16 8C16 12.4235 12.4235 16 8 16ZM8 0.941176C4.09412 0.941176 0.941176 4.09412 0.941176 8C0.941176 11.9059 4.09412 15.0588 8 15.0588C11.9059 15.0588 15.0588 11.9059 15.0588 8C15.0588 4.09412 11.9059 0.941176 8 0.941176Z" fill="#BBBBBB"/>
                            <path d="M3.76471 7.52941H12.2353V8.47059H3.76471V7.52941Z" fill="#BBBBBB"/>
                            <path d="M7.52941 3.76471H8.47059V12.2353H7.52941V3.76471Z" fill="#BBBBBB"/>
                        </svg>
                        <div className="quantity__input">{item.quantity}</div>
                        <svg 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg" 
                            onClick={handleQuantityDecrement} 
                            style={{ cursor: 'pointer' }}
                        >
                            <path d="M8 16C3.57647 16 0 12.4235 0 8C0 3.57647 3.57647 0 8 0C12.4235 0 16 3.57647 16 8C16 12.4235 12.4235 16 8 16ZM8 0.941176C4.09412 0.941176 0.941176 4.09412 0.941176 8C0.941176 11.9059 4.09412 15.0588 8 15.0588C11.9059 15.0588 15.0588 11.9059 15.0588 8C15.0588 4.09412 11.9059 0.941176 8 0.941176Z" fill="#BBBBBB"/>
                            <path d="M3.76471 7.52941H12.2353V8.47059H3.76471V7.52941Z" fill="#BBBBBB"/>
                        </svg>
                    </div>
                </div>
                <div className="cart__item__price">{item.price} ₽</div>
            </div>
        </div>
    );
};

export default CartCard;