import React from 'react';
import { useLocation } from 'react-router-dom';
import ItemCarousel from './Carousel/ItemCarousel';
import Detail from './Detail/Detail';
import './Item.css';
import Related from './Related/Related';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Item = (props) => {
    const location = useLocation(); // �������� ������� URL
    const pathParts = location.pathname.split('/'); // ��������� ���� �� ������

    // ��������� ��������� �� URL, ���� ��� ����
    let categories = [];
    if (pathParts[2]) { // ���������, ��� ������ ����� ���� ����������
        categories = pathParts[2].split(',').map(cat => cat.trim()); // ��������� �� ������� � ������� �������
    }

    // ������� ������ breadcrumbItems
    const breadcrumbItems = [
        { name: 'carabine', path: '/' }, // ������ ������� ������ "�������"
        ...categories.map(category => ({ name: category, path: `/category/${category}` })), // ��������� ������
        { name: props.item.name, path: location.pathname } // �������� ������
    ];

    // ����������� breadcrumbs ��� ����������� ����� "�"
    const formattedBreadcrumbs = breadcrumbItems
        .map(item => item.name) // ��������� ������ �����
        .join(' � '); // ���������� ����� "�"

    return (
        <div className="item__container">
            {/* ������� breadcrumbs */}
            <Breadcrumbs items={breadcrumbItems} formattedBreadcrumbs={formattedBreadcrumbs} />
            <div className="detail__and__carousel__container">
                <ItemCarousel item={props.item} />
                <Detail 
                    item={props.item} 
                    category={props.item.category} // �������� ������� ���������
                    breadcrumbItems={formattedBreadcrumbs} 
                />
            </div>
            <div className="related__items__container">
                <Related category={props.item.category} />
            </div>
        </div>
    );
};

export default Item;