import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from '../routes/Home';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ManageAccount from '../components/Account/ManageAccount/ManageAccount';
import MyAccount from '../components/Account/MyAccount/MyAccount';
import Shop from '../components/Shop/Shop';
import Cat from '../components/Cat/Cat';
import ItemView from '../routes/ItemView';
import CategoryView from '../routes/CategoryView';
import SearchView from '../routes/Search';
import CartItemsProvider from '../Context/CartItemsProvider';
import Login from '../components/Authentication/Login/Login';
import Register from '../components/Authentication/Register/Register';
import Wishlist from '../components/Wishlist';
import WishItemsProvider from '../Context/WishItemsProvider';
import SearchProvider from '../Context/SearchProvider';
import NotFound from '../components/NotFound/NotFound';
import AdminLogin from '../components/Admin/AdminLogin';
import AdminLayout from '../components/Admin/AdminLayout';
import AllItems from '../components/Admin/AllItems';
import AddItem from '../components/Admin/AddItem';
import EditItem from '../components/Admin/EditItem';
import Orders from '../components/Admin/Orders';
import Promocode from '../components/Admin/Promocode';
import BannerUpload from '../components/Admin/BannerUpload';
import CategoryUpload from '../components/Admin/CategoryUpload';
import AboutUs from '../components/AboutUs/AboutUs';
import Offer from '../components/Offer/Offer';
import Guaranties from '../components/Guaranties/Guaranties';
import DeliveryAndPayment from '../components/DeliveryAndPayment/DeliveryAndPayment';
import ReturnsAndExchanges from '../components/ReturnsAndExchanges/ReturnsAndExchanges';
import Stores from '../components/Stores/Stores';
import FAQs from '../components/FAQs/FAQs';
import Thanks from '../components/Thanks/Thanks';
import Thankspay from '../components/Thankspay/Thankspay';
import Checkout from '../components/Checkout/Checkout';
import ServiceComponent from '../components/ServiceComponent/ServiceComponent';
import Admin from '../components/Admin/Admin';

function App() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAppReady, setIsAppReady] = useState(false);

    // Инициализация приложения
    useEffect(() => {
        // Загрузка состояния админа
        const adminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(adminStatus);

        // Задержка для полной инициализации
        const initTimer = setTimeout(() => {
            setIsAppReady(true);
        }, 1);

        return () => {
            clearTimeout(initTimer);
        };
    }, []);

    const handleAdminLogin = (status) => {
        setIsAdmin(status);
        localStorage.setItem('isAdmin', status.toString());
    };

    return (
        <CartItemsProvider>
            <WishItemsProvider>
                <SearchProvider>
                    <Router>
                        <Header />
                        <div className="main-container">
                            <div className="content">
                                {isAppReady ? (
                                    <Routes>
                                        <Route index element={<Home />} />
                                        <Route path="/account">
                                            <Route path="me" element={<MyAccount />} />
                                            <Route path="manage" element={<ManageAccount />} />
                                            <Route path="login" element={<Login />} />
                                            <Route path="register" element={<Register />} />
                                            <Route path="*" element={<Login />} />
                                        </Route>
                                        <Route path="/all" element={<Shop />} />
                                        <Route path="/cat" element={<Cat />} />
                                        <Route path="/category/:id" element={<CategoryView />} />
                                        <Route path="/item/:gender/:id" element={<ItemView />} />
                                        <Route path="/wishlist" element={<Wishlist />} />
                                        <Route path="/search/*" element={<SearchView />} />
                                        <Route path="/checkout" element={<Checkout />} />
                                        <Route path="/thanks" element={<Thanks />} />
                                        <Route path="/thankspay" element={<Thankspay />} />
                                        <Route
                                            path="/admin/*"
                                            element={isAdmin ? <AdminLayout isAdmin={isAdmin} /> : <Navigate to="/admin/login" />}
                                        >
                                            <Route path="all-items" element={<AllItems />} />
                                            <Route path="add-item" element={<AddItem />} />
                                            <Route path="edit-item/:id" element={<EditItem />} />
                                            <Route path="orders" element={<Orders />} />
                                            <Route path="banner-upload" element={<BannerUpload />} />
                                            <Route path="category-upload" element={<CategoryUpload />} />
                                            <Route path="promocode" element={<Promocode />} />
                                        </Route>
                                        <Route
                                            path="/admin/login"
                                            element={<AdminLogin onLogin={handleAdminLogin} />}
                                        />
                                        <Route path="/admin/*" element={<Admin isAdmin={isAdmin} />} />
                                        <Route path="/o-nas" element={<AboutUs />} />
                                        <Route path="/dogovor-publichnoj-oftery" element={<Offer />} />
                                        <Route path="/garantii" element={<Guaranties />} />
                                        <Route path="/dostavka-i-oplata" element={<DeliveryAndPayment />} />
                                        <Route path="/vozvrat-i-obmen" element={<ReturnsAndExchanges />} />
                                        <Route path="/magaziny" element={<Stores />} />
                                        <Route path="/chasto-zadavaemye-voprosy" element={<FAQs />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                ) : (
                                   <div className="loading-spinner-container">
                                        <div className="loading-spinner"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Footer />
                    </Router>
                </SearchProvider>
            </WishItemsProvider>
        </CartItemsProvider>
    );
}

export default App;