import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Thankspay.css'; // Подключите стили, если необходимо

const Thankspay = () => {
    const navigate = useNavigate();

    const handleReturn = () => {
        navigate('/');
    };

    return (
        <div className="thanks-container">
            <h2>благодарим за ваш заказ!</h2>
            <p>обработка и изготовление заказа занимает от 1 до 4 дней</p>
            <p>мы свяжемся с вами, когда заказ будет передан на доставку</p>
            <button className="closepopups" onClick={() => {
                window.location.href = '/'; // Перенаправление на главную страницу с перезагрузкой
            }}>Продолжить покупки</button>
        </div>
    );
};

export default Thankspay;