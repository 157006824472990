import React, { useState, useEffect } from "react";
import { CartItemsContext } from "./CartItemsContext";

const CartItemsProvider = ({ children }) => {
    const [isHydrated, setIsHydrated] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    // Инициализация из localStorage
    useEffect(() => {
        const loadCart = () => {
            try {
                if (typeof window !== 'undefined') {
                    // Загружаем только товары
                    const savedCart = localStorage.getItem('cart');
                    
                    if (savedCart) {
                        const parsedCart = JSON.parse(savedCart);
                        if (Array.isArray(parsedCart)) {
                            setCartItems(parsedCart);
                            calculateTotal(parsedCart);
                        }
                    }
                }
            } catch (error) {
                console.error("Failed to load cart:", error);
            } finally {
                setIsHydrated(true);
                setInitialLoadComplete(true);
            }
        };

        const timer = setTimeout(loadCart, 10);
        return () => clearTimeout(timer);
    }, []);

    // Блокировка автоматического открытия корзины
    useEffect(() => {
        if (initialLoadComplete) {
            // Гарантированно закрываем корзину после загрузки
            setIsCartOpen(false);
            
            // Очищаем любое сохраненное состояние открытия
            if (typeof window !== 'undefined') {
                localStorage.removeItem('cartState');
            }
        }
    }, [initialLoadComplete]);

    // Сохранение в localStorage при изменениях
    useEffect(() => {
        if (!isHydrated) return;

        const saveCart = () => {
            try {
                if (typeof window !== 'undefined') {
                    localStorage.setItem('cart', JSON.stringify(cartItems));
                    // Сохраняем состояние только если корзина открыта
                    if (isCartOpen) {
                        localStorage.setItem('cartState', JSON.stringify({
                            isOpen: true
                        }));
                    } else {
                        localStorage.removeItem('cartState');
                    }
                }
            } catch (error) {
                console.error("Failed to save cart:", error);
            }
        };

        calculateTotal(cartItems);
        saveCart();
    }, [cartItems, isCartOpen, isHydrated]);

    const calculateTotal = (items) => {
        const total = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
        setTotalAmount(total);
    };

    const addItem = (item, quantity = 1) => {
        setCartItems(prevItems => {
            const existingIndex = prevItems.findIndex(i => i._id === item._id);
            
            if (existingIndex >= 0) {
                const updated = [...prevItems];
                updated[existingIndex].quantity += quantity;
                return updated;
            }
            
            return [...prevItems, { 
                ...item, 
                quantity,
                type: item.type || "-",
                description: item.description || "-"
            }];
        });
    };

    const removeItem = (itemId) => {
        setCartItems(prevItems => prevItems.filter(item => item._id !== itemId));
    };

    const updateQuantity = (itemId, action) => {
        setCartItems(prevItems =>
            prevItems.map(item => {
                if (item._id === itemId) {
                    const newQty = action === 'INC' 
                        ? item.quantity + 1 
                        : Math.max(item.quantity - 1, 0);
                    
                    return newQty > 0 
                        ? { ...item, quantity: newQty } 
                        : null;
                }
                return item;
            }).filter(Boolean)
        );
    };

    return (
        <CartItemsContext.Provider value={{
            items: cartItems,
            totalAmount,
            isCartOpen,
            setIsCartOpen: (value) => {
                // Блокируем любые попытки открыть корзину до завершения инициализации
                if (initialLoadComplete) {
                    setIsCartOpen(value);
                }
            },
            addItem,
            removeItem,
            quantity: updateQuantity,
            isHydrated
        }}>
            {isHydrated ? children : (
                <div className="loading-spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </CartItemsContext.Provider>
    );
};

export default CartItemsProvider;