import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CartItemsContext } from '../../../Context/CartItemsContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkout from '../../Checkout/Checkout';
import CartCard from './CartCard/CartCard';
import './Cart.css';

const style = {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '40%',
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '24px',
    transition: 'transform 0.3s',
    transform: 'translateX(100%)',
    overflowY: 'auto',
};

const Cart = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [lastCartLength, setLastCartLength] = useState(0); // Добавляем состояние для отслеживания количества товаров
    const navigate = useNavigate();
    const cartItems = useContext(CartItemsContext);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    // Модифицированный эффект для корзины
    useEffect(() => {
        // Открываем корзину только если количество товаров увеличилось
        if (!cartItems.isCartOpen && cartItems.items.length > lastCartLength) {
            cartItems.setIsCartOpen(true);
        }
        // Обновляем последнее известное количество товаров
        setLastCartLength(cartItems.items.length);
    }, [cartItems.items]);

    const handleClose = () => {
        cartItems.setIsCartOpen(false);
        setShowCheckout(false);
    };

    const handleCheckout = () => {
        if (cartItems.totalAmount > 0) {
            if (isMobile) {
                setShowCheckout(true);
            } else {
                navigate('/checkout');
                handleClose();
            }
        }
    };


    return (
        <Fragment>
            <div onClick={() => cartItems.setIsCartOpen(true)} style={{ cursor: 'pointer' }}>
                <Link to="#" className={`cart-button ${cartItems.items.length > 0 ? 'active' : ''}`}>
                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.30857 24.5C1.65048 24.5 1.10143 24.2947 0.661428 23.884C0.221428 23.4733 0.000952381 22.9609 0 22.3467V7.98667C0 7.37333 0.220476 6.86133 0.661428 6.45067C1.10238 6.04 1.65143 5.83422 2.30857 5.83333H5V5.16667C5 3.87067 5.48619 2.76889 6.45857 1.86133C7.43095 0.953778 8.61143 0.5 10 0.5C11.3886 0.5 12.569 0.953778 13.5414 1.86133C14.5138 2.76889 15 3.87067 15 5.16667V5.83333H17.6929C18.35 5.83333 18.8986 6.03911 19.3386 6.45067C19.7786 6.86222 19.999 7.37467 20 7.988V22.3467C20 22.96 19.7795 23.4724 19.3386 23.884C18.8976 24.2956 18.349 24.5009 17.6929 24.5H2.30857ZM2.30857 23.1667H17.6929C17.9119 23.1667 18.1133 23.0813 18.2971 22.9107C18.481 22.74 18.5724 22.5516 18.5714 22.3453V7.988C18.5714 7.78267 18.48 7.59422 18.2971 7.42267C18.1143 7.25111 17.9124 7.16578 17.6914 7.16667H15V10.5C15 10.6902 14.9319 10.8489 14.7957 10.976C14.6595 11.1031 14.4895 11.1667 14.2857 11.1667C14.0819 11.1667 13.9119 11.1031 13.7757 10.976C13.6395 10.8489 13.5714 10.6902 13.5714 10.5V7.16667H6.42857V10.5C6.42857 10.6902 6.36048 10.8489 6.22429 10.976C6.0881 11.1031 5.9181 11.1667 5.71429 11.1667C5.51048 11.1667 5.34048 11.1031 5.20429 10.976C5.06809 10.8489 5 10.6902 5 10.5V7.16667H2.30857C2.08857 7.16667 1.88667 7.252 1.70286 7.42267C1.51905 7.59333 1.42762 7.78178 1.42857 7.988V22.3467C1.42857 22.5511 1.52 22.7391 1.70286 22.9107C1.88571 23.0822 2.08714 23.1676 2.30714 23.1667M6.42857 5.83333H13.5714V5.16667C13.5714 4.228 13.2276 3.43778 12.54 2.796C11.8524 2.15422 11.0057 1.83333 10 1.83333C8.99429 1.83333 8.14762 2.15422 7.46 2.796C6.77238 3.43778 6.42857 4.228 6.42857 5.16667V5.83333Z" fill="#1B1B1B"/>
                    </svg>
                </Link>
            </div>
            <Modal
                open={cartItems.isCartOpen} // Используем состояние из контекста
                onClose={() => cartItems.setIsCartOpen(false)} // Закрываем через контекст
                closeAfterTransition
                BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }
                }}
            >
                <Box sx={{ ...style, transform: cartItems.isCartOpen ? 'translateX(0)' : 'translateX(100%)' }} className="cart-container">
                    <div className="cart__header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2>{!showCheckout ? 'Корзина' : ''}</h2>
                        <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.5907 9L18 16.4093L16.4093 18L9 10.5907L1.59075 18L0 16.4093L7.40925 9L0 1.59075L1.59075 0L9 7.40925L16.4093 0L18 1.59075L10.5907 9Z" fill="#1B1B1B"/>
                           </svg>
                        </div>
                    </div>
                    {!showCheckout ? (
                        <div className="cart__items__container">
                            <div className="cartItems">
                                {cartItems.items.length === 0 ? (
                                    <>
                                        <div className="cart__empty">Ваша корзина пуста!</div>
                                        <a className="return" href="/">Перейти в каталог</a>
                                    </>
                                ) : (
                                    <div className="shop__cart__items">
                                        {cartItems.items.map((item) => (
                                            <CartCard key={item._id} item={item} />
                                        ))}
                                    </div>
                                )}
                            </div>
                                {cartItems.items.length > 0 && (
                                    <div className="options">
                                        <div className="total__amount">
                                            <div className="total__amount__label">Итого</div>
                                            <div className="total__amount__value">{cartItems.totalAmount} ₽</div>
                                        </div>
                                        <div className="checkout">
                                            <Button variant="outlined" onClick={handleCheckout}>Перейти к оформлению</Button>
                                        </div>
                                    </div>
                                )}
                        </div>
                    ) : (
                        <Checkout />
                    )}
                </Box>
            </Modal>
            <style jsx>{`
                @media (max-width: 768px) {
                    .cart-container {
                        width: 100%;
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Cart;