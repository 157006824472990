import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Category from '../components/Category/Category';

const CategoryView = () => {
    const param = useParams()
    const [ basicItems, setBasicItems ] = useState()
    const [ crystalItems, setCrystalItems ] = useState()
    const [ pearlItems, setPearlItems ] = useState()
    const [ giftcardItems, setGiftcardItems ] = useState()
    const [ stikeryItems, setStikeryItems ] = useState()
    const [ bestsellersItems, setBestsellersItems ] = useState()
    const [ naturalstonesItems, setNaturalstonesItems ] = useState()
    const [ brasletteItems, setBrasletteItems ] = useState()
    const [ nasheyuItems, setNasheyuItems ] = useState()
    const [ newItems, setNewItems ] = useState()
    const [ sertifikatyItems, setSertifikatyItems ] = useState()
    const [ chokersItems, setChokersItems ] = useState()
    const [ necklaceItems, setNecklaceItems ] = useState()
    const [ pendantsItems, setPendantsItems ] = useState()
    const [ scarvesItems, setScarvesItems ] = useState()
    const [ ankletsItems, setAnkletsItems ] = useState()
    const [ packagingItems, setPackagingItems ] = useState()
    const [ sergiItems, setSergiItems ] = useState()
    const [ dreamdropItems, setDreamdropItems ] = useState()
    const [ babushkindropItems, setBabushkindropItems ] = useState()
    const [ loading , setLoading ] = useState(true) 
    const filterByCategory = (items, category) =>
    items.filter(item =>
        Array.isArray(item.categories)
            ? item.categories.map(cat => cat.trim().toLowerCase()).includes(category.toLowerCase())
            : item.category
                ? item.category.split(',').map(cat => cat.trim().toLowerCase()).includes(category.toLowerCase())
                : false
    );
    useEffect(() => {
        axios.get("https://carabine.shop/api/items")
            .then(res => {
                const allItems = res.data.map(item => ({
                    ...item,
                    categories: Array.isArray(item.categories)
                        ? item.categories.map(cat => cat.trim().toLowerCase())
                        : item.category
                            ? item.category.split(',').map(cat => cat.trim().toLowerCase())
                            : []
                }));
                console.log("All items with categories:", allItems); // Отладка
                setBasicItems(filterByCategory(allItems, "basic"));
                setCrystalItems(filterByCategory(allItems, "crystal"));
                setPearlItems(filterByCategory(allItems, "pearl"));
                setGiftcardItems(filterByCategory(allItems, "giftcard"));
                setStikeryItems(filterByCategory(allItems, "stikery"));
                setNaturalstonesItems(filterByCategory(allItems, "naturalstones"));
                setBestsellersItems(filterByCategory(allItems, "bestsellers"));
                setBrasletteItems(filterByCategory(allItems, "braslette"));
                setNasheyuItems(filterByCategory(allItems, "nasheyu"));
                setNewItems(filterByCategory(allItems, "new"));
                setSertifikatyItems(filterByCategory(allItems, "sertifikaty"));
                setSergiItems(filterByCategory(allItems, "sergi"));
                setChokersItems(filterByCategory(allItems, "chokers"));
                setNecklaceItems(filterByCategory(allItems, "necklace"));
                setPendantsItems(filterByCategory(allItems, "pendants"));
                setScarvesItems(filterByCategory(allItems, "scarves"));
                setAnkletsItems(filterByCategory(allItems, "anklets"));
                setPackagingItems(filterByCategory(allItems, "packaging"));
                setDreamdropItems(filterByCategory(allItems, "dreamdrop"));
                setBabushkindropItems(filterByCategory(allItems, "babushkindrop"));
                setLoading(false);
            })
            .catch(err => console.log(err));
        window.scrollTo(0, 0);
    }, [param.id]);
    
    return (
        <div className='d-flex min-vh-100 w-100 justify-content-center align-items-center m-auto'>
            {loading && (
                <ReactLoading type="bars" color="#eee" height={1} width={1} />
            )}
            {basicItems && param.id === 'basic' && <Category name="Basic" items={basicItems} category="basic" />}
            {crystalItems && param.id === 'crystal' && <Category name="Crystal" items={crystalItems} category="crystal" />}
            {pearlItems && param.id === 'pearl' && <Category name="Pearl" items={pearlItems} category="pearl" />}
            {giftcardItems && param.id === 'giftcard' && <Category name="Gift Card" items={giftcardItems} category="giftcard" />}
            {stikeryItems && param.id === 'stikery' && <Category name="Стикеры" items={stikeryItems} category="stikery" />}
            {bestsellersItems && param.id === 'bestsellers' && <Category name="Бестселлеры" items={bestsellersItems} category="bestsellers" />}
            {naturalstonesItems && param.id === 'naturalstones' && <Category name="Natural Stones" items={naturalstonesItems} category="naturalstones" />}
            {brasletteItems && param.id === 'braslette' && <Category name="Браслеты" items={brasletteItems} category="braslette" />}
            {nasheyuItems && param.id === 'nasheyu' && <Category name="На шею" items={nasheyuItems} category="nasheyu" />}
            {newItems && param.id === 'new' && <Category name="Новинки" items={newItems} category="new" />}
            {sertifikatyItems && param.id === 'sertifikaty' && <Category name="Сертификаты" items={sertifikatyItems} category="sertifikaty" />}
            {sergiItems && param.id === 'sergi' && <Category name="Серьги" items={sergiItems} category="sergi" />}
            {chokersItems && param.id === 'chokers' && <Category name="Чокеры" items={chokersItems} category="chokers" />}
            {necklaceItems && param.id === 'necklace' && <Category name="Колье" items={necklaceItems} category="necklace" />}
            {pendantsItems && param.id === 'pendants' && <Category name="Подвески" items={pendantsItems} category="pendants" />}
            {scarvesItems && param.id === 'scarves' && <Category name="Шарфики" items={scarvesItems} category="scarves" />}
            {ankletsItems && param.id === 'anklets' && <Category name="Анклеты" items={ankletsItems} category="anklets" />}
            {packagingItems && param.id === 'packaging' && <Category name="Упаковка" items={packagingItems} category="packaging" />}
            {dreamdropItems && param.id === 'dreamdrop' && <Category name="Dream Drop" items={dreamdropItems} category="dreamdrop" />}
            {babushkindropItems && param.id === 'babushkindrop' && <Category name="Babushkin Drop" items={babushkindropItems} category="babushkindrop" />}
        </div>
    );
}
 
export default CategoryView;