import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './NavLinks.css'; // Убедитесь, что ваш CSS содержит стили для .collection-link

// Пример запроса к API для получения новинок
const fetchnew = async () => {
    const response = await fetch('https://carabine.shop/api/items/new');
    const data = await response.json();
    return data.items || []; // Возвращаем пустой массив, если товаров нет
};

const NavLinks = () => {
    const [isSubmenuVisible, setSubmenuVisible] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [news, setnews] = useState([]);
    const submenuRef = useRef(null);

    // Переключение видимости подменю и загрузка новинок при необходимости
    

    const toggleSubmenu = async (index) => {
        setSubmenuVisible((prev) => (prev === index ? null : index));
        if ([0, 1].includes(index)) {
            if (news.length === 0) {
                const arrivals = await fetchnew();
                const filteredNews = arrivals.filter(item => item.category.includes("new"));
                setnews(filteredNews);
            }
        }
    };

    // Обработка кликов вне подменю для его закрытия
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (submenuRef.current && !submenuRef.current.contains(event.target)) {
                setSubmenuVisible(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [submenuRef]);

    // Функция для выбора случайных товаров
    
    const getRandomItems = (arr, num) => {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    };

    // Конфигурация пунктов меню
    const menuItems = [
        {
            label: "Каталог",
            links: [
                { to: "/all", label: "Все товары" },
                { to: "/cat", label: "Все коллекции" },
                { to: "/category/new/", label: "Новинки" },
                { to: "/category/bestsellers/", label: "Бестселлеры" },
                { to: "/category/chokers/", label: "Чокеры" },
                { to: "/category/necklace/", label: "Колье" },
                { to: "/category/pendants/", label: "Подвески" },
                { to: "/category/scarves/", label: "Шарфики" },
                { to: "/category/sergi/", label: "Серьги" },
                { to: "/category/braslette", label: "Браслеты" },
                { to: "/category/anklets", label: "Анклеты" },
                { to: "/category/sertifikaty/", label: "Сертификаты" },
            ],
            onHover: () => setHoveredIndex(0),
        },
        {
            label: "Коллекции",
            links: [
                { to: "/category/new/", label: "NEW" },
                { to: "/category/basic/", label: "BASIC" },
                { to: "/category/pearl/", label: "PEARL" },
                { to: "/category/crystal/", label: "CRYSTAL" },
                { to: "/category/babushkindrop/", label: "Babushkin Drop" },
                { to: "/category/dreamdrop/", label: "Dream Drop" },
            ],
            onHover: () => setHoveredIndex(1),
        },
    ];

    return (
        <nav className="nav__bottom__container" ref={submenuRef}>
            <div className="bottom__container">
                <ul className="nav">
                    {menuItems.map((item, index) => (
                        <li
                            key={index}
                            className={`nav-link ${hoveredIndex !== null && hoveredIndex !== index ? 'inactive' : ''}`}
                            onMouseEnter={() => {
                                item.onHover();
                                toggleSubmenu(index);
                            }}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <span
                                style={{
                                    color:
                                        isSubmenuVisible === index
                                            ? '#10059f'
                                            : hoveredIndex === index
                                            ? '#10059f'
                                            : '#1b1b1b',
                                }}
                            >
                                {item.label}
                            </span>
                            <div
                                className={`submenu-content ${isSubmenuVisible === index ? 'show' : ''}`}
                                onMouseEnter={() => setSubmenuVisible(index)}
                                onMouseLeave={() => setSubmenuVisible(null)}
                            >
                                {isSubmenuVisible === index && (
                                    <>
                                        <div className="submenu-columns">
                                            <div className="left-column">
                                                <ul className="sub-menu-left">
                                                    {item.links.slice(0, 6).map((link, i) => (
                                                        <li key={i}>
                                                            <Link
                                                                to={link.to}
                                                                onClick={() => setSubmenuVisible(null)}
                                                                className={item.label === "Коллекции" ? "collection-link" : ""}
                                                            >
                                                                {link.label}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {item.links.length > 6 && (
                                                <div className="right-column">
                                                    <ul className="sub-menu-right">
                                                        {item.links.slice(6).map((link, i) => (
                                                            <li key={i}>
                                                                <Link
                                                                    to={link.to}
                                                                    onClick={() => setSubmenuVisible(null)}
                                                                    className={item.label === "Коллекции" ? "collection-link" : ""}
                                                                >
                                                                    {link.label}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        <div className="submenu-images">
                                          {news.length > 0 ? (
                                            item.label === "Каталог" 
                                              ? getRandomItems(news, 4).map((item, i) => (
                                                  <Link key={i} to={`/item/${item.category}/${item._id}`}>
                                                    <img
                                                      src={`https://carabine.shop/api${item.image[0]?.path}`}
                                                      alt={`new-${i}`}
                                                      style={{ width: '234px', height: '227px' }}
                                                    />
                                                  </Link>
                                                ))
                                              : getRandomItems(news, 5).map((item, i) => (
                                                  <Link key={i} to={`/item/${item.category}/${item._id}`}>
                                                    <img
                                                      src={`https://carabine.shop/api${item.image[0]?.path}`}
                                                      alt={`new-${i}`}
                                                      style={{ width: '234px', height: '227px' }}
                                                    />
                                                  </Link>
                                                ))
                                          ) : (
                                            <p>{item.label === "Каталог" ? "Новинки еще не завезли" : ""}</p>
                                          )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </li>
                    ))}
                    <li
                        className={`nav-link ${hoveredIndex !== null && hoveredIndex !== 'bestsellers' ? 'inactive' : ''}`}
                        onMouseEnter={() => setHoveredIndex('bestsellers')}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <Link
                            to="/category/bestsellers"
                            className="nav-item"
                            style={{ color: hoveredIndex === 'bestsellers' ? '#10059f' : '#1b1b1b' }}
                        >
                            Бестселлеры
                        </Link>
                    </li>
                    <li
                        className={`nav-link ${hoveredIndex !== null && hoveredIndex !== 'about' ? 'inactive' : ''}`}
                        onMouseEnter={() => setHoveredIndex('about')}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <Link
                            to="/o-nas"
                            className="nav-item"
                            style={{ color: hoveredIndex === 'about' ? '#10059f' : '#1b1b1b' }}
                        >
                            О нас
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default NavLinks;