import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './DeliveryAndPayment.css';
import { TabTitle } from '../../utils/General';

const DeliveryAndPayment = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'Доставка и оплата', path: '/dostavka-i-oplata' },
    ];


    TabTitle('Доставка и оплата');
    // Состояния для каждого аккордеона
    const [openSections, setOpenSections] = useState({
        courierDelivery: false,
        sdekDelivery: false,
        selfPickup: false,
        payment: false,
    });

    const toggleAccordion = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    const ArrowIcon = ({ isOpen }) => (
        <svg
            width="18" 
            height="9" 
            viewBox="0 0 18 9" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
            <path d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 -6.33074e-07 1.25848 -6.46755e-07 1.10198C-6.60437e-07 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z" 
            fill="#1B1B1B"/>
        </svg>
    );

    return (
        <div className="DeliveryAndPayment">
            <div className="DeliveryAndPayment__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="Accordion">
				<span>Доставка и оплата</span>
                    <div className="Accordion_bottom"  onClick={() => toggleAccordion('courierDelivery')} style={{ cursor: 'pointer' }}>
                        <h1>Курьерская доставка по Москве и МО</h1>
						<ArrowIcon isOpen={openSections.courierDelivery} />
                    </div>
                    {openSections.courierDelivery && (
                        <div className={`Accordion__content ${openSections.courierDelivery ? 'open' : ''}`}>
                            <p>
                                Курьерская доставка по Москве - от 1 дня, 600 ₽.
                                <br />
                                Для точного расчета стоимости и сроков курьерской доставки по МО свяжитесь с нами в <a href="https://wa.me/79774757069">What’s app</a> или Instagram.
                            </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <div className="Accordion_bottom"  onClick={() => toggleAccordion('sdekDelivery')} style={{ cursor: 'pointer' }}>
                        <h1>Доставка СДЭК</h1>
                        <ArrowIcon isOpen={openSections.sdekDelivery} />
                    </div>
                    {openSections.sdekDelivery && (
                        <div className={`Accordion__content ${openSections.sdekDelivery ? 'open' : ''}`}>
                            <p>
                                Вы можете забрать свой заказ в одном из 1200+ пунктов выдачи.
                                <ul>
                                <li>Стоимость доставки в пункт выдачи 300 ₽.</li>
                                <li>Стоимость курьерской доставки СДЭК 600 ₽.</li>
                                </ul>
                                При отказе от получения заказа, стоимость доставки не возвращается.
                            </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <div className="Accordion_bottom"  onClick={() => toggleAccordion('selfPickup')} style={{ cursor: 'pointer' }}>
                        <h1>Самовывоз в Москве</h1>
                        <ArrowIcon isOpen={openSections.selfPickup} />
                    </div>
                    {openSections.selfPickup && (
                        <div className={`Accordion__content ${openSections.selfPickup ? 'open' : ''}`}>
                            <p>
                                Забрать заказ самостоятельно можно по адресу самовывоза в Москве или в магазинах.
                                <br />
                                Самовывоз осуществляется по записи, для этого свяжитесь с нами.
                                <br />
                                Предварительная примерка украшений возможна только по адресу самовывоза в Москве.
                            </p>
                        </div>
                    )}
                </div>

                <div className="Accordion">
                    <div className="Accordion_bottom"  onClick={() => toggleAccordion('payment')} style={{ cursor: 'pointer' }}>
                        <h1>Оплата</h1>
                        <ArrowIcon isOpen={openSections.payment} />
                    </div>
                    {openSections.payment && (
                        <div className={`Accordion__content ${openSections.payment ? 'open' : ''}`}>
                            <p>
                                Оплатить заказ можно как наличными при получении заказа по адресу самовывоза, так и банковской картой через сайт.
                                <br />
                                Если вам не понравилась купленная вещь или не подошёл размер, мы вернём вам деньги, при условии гарантийного срока и сохранения товарного вида.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeliveryAndPayment;