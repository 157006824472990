import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import EditItem from './EditItem';
import AllItems from './AllItems';
import AddItem from './AddItem';
import Orders from './Orders';
import BannerUpload from './BannerUpload';
import CategoryUpload from './CategoryUpload';
import Promocode from './Promocode';
import ProtectedRoute from './ProtectedRoute'; // Импортируем ProtectedRoute
import './Admin.css';

const Admin = () => {
  useEffect(() => {
    document.title = 'Админ-панель';
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route
          path="all-items"
          element={
            <ProtectedRoute>
              <AllItems />
            </ProtectedRoute>
          }
        />
        <Route
          path="add-item"
          element={
            <ProtectedRoute>
              <AddItem />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit-item/:id"
          element={
            <ProtectedRoute>
              <EditItem />
            </ProtectedRoute>
          }
        />
        <Route
          path="orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="banner-upload"
          element={
            <ProtectedRoute>
              <BannerUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="category-upload"
          element={
            <ProtectedRoute>
              <CategoryUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="promocode"
          element={
            <ProtectedRoute>
              <Promocode />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export default Admin;