import React, { useState } from 'react';
import Control from '../Controls/Control';
import DrawerNav from '../DrawerNav/DrawerNav';
import NavBrand from '../Nav-Brand/Navbrand';
import Navbottom from '../Nav-Links/NavLinks';
import SearchPopup from '../Controls/SearchPopup'; // You need to create this component
import './Container.css';

const Navtop = () => {
    const [isSearchPopupOpen, setSearchPopupOpen] = useState(false);

    const toggleSearchPopup = () => {
        setSearchPopupOpen(!isSearchPopupOpen);
    };

    return ( 
        <div className="nav__top__container">
            <div className="top__container">
                {/* Для мобильной версии - будет скрываться */}
                <div className="desktop__layout">
                    <NavBrand />
                    <Navbottom />
                    <div className="control__bar">
                        <Control toggleSearchPopup={toggleSearchPopup} />
                    </div>
                    <div className="drawer">
                        <DrawerNav />
                    </div>
                </div>
                {/* Для мобильной версии - будет отображаться */}
                <div className="mobile__layout">
                    <div className="mobile__list">
                        <div className="burgersearch">
						<DrawerNav />
                        <button onClick={toggleSearchPopup} className="search-button mobile">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_522_21351)">
                                    <path d="M23.7814 23.2191L16.5181 15.8847C17.8719 14.2575 18.6878 12.1639 18.6878 9.87796C18.6878 4.6987 14.5043 0.5 9.34389 0.5C4.18345 0.5 0 4.6987 0 9.87796C0 15.0572 4.18345 19.2559 9.34389 19.2559C11.6885 19.2559 13.8301 18.3871 15.4709 16.9549L22.724 24.2803C23.0159 24.5732 23.4895 24.5732 23.7814 24.2803C24.0729 23.9873 24.0729 23.5124 23.7814 23.2191H23.7814ZM9.34389 17.7678C5.00235 17.7678 1.48269 14.2353 1.48269 9.87796C1.48269 5.52058 5.00235 1.98809 9.34389 1.98809C13.6854 1.98809 17.2051 5.52058 17.2051 9.87796C17.2051 14.2353 13.6854 17.7678 9.34389 17.7678Z" fill="#1B1B1B"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_522_21351">
                                        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
						</div>
                        <NavBrand />
                        <Control toggleSearchPopup={toggleSearchPopup} />
                    </div>
                </div>
            </div>
            {isSearchPopupOpen && (
                <SearchPopup toggleSearchPopup={toggleSearchPopup} isActive={isSearchPopupOpen} />
            )}
        </div>
    );
};

export default Navtop;
