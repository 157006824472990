import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Promocode.css';

const Promocode = () => {
  document.title = 'Промокоды - Админ-панель'; // Устанавливаем заголовок страницы

  const [promoData, setPromoData] = useState({
    code: '',
    discountType: 'fixed', // Тип скидки: 'fixed' (фиксированная) или 'percentage' (процентная)
    discountValue: '', // Значение скидки (сумма или процент)
    maxUses: '',
  });
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({}); // Состояние для хранения ошибок валидации
  const [allPromocodes, setAllPromocodes] = useState([]); // Состояние для хранения всех промокодов

  // Функция для получения всех промокодов
  const fetchPromocodes = async () => {
    try {
      const response = await axios.get('https://carabine.shop/api/all-promocodes');
      setAllPromocodes(response.data);
    } catch (error) {
    }
  };

  // Вызываем функцию fetchPromocodes при монтировании компонента
  useEffect(() => {
    fetchPromocodes();
  }, []);

  // Обработчик изменения полей формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPromoData({
      ...promoData,
      [name]: value,
    });
    // Очищаем ошибку для этого поля
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  // Валидация данных перед отправкой
  const validateForm = () => {
    const newErrors = {};

    if (!promoData.code.trim()) {
      newErrors.code = 'Код промокода обязателен.';
    }

    if (isNaN(promoData.discountValue) || promoData.discountValue <= 0) {
      newErrors.discountValue = 'Значение скидки должно быть положительным числом.';
    }

    if (isNaN(promoData.maxUses) || promoData.maxUses <= 0) {
      newErrors.maxUses = 'Максимальное количество использований должно быть положительным числом.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Возвращаем true, если нет ошибок
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверяем валидность данных
    if (!validateForm()) {
      return;
    }

    try {
      // Преобразуем значение скидки в число
      const numericDiscountValue = parseFloat(promoData.discountValue);

      // Отправляем данные на сервер
      await axios.post('https://carabine.shop/api/create-promo', {
        ...promoData,
        discountValue: numericDiscountValue, // Передаем числовое значение
      }); 

      setMessage('Промокод успешно создан!'); 
      setPromoData({
        code: '',
        discountType: 'fixed',
        discountValue: '',
        maxUses: '',
      });
      setErrors({}); // Очищаем ошибки
      fetchPromocodes(); // Обновляем список промокодов после создания нового
    } catch (error) {
      setMessage('Ошибка при создании промокода');
    }
  };

  return (
    <div className="promoCode">
      <h1>Промокоды</h1>

      {/* Отображение всех промокодов в виде таблицы */}
      {allPromocodes.length > 0 ? (
        <table border="1" cellPadding="10" cellSpacing="0">
          <thead>
            <tr>
              <th>Код</th>
              <th>Тип скидки</th>
              <th>Значение скидки</th>
              <th>Макс. использование</th>
              <th>Использовано</th>
              <th>Активен</th>
            </tr>
          </thead>
          <tbody>
            {allPromocodes.map((promo) => (
              <tr key={promo.id}>
                <td>{promo.code}</td>
                <td>{promo.discount_type === 'percentage' ? 'Процентная' : 'Фиксированная'}</td>
                <td>
                  {promo.discount_type === 'percentage'
                    ? `${promo.discount_value}%`
                    : `${promo.discount_value} рублей`}
                </td>
                <td>{promo.max_uses}</td>
                <td>{promo.used_count}</td>
                <td>{promo.is_active ? 'Да' : 'Нет'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Нет доступных промокодов.</p>
      )}

      {/* Форма для создания нового промокода */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Код промокода:
            <input
              type="text"
              name="code"
              value={promoData.code}
              onChange={handleChange}
              required
            />
          </label>
          {errors.code && <span className="error-message">{errors.code}</span>}
        </div>
        <div>
          <label>
            Тип скидки:
            <select
              name="discountType"
              value={promoData.discountType}
              onChange={handleChange}
              required
            >
              <option value="fixed">Фиксированная сумма</option>
              <option value="percentage">Процентная скидка</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Значение скидки:
            <input
              type="number"
              name="discountValue"
              value={promoData.discountValue}
              onChange={handleChange}
              required
            />
          </label>
          {errors.discountValue && (
            <span className="error-message">{errors.discountValue}</span>
          )}
        </div>
        <div>
          <label>
            Максимальное количество использований:
            <input
              type="number"
              name="maxUses"
              value={promoData.maxUses}
              onChange={handleChange}
              required
            />
          </label>
          {errors.maxUses && <span className="error-message">{errors.maxUses}</span>}
        </div>
        <button type="submit">Создать промокод</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Promocode;