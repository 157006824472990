import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './Offer.css';
import { TabTitle } from '../../utils/General';

const Offer = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'договор публичной оферты', path: '/dogovor-publichnoj-oftery' },
    ];

    TabTitle('Договор публичной оферты');
    return (
        <div className="Offer">
            <div className="Offer__container">
                <Breadcrumbs items={breadcrumbItems} />
                <h1>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ</h1>
				<div className="textwidth">
                <p><span className="text">Общее положения.</span></p>
                <p><span className="text">Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту Интернет-магазина www.carabine.shop, расположенному по адресу www.carabine.shop</span></p>
                <ol>
                    <li><span className="text">Сайт Интернет-магазина www.carabine.shop (далее – Сайт или Интернет-магазин) является собственностью ИП Карабинцевой Елизаветы Витальевны.</span></li>
                    <li><span className="text">Настоящее Соглашение регулирует отношения между Администрацией сайта Интернет-магазина www.carabine.shop (далее – Администрация сайта) и Пользователем данного Сайта.</span></li>
                    <li><span className="text">Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</span></li>
                    <li><span className="text">Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</span></li>
                    <li><span className="text">Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</span></li>
                </ol>
                <p><span className="text">&nbsp;1. Определения терминов.</span></p>
                <ol>
                    <li><span className="text">Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</span></li>
                    <li><span className="text">www.carabine.shop – Интернет-магазин, расположенный на доменном имени www.carabine.shop, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.</span></li>
                    <li><span className="text">Интернет-магазин – Сайт, содержащий информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или) приобретение Товара.</span></li>
                    <li><span className="text">Администрация сайта Интернет-магазина – уполномоченные сотрудники на управления Сайтом, действующие от имени ИП Карабинцева Елизавета Витальевна.</span></li>
                    <li><span className="text">Пользователь сайта Интернет-магазина (далее — “Пользователь”) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</span></li>
                    <li><span className="text">Содержание сайта Интернет-магазина (далее – “Содержание”) — охраняемые результаты интеллектуальной деятельности.</span></li>
                </ol>
                <p><span className="text">2. Предмет соглашения.</span></p>
                <ol>
                    <li><span className="text">Предметом настоящего Соглашения является предоставление возможности Пользователю приобретать для некоммерческих нужд Товары, представленные в каталоге Интернет-магазина. Настоящее Соглашение заключается между Покупателем и Интернет-магазином в момент оформления заказа. Покупатель подтверждает свое согласие с условиями Соглашения, путем проставления отметки в графе «Я согласен с правилами магазина» при оформлении заказа.</span></li>
                    <li><span className="text">Интернет-магазин предоставляет Пользователю следующие виды услуг:</span></li>
                </ol>
                <ul>
                    <li><span className="text">доступ к средствам поиска и навигации Интернет-магазина;</span></li>
                    <li><span className="text">доступ к информации о Товаре и к информации о приобретении Товара на платной основе;</span></li>
                    <li><span className="text">иные виды услуг (сервисов), реализуемые на страницах Интернет-магазина.</span></li>
                    <li><span className="text">под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги Интернет-магазина, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги Интернет-магазина.</span></li>
                </ul>
                <ol>
                    <li><span className="text">Настоящее Соглашение и информация о товаре является публичной офертой (в соответствии со ст435 и ч2 ст437 ГК РФ). Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.</span></li>
                    <li><span className="text">Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.<br />
                    К отношениям между Интернет-магазином и Покупателем применяются ст. 497 Федерального закона РФ от 25.10.2007 N 234-ФЗ Гражданского</span></li>
                    <li><span className="text">Кодекса Российской Федерации о продажах товаров дистанционным способом, а также Закон РФ «О защите прав потребителей» от 07.02.1992 № 2300-1 и иные правовые акты, принятые в соответствии с ними.</span></li>
                </ol>
                <p><span className="text">1. Приобретение товара.</span></p>
                <ol>
                    <li><span className="text">Товар представлен на Сайте через изображения образцов.</span></li>
                    <li><span className="text">Каждое изображение сопровождается описанием товара и его ценой. Изображение товара может отличаться от его фактического внешнего вида. Описание товара не является исчерпывающим и может содержать ошибки или неточности.</span></li>
                    <li><span className="text">По просьбе Покупателя менеджер Интернет-магазина обязан предоставить (по телефону или посредством электронной почты) прочую информацию, необходимую и достаточную, с точки зрения Покупателя, для принятия им решения о покупке товара, а также проверить наличие товара на складе.</span></li>
                    <li><span className="text">В случае отсутствия заказанных Покупателем Товаров на складе Продавца, последний вправе исключить указанный Товар из Заказа или аннулировать Заказ Покупателя, уведомив об этом Покупателя путем направления соответствующего электронного сообщения по адресу, указанному Покупателем при оформлении заказа (либо звонком оператора). В случае аннулирования предоплаченного товара, Продавец обязуется вернуть стоимость оплаченного Товара тем способом, которым он был оплачен. Покупатель вправе заменить отсутствующий товар другим товаром, который присутствует на складе.</span></li>
                    <li><span className="text">Указанная на Сайте цена товара может быть изменена Интернет-магазином в одностороннем порядке.</span></li>
                    <li><span className="text">В случае изменения цены товара, заказанного Покупателем, менеджер Интернет-магазина при первой возможности информирует об этом Покупателя (по телефону или посредством электронной почты) для получения подтверждения либо аннулирования заказа. При невозможности связаться с Покупателем данный заказ считается аннулированным.</span></li>
                    <li><span className="text">Приобретение Товара, предлагаемого на Сайте, может потребовать заполнения полей информацией о персональных данных Пользователя.</span></li>
                    <li><span className="text">Заказ может быть оформлен Покупателем следующими способами: по номеру телефона или оформлен самостоятельно на Сайте.</span></li>
                    <li><span className="text">После оформления заказа на e-mail или телефон Покупателя отправляется письмо, подтверждающее принятие заказа, с указанием наименований выбранных товаров и общей суммы заказа, являющийся неотъемлемой частью настоящего Соглашения. Далее менеджер Интернет-магазина связывается с Покупателем (по телефону или посредством электронной почты) для получения подтверждения заказа.</span></li>
                    <li><span className="text">Покупатель вправе отказаться от заказанного товара в любое время до его отправки Покупателю, заблаговременно поставив в известность об этом Интернет-магазин (по телефону или посредством электронной почты).</span></li>
                    <li><span className="text">Покупатель не вправе отказаться от заказанного украшения и вернуть его после осуществления доставки до адреса покупателя.</span></li>
                </ol>
                <p><span className="text">1. Доставка товара по России.</span></p>
                <ol>
                    <li><span className="text">Способы и примерные сроки доставки указаны на Сайте в разделе “Доставка и оплата”.</span></li>
                    <li><span className="text">Доставка заказанных и оплаченных товаров производится в пределах территории РФ и Республики Беларусь, и может быть ограничена по усмотрению Администрации Сайта.</span></li>
                    <li><span className="text">Стоимость доставки указана на Сайте и оплачивается Покупателем.</span></li>
                    <li><span className="text">Покупатель обязан произвести в присутствии курьера осмотр товара, проверить его наличие, количество и качество. Обязанность Сайта по передаче Товара Покупателю считается исполненной в момент передачи Товара.</span></li>
                    <li><span className="text">Риск случайного разрушения или повреждения Товара переходит к Покупателю в момент передачи ему товара.</span></li>
                    <li><span className="text">Обязанность Сайта о передаче Товара Покупателю считается исполненной в момент передачи Товара Покупателю сотрудником курьерской службы.</span></li>
                    <li><span className="text">Покупатель соглашается с тем, что доставка не является частью Товара, но лишь отдельной услугой, которая заканчивается в момент передачи Товара Покупателю. В связи с этим приобретение Товара с доставкой не дает Покупателю право требования доставки приобретенного Товара в целях гарантийного обслуживания или замены, не дает возможности осуществлять гарантийное обслуживание или замену Товара посредством выезда к Покупателю и не подразумевает возможность возврата стоимости доставки Товара в случаях, когда Покупатель имеет право на возврат денег за Товар как таковой, в соответствии с Законом РФ «О защите прав потребителей».&nbsp;</span></li>
                </ol>
                <p><span className="text">1. Международная доставка товара.</span></p>
                <ol>
                    <li><span className="text">Сайт в праве на своё усмотрение ограничить территорию распространения услуги “Международная доставка”.</span></li>
                    <li><span className="text">Сайт в праве на своё усмотрение ограничивать Товары, которые доступны для Международной отправки.</span></li>
                    <li><span className="text">Цена Международной доставки согласовывается между Сайтом и Покупателем перед моментом совершения покупки по электронной почте. После согласования цены доставки, цена суммируется с суммой заказа.</span></li>
                </ol>
                <p><span className="text">1. Оплата товара.</span></p>
                <ol>
                    <li><span className="text">Способы оплаты товара указаны на Сайте в разделе “Доставка и оплата”.</span></li>
                    <li><span className="text">Товары оплачиваются только в валюте РФ.</span></li>
                    <li><span className="text">В случае неверного указания цены заказанного Покупателем Товара, Продавец информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо аннулирования Заказа.</span></li>
                    <li><span className="text">При невозможности связаться с Покупателем данный Заказ считается аннулированным. Если Заказ был оплачен, Продавец возвращает Покупателю оплаченную за Заказ сумму тем же способом, которым она была уплачена.</span></li>
                    <li><span className="text">Цена Товара на Сайте может быть изменена Продавцом в одностороннем порядке. При этом цена на заказанный Покупателем Товар изменению не подлежит.</span></li>
                    <li><span className="text">Фактом приемки товара Покупателем является оплата товара (оплата общей суммы заказа и суммы расходов по доставке товара Покупателю).</span></li>
                </ol>
                <p><span className="text">2.Возврат товара и денежных средств.</span></p>
                <ol>
                    <li><span className="text">Условия возврата Товара указаны на сайте в разделе “Возврат и обмен”.</span></li>
                    <li><span className="text">Покупатель вправе вернуть или обменять товар в случае обнаружения явного заводского дефекта в течение 30-ти (четырнадцати) дней со дня его получения. Вернуть товар возможно только в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара (email или sms подтверждения). В течение этого периода Покупатель обязан известить менеджера магазина, написав письмо на емейл – carabine.jwl@mail.ru или по номеру телефона о желании вернуть товар.</span></li>
                    <li><span className="text">При отказе Покупателя от товара Интернет-магазин возвращает ему денежную сумму, уплаченную за товар, кроме суммы расходов Интернет-магазина, связанных с доставкой товара Покупателю и вывозом товара от Покупателя (стоимость доставки Товара курьерской службой оплачивается Покупателем), не позднее десяти дней со дня предъявления Покупателем соответствующего требования.</span></li>
                    <li><span className="text">Возврат денежных средств осуществляется на банковские реквизиты Покупателя.</span></li>
                    <li><span className="text">Товар ненадлежащего качества может быть заменен на аналогичный товар надлежащего качества, либо возвращен Продавцу, в этом случае доставку товара оплачивает Продавец.</span></li>
                    <li><span className="text">Отличие элементов дизайна или оформления от заявленного на Сайте описания не является признаком ненадлежащего качества</span></li>
                    <li><span className="text">Внешний вид Товара и комплектность Заказа Покупатель обязан проверить в момент доставки Товара. Принятие заказа означает, что Покупатель не имеет претензий к количеству и внешнему виду товара.</span></li>
                    <li><span className="text">Данный договор считается заключенным с момента выдачи продавцом покупателю кассового или товарного чека, либо иного документа, подтверждающего оплату товара, или с момента получения продавцом сообщения о намерении приобрести товар.</span></li>
                </ol>
                <p><span className="text">3. Права и обязанности сторон.</span></p>
                <ul>
                    <li><span className="text">Администрация сайта вправе:</span></li>
                </ul>
                <ol>
                    <li><span className="text">Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</span></li>
                    <li><span className="text">Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.</span></li>
                    <li><span className="text">Расширять и сокращать товарное предложение на Сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению.</span></li>
                    <li><span className="text">Сайт вправе проводить специальные акции.</span></li>
                    <li><span className="text">В соответствии с Федеральным законом «О персональных данных», размещая заказ, Вы даете согласие Интернет-магазину на обработку своих персональных данных в целях рассылки информации и продвижения товаров на рынке, без ограничения срока. Указанное согласие может быть отозвано Вами путём направления письменного уведомления. В этом случае Ваши персональные данные будут уничтожены, а их обработка прекращена в течение 7 рабочих дней с даты получения нами уведомления.</span></li>
                    <li><span className="text">Персональные данные Пользователя/Покупателя обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.</span></li>
                    <li><span className="text">Продавец обязуется соблюдать конфиденциальность в отношении персональных данных Покупателя, а также иной информации о Покупателе, ставшей известной Продавцу в связи с исполнением настоящего Соглашения, за исключением случаев, когда такая информация: является общедоступной; раскрыта по требованию или с разрешения Покупателя; требует раскрытия по основаниям, предусмотренным законодательством, или при поступлении соответствующих запросов суда или уполномоченных государственных органов; раскрыта по иным основаниям, предусмотренным согласованием Сторон.</span></li>
                    <li><span className="text">Продавец вправе в одностороннем порядке вносить изменения в Соглашение путем публикации изменений на Сайте по адресу www.carabine.shop если иное не предусмотрено новой редакцией Соглашения.</span></li>
                    <li><span className="text">Соглашение является юридически обязывающим договором между Покупателем и Продавцом содержит правила совершения покупок в Интернет-магазине «carabine.shop».</span></li>
                </ol>
                <ul>
                    <li><span className="text">Пользователь Сайта обязуется:</span></li>
                </ul>
                <ol>
                    <li><span className="text">Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</span></li>
                    <li><span className="text">Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</span></li>
                </ol>
                <ul>
                    <li><span className="text">Пользователю запрещается:</span></li>
                </ul>
                <ol>
                    <li><span className="text">Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания</span></li>
                    <li><span className="text">Сайта данного Интернет-магазина;</span></li>
                    <li><span className="text">Нарушать надлежащее функционирование Сайта;</span></li>
                    <li><span className="text">Несанкционированный доступ к функциям Сайта, а также к любым услугам, предлагаемым на Сайте;</span></li>
                    <li><span className="text">Нарушать систему безопасности или аутентификации на Сайте.</span></li>
                    <li><span className="text">Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-магазина или других лиц.</span></li>
                </ol>
                <p><span className="text">4. Использование сайта интернет-магазина.</span></p>
                <ol>
                    <li><span className="text">Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.</span></li>
                    <li><span className="text">Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.</span></li>
                    <li><span className="text">Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказанию услуг, предоставляемых на Сайте.</span></li>
                    <li><span className="text">Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.</span></li>
                    <li><span className="text">Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам Интернет-магазином.</span></li>
                </ol>
                <p><span className="text">5. Ответственность.</span></p>
                <ul>
                    <li><span className="text">Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</span></li>
                    <li><span className="text">Администрация сайта не несет ответственности за:</span></li>
                </ul>
                <ol>
                    <li>
                        <p><span className="text">Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</span></p>
                    </li>
                    <li>
                        <p><span className="text">Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.</span></p>
                    </li>
                    <li>
                        <p><span className="text">Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.</span></p>
                    </li>
                </ol>
                <ul>
                    <li>
                        <p><span className="text">За ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, приобретенных в Интернет-магазине</span></p>
                    </li>
                </ul>
                <p><span className="text">6. Нарушение условий договора публичной оферты.</span></p>
                <ol>
                    <li><span className="text">Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</span></li>
                    <li><span className="text">Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности ИП Карабинцева Елизавета Витальевна и Пользователей.</span></li>
                    <li><span className="text">Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</span></li>
                    <li><span className="text">Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</span></li>
                    <li><span className="text">Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</span></li>
                </ol>
                <p><span className="text">7. Разрешение споров.</span></p>
                <ol>
                    <li><span className="text">В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).</span></li>
                    <li><span className="text">Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</span></li>
                    <li><span className="text">При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.</span></li>
                </ol>
                <p><span className="text">1. Дополнительные условия.</span></p>
                <ol>
                    <li><span className="text">Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.</span></li>
                </ol>
                <p><span className="text">Реквизиты компании:</span></p>
                <p><span className="text">Счёт №: 40802810300006335391</span></p>
                <p><span className="text">Наименование: Индивидуальный предприниматель Карабинцева Елизавета Витальевна&nbsp;</span></p>
                <p><span className="text">ИНН: 502409230508</span></p>
                <p><span className="text">Реквизиты банка:&nbsp;</span></p>
                <p><span className="text">Название: АО «ТБанк»<br />
                ИНН: 7706092528 БИК: 044525974 Корр. счёт: 30101810145250000974</span></p>
            </div>
        </div>
        </div>
    );
};

export default Offer;
