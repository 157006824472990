import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup, Typography, Container, TextField, Fab, TableSortLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowUp } from '@mui/icons-material';
import './Admin.css';

const Allitems = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const API_BASE_URL = 'https://carabine.shop/api';

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Все товары - Админ-панель';
    }, []);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/items`)
            .then((res) => {
                setItems(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setError('Ошибка при загрузке товаров');
                setLoading(false);
            });
    }, []);

    const parseImageData = (image) => {
        if (typeof image === 'string') {
            try {
                const cleanedString = image.replace(/\\"/g, '"');
                const parsedData = JSON.parse(cleanedString);
                return parsedData.filter(img => img !== null);
            } catch (error) {
                return [];
            }
        } else if (Array.isArray(image)) {
            return image.filter(img => img !== null);
        }
        return [];
    };

    const handleRemoveItem = useCallback((index) => {
        const itemToRemove = items[index];
        axios.delete(`${API_BASE_URL}/items/${itemToRemove._id}`)
            .then(() => {
                const updatedItems = items.filter((_, i) => i !== index);
                setItems(updatedItems);
            })
            .catch((err) => {
                setError('Ошибка при удалении товара');
            });
    }, [items]);

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    // Сначала объявляем filteredItems
    const filteredItems = items.filter((item) => {
        return (
            (item.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
            (item.category?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
            (item.price?.toString() || '').includes(searchQuery)
        );
    });

    // Затем используем её в sortedItems
    const sortedItems = React.useMemo(() => {
        if (!orderBy) return filteredItems;

        return [...filteredItems].sort((a, b) => {
            let comparison = 0;
            
            if (orderBy === 'price') {
                comparison = a.price - b.price;
            } else if (orderBy === 'name') {
                comparison = a.name?.localeCompare(b.name);
            } else if (orderBy === 'createdAt') {
                const dateA = new Date(a.createdAt || 0);
                const dateB = new Date(b.createdAt || 0);
                comparison = dateA - dateB;
            }

            return order === 'asc' ? comparison : -comparison;
        });
    }, [filteredItems, orderBy, order]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h4" component="h2" gutterBottom>Все товары</Typography>

                <TextField
                    label="Поиск товаров"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <Typography variant="body1" gutterBottom>
                    Всего товаров: {items.length} | Найдено: {filteredItems.length}
                </Typography>

                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Миниатюра</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleSort('name')}
                                        >
                                            Название
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Количество</TableCell>
                                    <TableCell>Категория</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'price'}
                                            direction={orderBy === 'price' ? order : 'asc'}
                                            onClick={() => handleSort('price')}
                                        >
                                            Цена
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedItems.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell>
                                            {(() => {
                                                const images = parseImageData(item.image);
                                                if (images.length > 0 && images[0]) {
                                                    const imagePath = images[0].path
                                                        ? images[0].path.startsWith('/public/uploads')
                                                            ? images[0].path
                                                            : `/public/uploads/${images[0].filename || ''}`
                                                        : `/public/uploads/${images[0].filename || ''}`;

                                                    return (
                                                        <img
                                                            src={`${API_BASE_URL}${imagePath}`}
                                                            alt={item.name}
                                                            style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 4 }}
                                                            onError={(e) => {
                                                                e.target.src = `${API_BASE_URL}/placeholder.png`;
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <img
                                                            src={`${API_BASE_URL}/placeholder.png`}
                                                            alt="Placeholder"
                                                            style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 4 }}
                                                        />
                                                    );
                                                }
                                            })()}
                                        </TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{item.category}</TableCell>
                                        <TableCell>{item.price}</TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="text" size="small">
                                                <Button 
                                                    onClick={() => navigate(`/admin/edit-item/${item._id}`)}
                                                    color="primary"
                                                >
                                                    Редактировать
                                                </Button>
                                                <Button 
                                                    onClick={() => handleRemoveItem(index)}
                                                    color="error"
                                                >
                                                    Удалить
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>

            {showScrollButton && (
                <Fab
                    color="primary"
                    aria-label="Вверх"
                    onClick={scrollToTop}
                    style={{ position: 'fixed', bottom: 20, right: 20, width: 56}}
                >
                    <KeyboardArrowUp />
                </Fab>
            )}
        </Container>
    );
};

export default Allitems;