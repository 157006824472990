import React, { useState } from 'react';
import './Description.css'; // Подключите файл стилей, если у вас его еще нет

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="18"
        height="9"
        viewBox="0 0 18 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
    >
        <path
            d="M8.43925 8.31106L0.213913 1.52225C0.0765998 1.40898 0 1.25848 0 1.10198C0 0.945487 0.0765998 0.794995 0.213913 0.68172L0.223207 0.674411C0.289774 0.619307 0.369899 0.575429 0.45871 0.545446C0.54752 0.515462 0.643159 0.5 0.739808 0.5C0.836457 0.5 0.932095 0.515462 1.02091 0.545446C1.10972 0.575429 1.18984 0.619307 1.25641 0.674411L9.00155 7.06732L16.7436 0.674409C16.8102 0.619306 16.8903 0.575428 16.9791 0.545444C17.0679 0.515461 17.1635 0.499999 17.2602 0.499999C17.3568 0.499999 17.4525 0.515461 17.5413 0.545444C17.6301 0.575428 17.7102 0.619305 17.7768 0.674409L17.7861 0.681719C17.9234 0.794993 18 0.945485 18 1.10198C18 1.25848 17.9234 1.40897 17.7861 1.52225L9.56075 8.31106C9.48841 8.37077 9.40141 8.4183 9.30502 8.45078C9.20863 8.48325 9.10486 8.5 9 8.5C8.89513 8.5 8.79136 8.48325 8.69497 8.45078C8.59859 8.4183 8.51159 8.37077 8.43925 8.31106Z"
            fill="#1B1B1B"
        />
    </svg>
);

const AccordionItem = ({ title, content, isOpen, onToggle }) => (
    <div className="accordion-item">
        <div className="accordion-header" onClick={onToggle}>
            {title}
            <ArrowIcon isOpen={isOpen} />
        </div>
        {isOpen && <div className="accordion-body">{content}</div>}
    </div>
);

const Description = ({ item }) => {
    const [openSections, setOpenSections] = useState({});
    const toggleSection = (section) => {
        setOpenSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    const formatArrayToString = (data) => {

        // Если данные являются строкой с квадратными скобками, пытаемся преобразовать их в массив
        if (typeof data === 'string' && data.startsWith('[') && data.endsWith(']')) {
            try {
                // Безопасно парсим строку как JSON
                const parsedData = JSON.parse(data);
                if (Array.isArray(parsedData) && parsedData.length > 0) {
                    return parsedData.join(', ');
                }
            } catch (e) {
            }
        }

        // Если данные массив, преобразуем его в строку
        if (Array.isArray(data) && data.length > 0) {
            return data.join(', ');
        }

        // Если данные строка, возвращаем её как есть
        if (typeof data === 'string' && data.trim() !== '') {
            return data;
        }

        // Возвращаем "Нет данных" для пустых или неопределенных значений
        return 'Нет данных';
    };

    // Функция для проверки, нужно ли отображать строку
    const shouldDisplay = (value) => {
        return value && value !== '-' && value !== 'Нет данных';
    };

    return (
        <div className="custom-accordion">
            <AccordionItem
                title="Описание"
                isOpen={openSections.description}
                onToggle={() => toggleSection('description')}
                content={
                    <div>
                        {shouldDisplay(item.type) && <p>Состав: {item.type}</p>}
                        {shouldDisplay(item.highlights) && <p>Материал: {formatArrayToString(item.highlights)}</p>}
                        {shouldDisplay(item.size) && <p>Размер: {formatArrayToString(item.size)}</p>}
                        {shouldDisplay(item.color) && <p>Цвет: {item.color}</p>}
                        {shouldDisplay(item.detail) && <p>Детали: {item.detail}</p>}
                    </div>
                }
            />
            <AccordionItem
                title="Доставка"
                isOpen={openSections.delivery}
                onToggle={() => toggleSection('delivery')}
                content={
                    <ul>
                        <li>СДЕК — до пункта выдачи — от 4 дней, 300 ₽</li>
                        <li>СДЕК — до квартиры — от 4 дней, 450 ₽</li>
                        <li>Забрать самовывозом в Москве*</li>
                        <li>Курьерская доставка по Москве — от 1 дня, 600 ₽</li>
                    </ul>
                }
            />
            <AccordionItem
                title="Рекомендации по уходу"
                isOpen={openSections.care}
                onToggle={() => toggleSection('care')}
                content={
                    <div>
                        <p>Украшения не любят воду, крема и парфюм — снимайте их перед душем и нанесением косметики.</p>
                        <p>Хранить украшения лучше отдельно друг от друга в сухом и темном месте.</p>
                    </div>
                }
            />
            <AccordionItem
                title="Оплата и возврат"
                isOpen={openSections.payment}
                onToggle={() => toggleSection('payment')}
                content={
                    <div>
                        <p>Оплатить заказ можно как наличными при получении заказа по адресу самовывоза, так и банковской картой через сайт.</p>
                        <p>Если вам не понравилась купленная вещь или не подошёл размер, мы вернём вам деньги, при условии сохранения товарного вида.</p>
                    </div>
                }
            />
            <AccordionItem
                title="Гарантия"
                isOpen={openSections.warranty}
                onToggle={() => toggleSection('warranty')}
                content={
                    <div>
                        <p>На наши изделия действует гарантия — 45 дней с момента покупки.</p>
                        <p>Если изделие пришло с производственным браком или потеряло свой первоначальный вид в течение 45 дней со дня покупки — мы произведем обмен изделия на новое или вернем вам деньги.</p>
                    </div>
                }
            />
        </div>
    );
};

export default Description;