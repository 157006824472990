import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, CircularProgress, MenuItem, Select, InputLabel, FormControl, LinearProgress } from '@mui/material';
import Compressor from 'compressorjs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const EditItem = () => {
  const [item, setItem] = useState({
    name: '',
    category: [],
    color: '',
    type: '',
    quantity: '',
    description: '',
    price: '',
    size: '',
    highlights: '',
    detail: '',
    images: []
  });
  const [previewImages, setPreviewImages] = useState([]);
  const [newPreviewImages, setNewPreviewImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletedImages, setDeletedImages] = useState([]);
  const API_BASE_URL = 'https://carabine.shop/api';
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/items/${id}`);
        const fetchedItem = response.data;
        // Преобразуем строки в массивы
        fetchedItem.category = fetchedItem.category.split(',').map(cat => cat.trim());
        fetchedItem.size = fetchedItem.size.split(',').map(size => size.trim());
        fetchedItem.highlights = fetchedItem.highlights.split(',').map(highlight => highlight.trim());
        // Преобразуем строку image в массив
        if (fetchedItem.image) {
          try {
            const cleanedImageString = fetchedItem.image.replace(/\\"/g, '"');
            const trimmedImageString = cleanedImageString.replace(/^"(.*)"$/, '$1');
            fetchedItem.images = JSON.parse(trimmedImageString).filter(img => img !== null);
          } catch (error) {
            fetchedItem.images = [];
          }
        } else {
          fetchedItem.images = [];
        }
        setItem(fetchedItem);
        setPreviewImages(fetchedItem.images);
      } catch (error) {
        setError('Ошибка при загрузке товара.');
      } finally {
        setLoading(false);
      }
    };
    fetchItem();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'category') {
      setItem({ ...item, [name]: Array.isArray(value) ? value : [value] });
    } else if (name === 'size') {
      // Преобразуем строку в массив, разделяя по запятым
      setItem({ ...item, [name]: value.split(',').map(s => s.trim()) });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const compressImage = (file, callback) => {
    new Compressor(file, {
      quality: 0.90,
      maxWidth: 1920,
      maxHeight: 1080,
      success(result) {
        callback(result);
      },
      error(err) {
        callback(file);
      },
    });
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    const MAX_IMAGES = 20 - previewImages.length;
    if (files.length > MAX_IMAGES) {
      alert(`Вы можете выбрать не более ${MAX_IMAGES} новых изображений.`);
      return;
    }
    const compressedFiles = [];
    const previews = [];
    for (let file of files) {
      if (file.size > 50 * 1024 * 1024) {
        alert('Файл слишком большой. Максимальный размер: 15 MB.');
        continue;
      }
      compressImage(file, (compressedFile) => {
        compressedFiles.push(compressedFile);
        const reader = new FileReader();
        reader.onload = (event) => {
          previews.push(event.target.result);
          setNewPreviewImages([...previews]);
        };
        reader.readAsDataURL(compressedFile);
      });
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = previewImages.filter((_, i) => i !== index);
    const deletedImage = previewImages[index].filename;
    // Добавляем имя удаленного файла в deletedImages
    setDeletedImages([...deletedImages, deletedImage]);
    // Обновляем previewImages
    setPreviewImages(updatedImages);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Перетаскивание текущих изображений
    if (source.droppableId === 'currentImages') {
      const items = Array.from(previewImages);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      setPreviewImages(items);
    }
    // Перетаскивание новых изображений
    if (source.droppableId === 'newImages') {
      const items = Array.from(newPreviewImages);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      setNewPreviewImages(items);
    }
  };

  const base64ToFile = (base64, filename) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('name', item.name);
    formData.append('category', item.category.join(','));
    formData.append('color', item.color);
    formData.append('quantity', item.quantity);
    formData.append('type', item.type);
    formData.append('description', item.description);
    formData.append('price', item.price);
    formData.append('size', Array.isArray(item.size) ? item.size.join(',') : item.size);
    formData.append('highlights', item.highlights.join(','));
    formData.append('detail', item.detail);

    // Передаем текущие изображения
    previewImages.forEach((image, index) => {
      formData.append('existingImages[]', image.filename); // Используйте [] для массива
      formData.append('imageOrder[]', index); // Порядок изображений
    });

    // Передаем удаленные изображения
    deletedImages.forEach((filename) => {
      formData.append('deletedImages', filename); // Имена файлов удаленных изображений
    });

    // Добавляем новые изображения
    newPreviewImages.forEach((preview, index) => {
      const file = base64ToFile(preview, `new_image_${index}.webp`);
      formData.append('images', file); // Новые файлы изображений
      formData.append('imageOrder', previewImages.length + index); // Порядок новых изображений
    });

    try {
      const response = await axios.put(`${API_BASE_URL}/items/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Обновляем состояние previewImages
      if (response.data.image) {
        try {
          const newImages = JSON.parse(response.data.image);
          setPreviewImages(newImages);
        } catch (error) {
          console.error('Ошибка при парсинге изображений:', error);
        }
      }

      setLoading(false);
      window.location.href = '/admin/all-items';
    } catch (err) {
      setError('Ошибка при обновлении товара');
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>Редактировать товар</Typography>
        <form onSubmit={handleSubmit}>
          {/* Текстовые поля */}
          <TextField
            label="Название товара"
            name="name"
            value={item.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Артикул"
            name="description"
            value={item.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Категория</InputLabel>
            <Select
              name="category"
              multiple
              value={item.category}
              onChange={handleChange}
              label="Категория"
            >
              <MenuItem value="chokers">Чокеры</MenuItem>
              <MenuItem value="pendants">Подвески</MenuItem>
              <MenuItem value="necklace">Колье</MenuItem>
              <MenuItem value="scarves">Шарфики</MenuItem>
              <MenuItem value="anklets">Анклеты</MenuItem>
              <MenuItem value="sergi">Серьги</MenuItem>
              <MenuItem value="braslette">Браслеты</MenuItem>
              <MenuItem value="stikery">Стикеры</MenuItem>
              <MenuItem value="sertifikaty">Сертификаты</MenuItem>
              <MenuItem value="packaging">Упаковка</MenuItem>
              <MenuItem value="bestsellers">Бестселлеры</MenuItem>
              <MenuItem value="new">Новинки</MenuItem>
              <MenuItem value="basic">BASIC</MenuItem>
              <MenuItem value="pearl">PEARL</MenuItem>
              <MenuItem value="crystal">CRYSTAL</MenuItem>
              <MenuItem value="giftcard">GIFT CARD</MenuItem>
              <MenuItem value="naturalstones">Natural stones</MenuItem>
              <MenuItem value="babushkindrop">Babushkin Drop</MenuItem>
              <MenuItem value="dreamdrop">Dream Drop</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Цвет"
            name="color"
            value={item.color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Состав"
            name="type"
            value={item.type}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Количество"
            name="quantity"
            value={item.quantity}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Цена"
            name="price"
            value={item.price}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            required
          />
          <TextField
            label="Размер"
            name="size"
            value={item.size}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Материал"
            name="highlights"
            value={item.highlights}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Детали"
            name="detail"
            value={item.detail}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Текущие изображения */}
          <Box mt={2}>
            <Typography variant="h6">Текущие изображения</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {Array.isArray(previewImages) && previewImages.map((image, index) => (
                <div
                  key={image.filename}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                >
                  <img
                    src={`${API_BASE_URL}/public/uploads/${image.filename}`}
                    alt={`Current ${index}`}
                    onError={(e) => { e.target.src = 'https://carabine.shop/api/public/placeholder.png'; }}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: 4 }}
                  />
                  <button
                    style={{ marginLeft: '10px', background: 'red', color: 'white', border: 'none', borderRadius: '4px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(index);
                    }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </Box>

          {/* Новые изображения */}
          <Box mt={2}>
            <Typography variant="h6">Добавить новые изображения</Typography>
            <input
              type="file"
              name="images"
              multiple
              accept="image/jpeg,image/png,image/webp"
              onChange={handleFileChange}
              style={{ marginBottom: '10px' }}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="newImages" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                  >
                    {newPreviewImages.map((preview, index) => (
                      <Draggable key={`new-${index}`} draggableId={`new-${index}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              position: 'relative',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <img
                              src={preview}
                              alt={`New Preview ${index}`}
                              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: 4 }}
                            />
                            <button
                              style={{
                                position: 'absolute',
                                top: '90px',
                                right: '5px',
                                background: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                width: '24px',
                                height: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const updatedPreviews = newPreviewImages.filter((_, i) => i !== index);
                                setNewPreviewImages(updatedPreviews);
                              }}
                            >
                              ×
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          {/* Кнопка отправки */}
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Обновить'}
            </Button>
          </Box>
          {loading && <LinearProgress />}
          {error && <Typography color="error" mt={2}>{error}</Typography>}
        </form>
      </Box>
    </Container>
  );
};

export default EditItem;