import React, { useEffect } from 'react';
import Navtop from '../Nav/Container/Container';
import './Header.css';

const Header = () => {
    return ( 
        <div className='header__container'>
            <Navtop />
        </div>
    );
}
 
export default Header;
