import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './AboutUs.css';
import desktopImage from '../../asset/img/o-nas.jpg'; // Desktop image path
import mobileImage from '../../asset/img/o-nas-mobile.jpg'; // Mobile image path
import { TabTitle } from '../../utils/General';

const AboutUs = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'о нас', path: '/o-nas' },
    ];
    TabTitle('О нас');

    const [imageSrc, setImageSrc] = useState(desktopImage);

    const updateImage = () => {
        if (window.innerWidth <= 768) {
            setImageSrc(mobileImage);
        } else {
            setImageSrc(desktopImage);
        }
    };

    useEffect(() => {
        updateImage();
        window.addEventListener('resize', updateImage);

        return () => {
            window.removeEventListener('resize', updateImage);
        };
    }, []);

    return (
        <div className="AboutUs">
            <div className="AboutUs__container">
                <Breadcrumbs items={breadcrumbItems} />
                <img src={imageSrc} alt='О нас' className="AboutUs__image" />
                <span className="text">
                    <br />Свобода, правда и юмор — три опоры философии бренда. 
                    Мы за естественность и простоту. Не только в нашем продукте, но и в повседневной жизни. 
					Мы всегда за поиск. Никого не осуждаем и принимаем выбор каждого. Задаем вопрос, но не даем ответ.<br /><br />
                    Карабин — элемент быстрого скрепления. В этом и есть миссия бренда. Мы делаем украшения для всех, объединяя этим ваши миры, поколения, гендеры и субкультуры. 
					Наша цель, сплотить крайности и укрепить их в едином комьюнити.<br />Ведь, кажется, именно так рождается любовь?
                </span>
            </div>
        </div>
    );
};

export default AboutUs;
