import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import './ItemCarousel.css';
import { Button } from 'react-bootstrap';

const ProductCarousel = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    // Проверка размера экрана
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // 768px - примерное значение для мобильных устройств и планшетов
        };
        handleResize(); // Проверяем при загрузке
        window.addEventListener('resize', handleResize); // Проверяем при изменении размера окна
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };

    const handleThumbnailClick = (index) => {
        setActiveIndex(index);
    };

    const handleImageClick = (img) => {
        setSelectedImage(img);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Убедимся, что props.item.image является массивом и отфильтруем null значения
    const images = Array.isArray(props.item?.image) ? props.item.image.filter(img => img !== null) : [];

    return (
        <div className="product__carousel__container">
            <div className="product__carousel">
                <Carousel
                    variant="dark"
                    activeIndex={activeIndex}
                    onSelect={handleSelect}
                    interval={null}
                    indicators={true}
                    slide={false}
                >
                    {images.map((img, index) => {
                        // Проверяем, что img существует и содержит необходимые свойства
                        if (!img) return null;

                        const imageUrl = `https://carabine.shop/api/public/${img.optimizedPath || `uploads/${img.filename}`}`;

                        return (
                            <Carousel.Item key={index} className="custom-carousel-item">
                                <div
                                    className="carousel__image__container"
                                    onClick={() => handleImageClick(imageUrl)}
                                    onMouseEnter={(e) => {
                                        if (!isMobile) {
                                            e.currentTarget.style.transform = 'scale(1.2)';
                                            e.currentTarget.style.transition = 'transform 0.5s ease';
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (!isMobile) {
                                            e.currentTarget.style.transform = 'scale(1)';
                                        }
                                    }}
                                >
                                    <img
                                        className="carousel__image"
                                        src={imageUrl}
                                        alt="item"
                                    />
                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>

            {/* Кастомные стили для индикаторов */}
            <style jsx>{`
                :global(.carousel-indicators) {
                    position: static;
                    margin: 15px 0 0;
                }

                :global(.carousel-indicators [data-bs-target]) {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border: 1px solid #000;
                    background-color: transparent;
                }

                :global(.carousel-indicators .active) {
                    background-color: #000;
                }
            `}</style>

            <div className="thumbnail__container">
                {images.map((img, index) => {
                    // Проверяем, что img существует и содержит необходимые свойства
                    if (!img) return null;

                    const imageUrl = `https://carabine.shop/api/public/${img.optimizedPath || `uploads/${img.filename}`}`;

                    return (
                        <img
                            key={index}
                            className={`thumbnail ${activeIndex === index ? 'active' : ''}`}
                            src={imageUrl}
                            alt={`Thumbnail ${index}`}
                            onClick={() => handleThumbnailClick(index)}
                        />
                    );
                })}
            </div>

            {/* Модальное окно для просмотра изображения */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Body>
                    {/* Кнопка закрытия (SVG) */}
                    <button
                        className="modal-close-btn"
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                    {/* Изображение */}
                    <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ width: '100%', maxHeight: '80vh', display: 'block', margin: 'auto' }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProductCarousel;