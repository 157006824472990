import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryCard.css';
import { Button } from '@mui/material';

const CategoryCard = (props) => { 
    return ( 
        <div className="category__card__card">
            <div className="category__card__detail">
                <Link to={props.data.url}>
                    <div className="category__name">
                        <span>{props.data.name}</span>
                        <p>{props.data.text} &nbsp;&nbsp;
                            <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.81106 10.0607L1.02225 18.2861C0.908977 18.4234 0.758483 18.5 0.601985 18.5C0.445487 18.5 0.294995 18.4234 0.18172 18.2861L0.174411 18.2768C0.119307 18.2102 0.0754295 18.1301 0.0454459 18.0413C0.0154624 17.9525 5.04951e-07 17.8568 5.09175e-07 17.7602C5.134e-07 17.6635 0.0154624 17.5679 0.045446 17.4791C0.0754295 17.3903 0.119307 17.3102 0.174411 17.2436L6.56732 9.49845L0.174412 1.75641C0.119308 1.68984 0.0754302 1.60972 0.0454467 1.52091C0.0154631 1.4321 1.22708e-06 1.33646 1.2313e-06 1.23981C1.23553e-06 1.14316 0.0154632 1.04752 0.0454467 0.958713C0.0754302 0.869901 0.119308 0.789776 0.174412 0.723209L0.181721 0.713915C0.294996 0.576601 0.445488 0.5 0.601986 0.5C0.758484 0.5 0.908977 0.576601 1.02225 0.713915L7.81106 8.93925C7.87077 9.01159 7.9183 9.09859 7.95078 9.19498C7.98325 9.29137 8 9.39514 8 9.5C8 9.60487 7.98325 9.70864 7.95078 9.80503C7.9183 9.90141 7.87077 9.98841 7.81106 10.0607Z" fill="#1B1B1B"/>
                            </svg>
                        </p>
                    </div>
                </Link>
            </div>
            <div className="category__image"> 
                <Link to={props.data.url}>
                   <img src={`https://carabine.shop/api/public${props.data.image_url}`} alt={props.data.name} className="product__img"/> 
                </Link>
                <div className="overlay"></div>
            </div>
        </div>
    );
}

export default CategoryCard;