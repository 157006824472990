import React from 'react';
import axios from 'axios';
import { Button, Box, Typography, LinearProgress } from '@mui/material';

const BannerUploader = ({ resolution, label }) => {
    const [file, setFile] = React.useState(null);
    const [message, setMessage] = React.useState('');
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [previewImage, setPreviewImage] = React.useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;

        // Проверка размера файла (15MB)
        if (selectedFile.size > 15 * 2500 * 1778) {
            setMessage('Файл слишком большой. Максимальный размер: 15 MB.');
            return;
        }

        // Проверка типа файла
        if (!selectedFile.type.match('image/(jpeg|png|webp)')) {
            setMessage('Пожалуйста, загружайте только JPEG, PNG или WebP');
            return;
        }

        setFile(selectedFile);
        
        // Создаем превью (без сжатия)
        const reader = new FileReader();
        reader.onload = (event) => {
            setPreviewImage(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Пожалуйста, выберите файл');
            return;
        }
        
        setIsUploading(true);
        setMessage('');
        setUploadProgress(0);
        
        try {
            const formData = new FormData();
            formData.append(`banner_${resolution}`, file);
            
            const response = await axios.post('https://carabine.shop/api/banner', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded)
                    );
                    setUploadProgress(percentCompleted);
                },
            });
            
            setMessage(response.data.message || 'Баннер успешно загружен');
            setFile(null);
            setPreviewImage('');
        } catch (error) {
            console.error('Ошибка загрузки:', error);
            setMessage(error.response?.data?.message || 'Ошибка при загрузке файла');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Box my={2}>
            <Typography variant="h6" component="h3" gutterBottom>
                Баннер для {label} ({resolution}px)
            </Typography>
            
            <input
                type="file"
                accept="image/jpeg, image/png, image/webp"
                onChange={handleFileChange}
                style={{ display: 'block', marginBottom: '16px' }}
                disabled={isUploading}
            />
            
            {previewImage && (
                <Box mb={2} style={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <img 
                        src={previewImage} 
                        alt="Предпросмотр баннера" 
                        style={{ 
                            maxWidth: '100%', 
                            height: 'auto',
                            border: '1px solid #ddd',
                            borderRadius: '4px'
                        }} 
                    />
                </Box>
            )}
            
            <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={isUploading || !file}
            >
                {isUploading ? 'Загрузка...' : 'Загрузить баннер'}
            </Button>
            
            {isUploading && (
                <Box mt={2}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" color="textSecondary" align="center">
                        {uploadProgress}%
                    </Typography>
                </Box>
            )}
            
            {message && (
                <Typography style={{ 
                    marginTop: '16px', 
                    color: message.includes('Ошибка') ? 'red' : 'green' 
                }}>
                    {message}
                </Typography>
            )}
        </Box>
    );
};

const BannerUpload = () => {
    return (
        <Box my={4}>
            <Typography variant="h4" component="h2" gutterBottom>
                Загрузка баннеров
            </Typography>
            <Typography variant="body1" paragraph>
                Рекомендуется загружать изображения в исходном качестве (JPEG, PNG или WebP).
                Оптимальные размеры:
            </Typography>
            <ul style={{ marginBottom: '20px' }}>
                <li><strong>ПК (1920px)</strong> - 2500×1776</li>
                <li><strong>Ноутбук (1440px)</strong> - 2500×1778</li>
                <li><strong>Планшет (768px)</strong> - 1500×1152</li>
                <li><strong>Мобильный (430px)</strong> - 1500×1706</li>
            </ul>
            
            <BannerUploader resolution="2500" label="ПК" />
            <BannerUploader resolution="2500" label="Ноутбука" />
            <BannerUploader resolution="1500" label="Планшета" />
            <BannerUploader resolution="1500" label="Мобильного" />
            <ul>
                <li>ПК(1920px) - 2500x1776</li>
                <li>Ноутбук(1440px) - 2500x1778</li>
                <li>Планшет(768px) - 1500x1152</li>
                <li>Мобильный(430px) - 1500x1706</li>
            </ul>
        </Box>
    );
};

export default BannerUpload;