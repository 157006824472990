import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { CartItemsContext } from '../../Context/CartItemsContext';
import InputMask from 'react-input-mask';
import { TabTitle } from '../../utils/General';
import CartCard from '../Card/Cart/CartCard/CartCard';
import debounce from 'lodash.debounce';
import './Checkout.css'; // Подключаем стили

const Checkout = () => {
    const navigate = useNavigate();
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'Оформление заказа', path: '/checkout' },
    ];
    TabTitle('Оформление заказа');
    const { items, totalAmount, removeFromCart } = useContext(CartItemsContext);

    const [contactInfo, setContactInfo] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        fullName: '',
        street: '',
        houseNumber: '',
        apartment: '',
        comment: ''
    });

    const [errors, setErrors] = useState({});
    const [deliveryAmount, setDeliveryAmount] = useState(0);
    const widgetRef = useRef(null);
    const checkoutRef = useRef(null);
    const [widgetInstance, setWidgetInstance] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [deliveryOption, setDeliveryOption] = useState('pickup');
    const [paymentMethod, setPaymentMethod] = useState('onlinePayment');
    const [packMethod, setPackMethod] = useState('onlinePaymentPack');
    const [isOrderPlaced, setIsOrderPlaced] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [packAmount, setPackAmount] = useState(0);
	const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);
	const [finalAmount, setFinalAmount] = useState(0);

    // Функция для получения обязательных полей в зависимости от условий
    const getRequiredFields = () => {
        let requiredFields = ['firstName', 'lastName', 'phone', 'email']; // Базовые поля

        if (deliveryOption === 'pickupKurl') {
            // Для курьерской доставки по Москве добавляем только нужные поля
            requiredFields.push('fullName', 'street', 'houseNumber', 'apartment');
        } else if (deliveryOption === 'delivery' || deliveryOption === 'deliveryPoint') {
            // Для доставки до квартиры или пункта выдачи добавляем адресные поля
            requiredFields.push('city', 'street', 'houseNumber', 'apartment');
        } else if (deliveryOption === 'pickup') {
            // Для самовывоза оставляем только базовые поля
            requiredFields = ['firstName', 'lastName', 'phone', 'email'];
        }

        return requiredFields;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setContactInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));

        // Убираем ошибку для текущего поля
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));

        // Обновляем виджет при изменении города
        if (name === 'city' && widgetInstance) {
            widgetInstance.update({
                to: {
                    country_code: 'RU',
                    city: value,
                }
            });
        }

        // Запрос к Nominatim для автозаполнения адреса
        if (name === 'address') {
            fetchAddressSuggestions(value);
        }
    };

    const fetchAddressSuggestions = async (query) => {
        if (!query) {
            setSuggestions([]);
            return;
        }

        try {
            const response = await axios.get(
                `https://nominatim.openstreetmap.org/search?format=json&limit=5&countrycodes=ru&q=${query}`
            );
            setSuggestions(response.data);
        } catch (error) {
            console.error('Ошибка при получении подсказок адресов:', error);
        }
    };

    const handleAddressSelect = (suggestion) => {
        const addressDetails = suggestion.display_name.split(', ');
        setContactInfo(prevState => ({
            ...prevState,
            address: suggestion.display_name,
            city: addressDetails[addressDetails.length - 3], // город
            street: addressDetails[0] || '', // улица, предположительно
            houseNumber: addressDetails[1] || '' // номер дома, предположительно
        }));
        setSuggestions([]);
    };

    const handlePackChange = (event) => {
        const selectedPackOption = event.target.value;
        setPackMethod(selectedPackOption);

        // Устанавливаем стоимость упаковки
        if (selectedPackOption === 'onlinePaymentPack') {
            setPackAmount(0); // Стандартная упаковка - бесплатно
        } else if (selectedPackOption === 'deliveryPaymentPack') {
            setPackAmount(150); // Подарочная упаковка - 150 рублей
        }
    };

    const handleDeliveryChange = (event) => {
        const selectedOption = event.target.value;
        setDeliveryOption(selectedOption);

        // Установка стоимости доставки в зависимости от выбранного варианта
        if (selectedOption === 'delivery') {
            setDeliveryAmount(600); // Стоимость доставки до квартиры
        } else if (selectedOption === 'deliveryPoint') {
            setDeliveryAmount(300); // Стоимость доставки до пункта выдачи
        } else if (selectedOption === 'pickupKurl') {
            setDeliveryAmount(600); // Стоимость курьерской доставки по Москве
        } else {
            setDeliveryAmount(0); // Самовывоз или другой вариант без доставки
            // Очистка полей адреса при выборе самовывоза
            setContactInfo(prevState => ({
                ...prevState,
                address: '',
                street: '',
                houseNumber: '',
                apartment: ''
            }));
        }
    };

    const handlePaymentChange = (event) => {
        const selectedPaymentOption = event.target.value;
        setPaymentMethod(selectedPaymentOption);
    };

    const handleRemove = (productId) => {
        console.log(`Удаление товара с id: ${productId}`);
        removeFromCart(productId);
    };

    const calculateFinalAmount = () => {
        return totalAmount + deliveryAmount + packAmount - discountAmount;
    };

    function sanitizePhone(phone) {
        return `+${phone.replace(/\D/g, '')}`;
    }

    function formatAddress(orderData) {
        let address = `${orderData.city}, ул. ${orderData.street}, д. ${orderData.house_number}`;
        if (orderData.apartment) {
            address += `, кв. ${orderData.apartment}`;
        }
        return address;
    }

    const validateOrderData = (orderData) => {
        const requiredFields = [
            'first_name',
            'last_name',
            'phone',
            'email',
            'city',
            'street',
            'house_number',
            'apartment',
            'delivery_option',
            'payment_method',
            'total_amount',
            'items',
        ];

        if (!/^\d+$/.test(contactInfo.houseNumber)) {
            throw new Error('Некорректный формат номера дома');
        }
        if (items.length === 0) {
            throw new Error('Необходимо добавить хотя бы один товар в заказ');
        }
        if (calculateFinalAmount(items) <= 0) {
            throw new Error('Общая сумма заказа должна быть больше нуля');
        }
    };

    const getCityPostalCode = async (cityName, accessToken) => {
        try {
            const response = await axios.post(
                'https://carabine.shop/api/cdek/city-postal-code',
                { cityName },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            return response.data.postal_code;
        } catch (error) {
            console.error('Ошибка при получении почтового индекса:', error);
            throw error;
        }
    };

    const handleCheckout = async (event) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            // Получаем список обязательных полей
            const requiredFields = getRequiredFields();
            const newErrors = {};

            // Проверяем заполненность обязательных полей
            requiredFields.forEach((field) => {
                if (!contactInfo[field]) {
                    newErrors[field] = 'Пожалуйста, заполните это поле';
                }
            });

            // Если есть ошибки, устанавливаем их в стейт и прерываем выполнение
            if (Object.keys(newErrors).length > 0) {
                console.error('Ошибки валидации:', newErrors);
                setErrors(newErrors);
                setIsSubmitting(false); // Сбрасываем состояние отправки
                return;
            }

            // Формируем детали товаров
            const itemsWithDetails = items.map(item => ({
                _id: item._id,
                name: item.name,
                description: item.description,
                quantity: item.quantity || 1,
                price: item.price,
            }));

            if (itemsWithDetails.length === 0) {
                throw new Error('Необходимо добавить хотя бы один товар в заказ');
            }

            // Рассчитываем общую сумму заказа
            const totalAmount = calculateFinalAmount(items);
            if (totalAmount <= 0) {
                throw new Error('Общая сумма заказа должна быть больше нуля');
            }

            // Получаем почтовый индекс города (только для вариантов, где это нужно)
            let receiverCityWithPostalCode = { name: contactInfo.city };
            if (deliveryOption === 'delivery' || deliveryOption === 'deliveryPoint') {
                if (!receiverCityWithPostalCode.postal_code) {
                    const response = await axios.post('https://carabine.shop/api/cdek/token');
                    const accessToken = response.data.access_token;
                    receiverCityWithPostalCode.postal_code = await getCityPostalCode(contactInfo.city, accessToken);
                }
            }

            // Формируем данные заказа
            const orderData = {
                first_name: contactInfo.firstName,
                last_name: contactInfo.lastName,
                phone: sanitizePhone(contactInfo.phone),
                email: contactInfo.email,
                city: deliveryOption === 'pickup' || deliveryOption === 'pickupKurl' ? '' : receiverCityWithPostalCode,
                address: formatAddress({
                    city: receiverCityWithPostalCode.name,
                    street: contactInfo.street,
                    house_number: contactInfo.houseNumber.replace(/\D/g, ''),
                    apartment: contactInfo.apartment,
                }),
                street: contactInfo.street,
                house_number: contactInfo.houseNumber.replace(/\D/g, ''),
                apartment: contactInfo.apartment,
                comment: contactInfo.comment,
                promo_code: promoCode,
                delivery_option: deliveryOption,
                payment_method: paymentMethod,
				payment_status: paymentMethod === 'onlinePayment' ? 'pending' : 'deliveryPayment',
                total_amount: totalAmount,
                items: itemsWithDetails,
            };

            console.log('Отправляемые данные:', JSON.stringify(orderData, null, 2));

            // Отправляем заказ в СДЭК или создаем его в базе данных
            if (deliveryOption === 'delivery' || deliveryOption === 'deliveryPoint') {
                const cdekResponse = await axios.post('https://carabine.shop/api/orders', orderData);
                if (cdekResponse.status !== 201) {
                    throw new Error('Ошибка при создании заказа в СДЭК');
                }
                console.log('Заказ успешно создан в СДЭК:', cdekResponse.data);
            }

            if (paymentMethod === 'onlinePayment') {
                const paymentResponse = await axios.post('https://carabine.shop/api/payment', {
                    ...orderData,
                    returnUrl: `${window.location.origin}/thank-you`,
                });
                if (paymentResponse.data && paymentResponse.data.paymentUrl) {
                    console.log('Перенаправление на страницу оплаты:', paymentResponse.data.paymentUrl);
                    window.location.href = paymentResponse.data.paymentUrl;
                } else {
                    console.error('Ошибка при создании платежа');
                }
            } else {
                const dbResponse = await axios.post('https://carabine.shop/api/orders', orderData);
                if (dbResponse.status === 201) {
                    setIsOrderPlaced(true);
                    console.log('Заказ успешно создан в базе данных');
                } else {
                    console.error('Ошибка создания заказа в базе данных:', dbResponse.data);
                }
            }
        } catch (error) {
            if (error.response) {
                console.error('Ошибка API:', error.response.data);
            } else {
                console.error('Ошибка сети:', error.message);
            }
        } finally {
            setIsSubmitting(false); // Сбрасываем состояние отправки
        }
    };

    useEffect(() => {
        if (deliveryOption === 'deliveryPoint' && !widgetInstance) {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/@cdek-it/widget@3';
            script.async = true;

            script.onload = () => {
                if (widgetRef.current && window.CDEKWidget) {
                    const widget = new window.CDEKWidget({
                        from: {
                            country_code: 'RU',
                            city: 'Москва',
                            postal_code: 125047,
                            code: 495,
                            address: '1-я Тверская-Ямская, 13, ст.1',
                        },
                        root: 'cdek-map',
                        apiKey: '061f1e76-3219-439a-8903-8d8c1ebf0e1f',
                        canChoose: false,
                        servicePath: 'https://carabine.shop/api/public/service.php',
                        hideFilters: {
                            have_cashless: false,
                            have_cash: false,
                            is_dressing_room: false,
                            type: false,
                        },
                        debug: false,
                        goods: [
                            {
                                width: 10,
                                height: 10,
                                length: 10,
                                weight: 10,
                            },
                        ],
                        defaultLocation: 'Москва',
                        lang: 'rus',
                        currency: 'RUB',
                        tariffs: {
                            office: [234, 136, 138],
                            door: [233, 137, 139],
                        },
                        hideDeliveryOptions: {
                            office: false,
                            door: true,
                        },
                        onReady() {
                            console.log('Карта CDEK инициализирована');
                        },
                        onCalculate(rates, address) {
                            console.log('Калькуляция завершена');
                        },
                        onChoose(details) {
                            console.log('Выбран пункт доставки:', details);
                            setContactInfo(prevState => ({
                                ...prevState,
                                city: details.city,
                                street: details.street,
                                houseNumber: details.house,
                                apartment: details.flat,
                            }));
                        },
                    });
                    setWidgetInstance(widget);
                }
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [deliveryOption, widgetInstance]);

    const handleClosePopup = () => {
        setIsOrderPlaced(false);
        window.location.href = '/';

        // Включение прокрутки при закрытии попап-окна
        document.body.style.overflow = 'auto';
    };
    const applyPromoCode = async (code) => {
      try {
        const totalAmount = calculateFinalAmount(items); // Получаем общую сумму заказа

        const response = await axios.post('https://carabine.shop/api/apply-promo', {
          promoCode: code,
          totalAmount: totalAmount, // Передаем общую сумму заказа
        });

        if (response.data.discountAmount) {
          const discount = response.data.discountAmount;
          setDiscountAmount(discount); // Устанавливаем размер скидки
          setFinalAmount(totalAmount - discount); // Обновляем итоговую сумму
          setPromoCodeError(''); // Очищаем ошибки
        } else {
          setPromoCodeError(response.data.error || 'Ошибка при применении промокода');
          setDiscountAmount(0); // Сбрасываем скидку
        }
      } catch (error) {
        console.error('Ошибка при применении промокода:', error.response?.data || error.message);
        setPromoCodeError('Ошибка при применении промокода');
        setDiscountAmount(0); // Сбрасываем скидку
      }
    };
	const debouncedApplyPromoCode = debounce((code) => applyPromoCode(code), 500);
    const handlePromoCodeChange = (event) => {
      const value = event.target.value;
      setPromoCode(value); // Обновляем состояние ввода

      if (value.trim()) {
        debouncedApplyPromoCode(value);
      } else {
        setDiscountAmount(0); // Сбрасываем скидку
        setPromoCodeError(''); // Очищаем ошибки
      }
    };
	useEffect(() => {
      const initialTotal = calculateFinalAmount(items);
      setFinalAmount(initialTotal); // Начальная итоговая сумма равна общей сумме
    }, [items]);
    return (
        <>
            <div className="Checkout" ref={checkoutRef}>
                <div className="Checkout__container">
                    <Breadcrumbs items={breadcrumbItems} />
                    {isOrderPlaced ? (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <h2>Благодарим за ваш заказ!</h2>
                                <p>В ближайшее время Мы свяжемся с вами для уточнения деталей.</p>
                                <button onClick={handleClosePopup} className="popup-close-button">
                                    Продолжить покупки
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h1>Оформление заказа</h1>

                            <div className="Checkout__content">
                                <form onSubmit={handleCheckout}>
                                    <div className="contact-info">
                                        <h2>Контактная информация</h2>
                                        <div className={`input-container ${errors.firstName ? 'error' : ''}`}>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={contactInfo.firstName}
                                                onChange={handleChange}
                                                placeholder=" "
                                            />
                                            <label>Имя</label>
                                            {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                                        </div>
                                        <div className={`input-container ${errors.lastName ? 'error' : ''}`}>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={contactInfo.lastName}
                                                onChange={handleChange}
                                                placeholder=" "
                                            />
                                            <label>Фамилия</label>
                                            {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                                        </div>
                                        <div className={`input-container ${errors.phone ? 'error' : ''}`}>
                                            <InputMask
                                                mask="+7 (999) 999-9999"
                                                type="tel"
                                                name="phone"
                                                value={contactInfo.phone}
                                                onChange={handleChange}
                                                placeholder=" "
                                            />
                                            <label>Телефон</label>
                                            {errors.phone && <span className="error-message">{errors.phone}</span>}
                                        </div>
                                        <div className={`input-container ${errors.email ? 'error' : ''}`}>
                                            <input
                                                type="email"
                                                name="email"
                                                value={contactInfo.email}
                                                onChange={handleChange}
                                                placeholder=" "
                                            />
                                            <label>Email</label>
                                            {errors.email && <span className="error-message">{errors.email}</span>}
                                        </div>
                                        {(deliveryOption !== 'pickup' && deliveryOption !== 'pickupKurl') && (
                                            <>
                                                <h2>Доставка</h2>
                                                <div className={`input-container ${errors.address ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={contactInfo.address}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label>Адрес (улица, номер дома, квартира)</label>
                                                    {errors.address && <span className="error-message">{errors.address}</span>}
                                                    {suggestions.length > 0 && (
                                                        <ul className="suggestions">
                                                            {suggestions.map(suggestion => (
                                                                <li key={suggestion.place_id} onClick={() => handleAddressSelect(suggestion)}>
                                                                    {suggestion.display_name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    <p className="sityname">Россия, г. Москва</p>
                                                </div>
                                            </>
                                        )}
                                        <div className={`delivery-name ${errors.deliveryOption ? 'error' : ''}`}>
                                            <label className="custom-radio">
                                                <input
                                                    type="radio"
                                                    value="delivery"
                                                    checked={deliveryOption === 'delivery'}
                                                    onChange={handleDeliveryChange}
                                                />
                                                <span className="radio-btn"></span>
                                                СДЕК - до квартиры - от 1 дня, 600 ₽
                                            </label>
                                            <label className="custom-radio">
                                                <input
                                                    type="radio"
                                                    value="deliveryPoint"
                                                    checked={deliveryOption === 'deliveryPoint'}
                                                    onChange={handleDeliveryChange}
                                                />
                                                <span className="radio-btn"></span>
                                                СДЕК - до пункта выдачи - от 4 дней, 300 ₽
                                            </label>
                                            <label className="custom-radio">
                                                <input
                                                    type="radio"
                                                    value="pickup"
                                                    checked={deliveryOption === 'pickup'}
                                                    onChange={handleDeliveryChange}
                                                />
                                                <span className="radio-btn"></span>
                                                забрать самовывозом в Москве
                                            </label>
                                            <label className="custom-radio">
                                                <input
                                                    type="radio"
                                                    value="pickupKurl"
                                                    checked={deliveryOption === 'pickupKurl'}
                                                    onChange={handleDeliveryChange}
                                                />
                                                                                                <span className="radio-btn"></span>
                                                курьерская доставка по Москве - от 1 дня, 600 ₽
                                            </label>
                                            {errors.deliveryOption && <span className="error-message">{errors.deliveryOption}</span>}
                                            {errors.deliveryOption && <span className="error-message">{errors.deliveryOption}</span>}
                                            <p>* свяжитесь с нами в WhatsApp или Instagram</p>
                                        </div>
                                    </div>

                                    {deliveryOption === 'deliveryPoint' && (
                                        <>
                                            <div id="cdek-map" ref={widgetRef} style={{ height: '500px', width: '100%' }}></div>
                                        </>
                                    )}
                                    {deliveryOption === 'delivery' && (
                                        <>
                                            <div className={`input-container ${errors.fullName ? 'error' : ''}`}>
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    value={contactInfo.fullName}
                                                    onChange={handleChange}
                                                    className="input-full-name"
                                                    placeholder=" "
                                                />
                                                <label>Получатель (ФИО полностью)</label>
                                                {errors.fullName && <span className="error-message">{errors.fullName}</span>}
                                            </div>
                                            <div className={`input-container ${errors.city ? 'error' : ''}`}>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    value={contactInfo.city}
                                                    onChange={handleChange}
                                                    className="input-city"
                                                    placeholder=" "
                                                />
                                                <label>Город</label>
                                                {errors.city && <span className="error-message">{errors.city}</span>}
                                            </div>
                                            <div className={`input-container ${errors.street ? 'error' : ''}`}>
                                                <input
                                                    type="text"
                                                    name="street"
                                                    value={contactInfo.street}
                                                    onChange={handleChange}
                                                    className="input-street"
                                                    placeholder=" "
                                                />
                                                <label>Улица</label>
                                                {errors.street && <span className="error-message">{errors.street}</span>}
                                            </div>
                                            <div className="desctop-input">
                                                <div className={`input-container ${errors.houseNumber ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="houseNumber"
                                                        value={contactInfo.houseNumber}
                                                        onChange={handleChange}
                                                        className="input-house-number"
                                                        placeholder=" "
                                                    />
                                                    <label>Номер дома</label>
                                                    {errors.houseNumber && <span className="error-message">{errors.houseNumber}</span>}
                                                </div>
                                                <div className={`input-container ${errors.apartment ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="apartment"
                                                        value={contactInfo.apartment}
                                                        onChange={handleChange}
                                                        className="input-apartment"
                                                        placeholder=" "
                                                    />
                                                    <label>Квартира/офис</label>
                                                    {errors.apartment && <span className="error-message">{errors.apartment}</span>}
                                                </div>
                                            </div>
                                            <div className="mobile-input">
                                                <div className={`input-container ${errors.houseNumber ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="houseNumber"
                                                        value={contactInfo.houseNumber}
                                                        onChange={handleChange}
                                                        className="input-house-number"
                                                        placeholder=" "
                                                    />
                                                    <label>Номер дома</label>
                                                    {errors.houseNumber && <span className="error-message">{errors.houseNumber}</span>}
                                                </div>
                                                <div className={`input-container ${errors.apartment ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="apartment"
                                                        value={contactInfo.apartment}
                                                        onChange={handleChange}
                                                        className="input-apartment"
                                                        placeholder=" "
                                                    />
                                                    <label>Квартира/офис</label>
                                                    {errors.apartment && <span className="error-message">{errors.apartment}</span>}
                                                </div>
                                            </div>
                                            <div className={`input-container ${errors.comment ? 'error' : ''}`}>
                                                <textarea
                                                    name="comment"
                                                    value={contactInfo.comment}
                                                    onChange={handleChange}
                                                    className="input-comment"
                                                    rows="4"
                                                    style={{ resize: 'none' }}
                                                    placeholder=" "
                                                />
                                                <label>Комментарий к заказу</label>
                                                {errors.comment && <span className="error-message">{errors.comment}</span>}
                                            </div>

                                            <div className="input-container promo">
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                    className="input-promocode"
                                                    placeholder=" "
                                                />
                                                <label>промокод</label>
												{promoCodeError && <span className="error-message">{promoCodeError}</span>}
                                                {discountAmount > 0 && (
                                                  <p className="succespromo" style={{ color: 'green' }}>
                                                    Промокод успешно применен! Скидка: {discountAmount} рублей.
                                                  </p>
                                                )}
                                                <svg className="prompsvg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 12.5C2.90038e-07 19.1353 5.36471 24.5 12 24.5C18.6353 24.5 24 19.1353 24 12.5C24 5.86471 18.6353 0.5 12 0.5C5.36471 0.500002 -2.90038e-07 5.86471 0 12.5ZM22.5882 12.5C22.5882 18.3588 17.8588 23.0882 12 23.0882C6.14118 23.0882 1.41176 18.3588 1.41176 12.5C1.41176 6.64118 6.14118 1.91177 12 1.91177C17.8588 1.91177 22.5882 6.64118 22.5882 12.5Z" fill="#BBBBBB"/>
                                                    <path d="M10.8 6.64118L16.6588 12.5L10.8 18.3588L11.7882 19.3471L18.6353 12.5L11.7882 5.65294L10.8 6.64118Z" fill="#BBBBBB"/>
                                                    <path d="M17.6471 13.2059V11.7941H5.64706V13.2059H17.6471Z" fill="#BBBBBB"/>
                                                </svg>
                                            </div>
                                        </>
                                    )}
                                    {deliveryOption === 'pickupKurl' && (
                                        <>
                                            <div className={`input-container ${errors.fullName ? 'error' : ''}`}>
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    value={contactInfo.fullName}
                                                    onChange={handleChange}
                                                    className="input-full-name"
                                                    placeholder=" "
                                                />
                                                <label>Получатель (ФИО полностью)</label>
                                                {errors.fullName && <span className="error-message">{errors.fullName}</span>}
                                            </div>
                                            <div className={`input-container ${errors.street ? 'error' : ''}`}>
                                                <input
                                                    type="text"
                                                    name="street"
                                                    value={contactInfo.street}
                                                    onChange={handleChange}
                                                    className="input-street"
                                                    placeholder=" "
                                                />
                                                <label>Улица</label>
                                                {errors.street && <span className="error-message">{errors.street}</span>}
                                            </div>
                                            <div className="desctop-input">
                                                <div className={`input-container ${errors.houseNumber ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="houseNumber"
                                                        value={contactInfo.houseNumber}
                                                        onChange={handleChange}
                                                        className="input-house-number"
                                                        placeholder=" "
                                                    />
                                                    <label>Номер дома</label>
                                                    {errors.houseNumber && <span className="error-message">{errors.houseNumber}</span>}
                                                </div>
                                                <div className={`input-container ${errors.apartment ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="apartment"
                                                        value={contactInfo.apartment}
                                                        onChange={handleChange}
                                                        className="input-apartment"
                                                        placeholder=" "
                                                    />
                                                    <label>Квартира/офис</label>
                                                    {errors.apartment && <span className="error-message">{errors.apartment}</span>}
                                                </div>
                                            </div>
                                            <div className="mobile-input">
                                                <div className={`input-container ${errors.houseNumber ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="houseNumber"
                                                        value={contactInfo.houseNumber}
                                                        onChange={handleChange}
                                                        className="input-house-number"
                                                        placeholder=" "
                                                    />
                                                    <label>Номер дома</label>
                                                    {errors.houseNumber && <span className="error-message">{errors.houseNumber}</span>}
                                                </div>
                                                <div className={`input-container ${errors.apartment ? 'error' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="apartment"
                                                        value={contactInfo.apartment}
                                                        onChange={handleChange}
                                                        className="input-apartment"
                                                        placeholder=" "
                                                    />
                                                    <label>Квартира/офис</label>
                                                    {errors.apartment && <span className="error-message">{errors.apartment}</span>}
                                                </div>
                                            </div>
                                            <div className={`input-container ${errors.comment ? 'error' : ''}`}>
                                                <textarea
                                                    name="comment"
                                                    value={contactInfo.comment}
                                                    onChange={handleChange}
                                                    className="input-comment"
                                                    rows="4"
                                                    style={{ resize: 'none' }}
                                                    placeholder=" "
                                                />
                                                <label>Комментарий к заказу</label>
                                                {errors.comment && <span className="error-message">{errors.comment}</span>}
                                            </div>

                                            <div className="input-container promo">
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                    className="input-promocode"
                                                    placeholder=" "
                                                />
                                                <label>промокод</label>
												{promoCodeError && <span className="error-message">{promoCodeError}</span>}
                                                {discountAmount > 0 && (
                                                  <p className="succespromo" style={{ color: 'green' }}>
                                                    Промокод успешно применен! Скидка: {discountAmount} рублей.
                                                  </p>
                                                )}
                                                <svg className="prompsvg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 12.5C2.90038e-07 19.1353 5.36471 24.5 12 24.5C18.6353 24.5 24 19.1353 24 12.5C24 5.86471 18.6353 0.5 12 0.5C5.36471 0.500002 -2.90038e-07 5.86471 0 12.5ZM22.5882 12.5C22.5882 18.3588 17.8588 23.0882 12 23.0882C6.14118 23.0882 1.41176 18.3588 1.41176 12.5C1.41176 6.64118 6.14118 1.91177 12 1.91177C17.8588 1.91177 22.5882 6.64118 22.5882 12.5Z" fill="#BBBBBB"/>
                                                    <path d="M10.8 6.64118L16.6588 12.5L10.8 18.3588L11.7882 19.3471L18.6353 12.5L11.7882 5.65294L10.8 6.64118Z" fill="#BBBBBB"/>
                                                    <path d="M17.6471 13.2059V11.7941H5.64706V13.2059H17.6471Z" fill="#BBBBBB"/>
                                                </svg>
                                            </div>
                                        </>
                                    )}

                                    {deliveryOption === 'pickup' && (
                                        <>
                                            <p className="border">После оплаты заказа и указания личных данных с вами свяжется наш менеджер для подбора временного слота самовывоза.<br /><br />Хотим обратить ваше внимание, точка самовывоза - это не магазин, а именно пункт, где вы можете забрать свой заказ.<br /><br />Обязательно уточните изделие, которое планируете забрать.</p>
                                            <div className="input-container">
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    value={contactInfo.fullName}
                                                    onChange={handleChange}
                                                    className="input-full-name"
                                                    placeholder=" "
                                                />
                                                <label>Получатель (ФИО полностью)</label>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    name="comment"
                                                    value={contactInfo.comment}
                                                    onChange={handleChange}
                                                    className="input-comment"
                                                    rows="4"
                                                    style={{ resize: 'none' }}
                                                    placeholder=" "
                                                />
                                                <label>Комментарий к заказу</label>
                                            </div>

                                            <div className="input-container promo">
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                    className="input-promocode"
                                                    placeholder=" "
                                                />
                                                <label>промокод</label>
												{promoCodeError && <span className="error-message">{promoCodeError}</span>}
                                                {discountAmount > 0 && (
                                                  <p className="succespromo" style={{ color: 'green' }}>
                                                    Промокод успешно применен! Скидка: {discountAmount} рублей.
                                                  </p>
                                                )}
                                                <svg className="prompsvg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 12.5C2.90038e-07 19.1353 5.36471 24.5 12 24.5C18.6353 24.5 24 19.1353 24 12.5C24 5.86471 18.6353 0.5 12 0.5C5.36471 0.500002 -2.90038e-07 5.86471 0 12.5ZM22.5882 12.5C22.5882 18.3588 17.8588 23.0882 12 23.0882C6.14118 23.0882 1.41176 18.3588 1.41176 12.5C1.41176 6.64118 6.14118 1.91177 12 1.91177C17.8588 1.91177 22.5882 6.64118 22.5882 12.5Z" fill="#BBBBBB"/>
                                                    <path d="M10.8 6.64118L16.6588 12.5L10.8 18.3588L11.7882 19.3471L18.6353 12.5L11.7882 5.65294L10.8 6.64118Z" fill="#BBBBBB"/>
                                                    <path d="M17.6471 13.2059V11.7941H5.64706V13.2059H17.6471Z" fill="#BBBBBB"/>
                                                </svg>
                                            </div>
                                        </>
                                    )}
                                    {deliveryOption === 'deliveryPoint' && (
                                        <>
                                            <div className="input-container">
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    value={contactInfo.fullName}
                                                    onChange={handleChange}
                                                    className="input-full-name"
                                                    placeholder=" "
                                                />
                                                <label>Получатель (ФИО полностью)</label>
                                            </div>
                                            <div className="input-container">
                                                <textarea
                                                    name="comment"
                                                    value={contactInfo.comment}
                                                    onChange={handleChange}
                                                    className="input-comment"
                                                    rows="4"
                                                    style={{ resize: 'none' }}
                                                    placeholder=" "
                                                />
                                                <label>Комментарий к заказу</label>
                                            </div>

                                            <div className="input-container promo">
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                    className="input-promocode"
                                                    placeholder=" "
                                                />
                                                <label>промокод</label>
												{promoCodeError && <span className="error-message">{promoCodeError}</span>}
                                                {discountAmount > 0 && (
                                                  <p className="succespromo" style={{ color: 'green' }}>
                                                    Промокод успешно применен! Скидка: {discountAmount} рублей.
                                                  </p>
                                                )}
                                                <svg className="prompsvg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 12.5C2.90038e-07 19.1353 5.36471 24.5 12 24.5C18.6353 24.5 24 19.1353 24 12.5C24 5.86471 18.6353 0.5 12 0.5C5.36471 0.500002 -2.90038e-07 5.86471 0 12.5ZM22.5882 12.5C22.5882 18.3588 17.8588 23.0882 12 23.0882C6.14118 23.0882 1.41176 18.3588 1.41176 12.5C1.41176 6.64118 6.14118 1.91177 12 1.91177C17.8588 1.91177 22.5882 6.64118 22.5882 12.5Z" fill="#BBBBBB"/>
                                                    <path d="M10.8 6.64118L16.6588 12.5L10.8 18.3588L11.7882 19.3471L18.6353 12.5L11.7882 5.65294L10.8 6.64118Z" fill="#BBBBBB"/>
                                                                                                        <path d="M17.6471 13.2059V11.7941H5.64706V13.2059H17.6471Z" fill="#BBBBBB"/>
                                                </svg>
                                            </div>
                                        </>
                                    )}
                                    <div className="pack-method">
                                        <h2>Упаковка</h2>
                                        <label className="custom-radio">
                                            <input
                                                type="radio"
                                                value="onlinePaymentPack"
                                                checked={packMethod === 'onlinePaymentPack'}
                                                onChange={handlePackChange}
                                            />
                                            <span className="radio-btn"></span>
                                            стандартная
                                        </label>
                                        <label className="custom-radio">
                                            <input
                                                type="radio"
                                                value="deliveryPaymentPack"
                                                checked={packMethod === 'deliveryPaymentPack'}
                                                onChange={handlePackChange}
                                            />
                                            <span className="radio-btn"></span>
                                            подарочная
                                        </label>
                                    </div>
                                    <div className="payment-method">
                                        <h2>Способ оплаты</h2>
                                        <label className="custom-radio">
                                            <input
                                                type="radio"
                                                value="onlinePayment"
                                                checked={paymentMethod === 'onlinePayment'}
                                                onChange={handlePaymentChange}
                                            />
                                            <span className="radio-btn"></span>
                                            Онлайн-оплата банковской картой
                                        </label>
                                        {deliveryOption === 'pickup' && (
                                            <>
                                                <label className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        value="deliveryPayment"
                                                        checked={paymentMethod === 'deliveryPayment'}
                                                        onChange={handlePaymentChange}
                                                    />
                                                    <span className="radio-btn"></span>
                                                    Оплата при получении*
                                                </label>
                                                <p>* доступно при самовывозе в Москве</p>
                                            </>
                                        )}
                                    </div>
                                    <div className="delivery-amount-to">
                                        <div className="toprice">сумма заказа</div><div className="toamount">{totalAmount} ₽</div>
                                    </div>
                                    <div className="final-amount-to">
                                        <div className="toprice">итого</div><div className="toamount">{calculateFinalAmount()} ₽</div>
                                    </div>
                                    <button
                                        type="submit"
                                        onClick={handleCheckout}
                                        disabled={isSubmitting} // Блокируем кнопку во время отправки
                                        className="checkout-button"
                                    >
                                        {isSubmitting ? (
                                            <span>Отправка... <i className="spinner"></i></span>
                                        ) : (
                                            'Оформить заказ'
                                        )}
                                    </button>
                                    <p className="pay">Нажимая на кнопку «Оформить заказ», я принимаю условия публичной оферты и политики конфиденциальности</p>
                                </form>

                                <div className="product-info">
                                    {items.length === 0 ? (
                                        <>
                                            <div className="cart__empty">Ваша корзина пуста!</div>
                                            <a className="return" href="/">Перейти в каталог</a>
                                        </>
                                    ) : (
                                        items.map((item) => (
                                            <CartCard key={item._id} item={item} onRemove={handleRemove} />
                                        ))
                                    )}
                                    <div className="checkout-summary">
                                        <div className="prc">
                                            <div className="toprice">Cумма заказа</div><div className="toamount">{totalAmount} ₽</div>
                                        </div>
                                        <div className="prm">
                                            <label className="promo-code-label">
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    onChange={handlePromoCodeChange}
                                                    placeholder="промокод"
                                                />
                                            </label>
										   {promoCodeError && <span className="error-message">{promoCodeError}</span>}
                                           {discountAmount > 0 && (
                                             <p className="succespromo" style={{ color: 'green' }}>
                                               Промокод успешно применен! Скидка: {discountAmount} рублей.
                                             </p>
                                           )}
                                            <svg className="prompsvg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 12.5C2.90038e-07 19.1353 5.36471 24.5 12 24.5C18.6353 24.5 24 19.1353 24 12.5C24 5.86471 18.6353 0.5 12 0.5C5.36471 0.500002 -2.90038e-07 5.86471 0 12.5ZM22.5882 12.5C22.5882 18.3588 17.8588 23.0882 12 23.0882C6.14118 23.0882 1.41176 18.3588 1.41176 12.5C1.41176 6.64118 6.14118 1.91177 12 1.91177C17.8588 1.91177 22.5882 6.64118 22.5882 12.5Z" fill="#BBBBBB"/>
                                                <path d="M10.8 6.64118L16.6588 12.5L10.8 18.3588L11.7882 19.3471L18.6353 12.5L11.7882 5.65294L10.8 6.64118Z" fill="#BBBBBB"/>
                                                <path d="M17.6471 13.2059V11.7941H5.64706V13.2059H17.6471Z" fill="#BBBBBB"/>
                                            </svg>
                                        </div>
                                        <div className="delivery-amount">
                                            <div className="toprice">доставка</div><div className="toamount">{deliveryAmount} ₽</div>
                                        </div>
                                        <div className="pack-amount">
                                            <div className="toprice">упаковка</div><div className="toamount">{packAmount}  ₽</div>
                                        </div>
                                        <div className="final-amount">
                                            <div className="toprice">Итого</div><div className="toamount">{finalAmount} ₽</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Checkout;