import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './Stores.css';
import { TabTitle } from '../../utils/General';

const Stores = () => {
    const breadcrumbItems = [
        { name: 'carabine', path: '/' },
        { name: 'магазины', path: '/magaziny' },
    ];
    TabTitle('Магазины');

    return (
        <div className="Stores">
            <div className="Stores__container">
                <Breadcrumbs items={breadcrumbItems} />
                <div className="Stores__content">
                    {/* Первый магазин */}
                    <div className="Stores__item">
                        <div className="Stores__text">
                            <h2>Москва<br />Точка самовывоза​</h2>
							<p className="star">осуществляется по предварительной договоренности для записи свяжетесь с нами в  <a href="https://www.instagram.com/carabine.ru?igsh=MTlmZ3l0NWw4YXU1ag==">instagram</a></p>
                        </div>
                    </div>

                    {/* Второй магазин */}
                    <div className="Stores__item">
                        <div className="Stores__text">
                            <h2>Москва<br />ITEMS store​</h2>
                            <p>Цветной бульвар 15 стр1,​</p>
                            <p>ТЦ Цветной, 4 этаж​</p>
                        </div>
                        <div className="Stores__map">
                            <iframe 
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A407cd1833e3044177102d9a7cb30b6b28a036aa96a8f5f4a3b3b796624c9fb3c&amp;source=constructor" 
                                width="100%" 
                                height="400" 
                                frameBorder="0">
                            </iframe>
                        </div>
                    </div>

                    {/* Третий магазин */}
                    
                </div>
            </div>
        </div>
    );
};

export default Stores;
